import { Injectable } from "@angular/core";

import { DocumentNode } from "@apollo/client/core";
import { Subscription } from "apollo-angular";
import { messageAdded } from "./api/query";

@Injectable({
    providedIn: "root",
})
export class ChatGetMessageService extends Subscription {
    public document: DocumentNode = messageAdded;
}
