/* src/app/relations/clients/modals/contract-modal/components/contract-list/contract-list.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.contract-list {
  width: 300px;
}
.contract-list > *:not(:last-child) {
  margin-bottom: 15px;
}
.contract-list > *:last-child {
  margin-top: 20px;
}
.contract-list .add-button {
  width: 300px;
}
.contract-list .contract-dropdown-items {
  width: 300px;
}
.new-contract-block {
  padding-top: 10px;
  border-top: 1px solid rgba(219, 223, 232, 0.5);
}
/*# sourceMappingURL=contract-list.component.css.map */
