/* src/app/Chat/chat-main/chat.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.chatModalContainer {
  position: fixed;
  z-index: 122;
  top: 115px;
  left: 434px;
  background-color: #808080;
}
#search {
  width: 242px;
}
#search ::ng-deep input {
  height: 40px;
  width: 242px;
}
#driversScroll::ng-deep {
  background-color: red;
}
#allBtn ::ng-deep label {
  display: block;
}
#allBtn ::ng-deep span {
  transform: translateY(-50%);
}
.chat-container {
  display: flex;
  flex-direction: column;
  padding: 30px 24px 12px 30px;
  width: 1052px;
  height: 699px;
  border-radius: 4px;
  background-color: #f3f4f7;
}
.mass-plag {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mass-plag p {
  height: 15px;
}
.inputBtn {
  display: flex;
  width: 392px;
  margin-bottom: 12px;
}
.chatPart {
  margin-top: 16px;
  display: flex;
  height: 100%;
}
.driversSide {
  height: 540px;
  width: 408px;
}
.driversWrapper {
  height: 100%;
  margin-top: 12px;
  background-color: red;
}
.pickAllDrivers {
  width: 100%;
}
.driversWrapper {
  width: 40%;
}
.chatWindowContainer {
  width: 578px;
  height: 590px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-left: 10px;
  border: 1px solid #dbdfe8;
}
.send-form {
  margin: 16px auto;
  min-height: 56px;
  background-color: white;
  border: 1px solid #dbdfe8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 538px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.message-textarea {
  height: 100%;
  width: 449px;
  resize: none;
  border: none;
  padding: 0;
  margin-top: 1px;
  margin-left: 14px;
  overflow: hidden;
  word-wrap: break-word;
  vertical-align: middle;
  padding-top: 17px;
  position: sticky;
  bottom: 0;
  left: 0;
}
.send-message-btn {
  margin-left: auto;
  background-color: white;
  border: 0;
  display: block;
  float: right;
  height: 36px;
  width: 36px;
  margin-right: 19px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  align-self: flex-end;
  margin-bottom: 10px;
}
.chat-plug {
  align-self: center;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(50%);
}
.chat-plug > p {
  text-align: center;
}
.openChat {
  background-color: greenyellow;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
}
.selectAll {
  text-align: center;
  cursor: pointer;
  width: 138px;
  height: 40px;
  border: solid 1px #e7eaec;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.scrollView {
  height: 100%;
  position: relative;
}
.chat-scroll {
  height: 520px;
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}
/*# sourceMappingURL=chat.component.css.map */
