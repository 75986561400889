import { Attribute, Component, ElementRef, NgZone } from "@angular/core";

@Component({
    selector: "ge-editor-field",
    templateUrl: "./editor-field.component.html",
})
export class EditorFieldComponent {
    public inputVisible = false;

    constructor(
        @Attribute("autoOpen")
        public autoOpen: string,

        private zone: NgZone,
        private elementRef: ElementRef,
    ) {}

    public hideInput(): void {
        this.inputVisible = false;
    }

    public showInput(): void {
        this.inputVisible = true;

        if (!this.autoOpen && this.autoOpen !== "") {
            return;
        }

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                const htmlElement = this.elementRef.nativeElement.querySelector(
                    "input, textarea, select, label",
                );

                if (htmlElement) {
                    htmlElement.focus();
                    htmlElement.click();
                }
            });
        });
    }
}
