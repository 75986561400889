import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import moment from "moment";
import { IDateRange } from "../../../contract-modal.models";
import { DATE_FORMAT } from "src/app/shared/constants";

@Component({
    selector: "ge-contract-range-inputs",
    templateUrl: "./contrat-range-inputs.component.html",
    styleUrl: "./contrat-range-inputs.component.scss",
})
export class ContratRangeInputsComponent implements OnChanges {
    @Input()
    public readonly startDate!: Date | string;

    @Input()
    public readonly endDate!: Date | string;

    @Input()
    public readonly okLabel!: string;

    @Output()
    public readonly save: EventEmitter<IDateRange> =
        new EventEmitter<IDateRange>();

    @Output()
    public readonly reset: EventEmitter<void> = new EventEmitter<void>();

    public startDatepicker!: Date;

    public endDatepicker!: Date;
    public ngOnChanges(changes: SimpleChanges): void {
        const { startDate, endDate } = changes;
        if (startDate && this.startDate) {
            this.startDatepicker =
                typeof this.startDate === "string"
                    ? new Date(this.startDate)
                    : this.startDate;
        }

        if (endDate && this.endDate) {
            this.endDatepicker =
                typeof this.endDate === "string"
                    ? new Date(this.endDate)
                    : this.endDate;
        }
    }

    public onReset = () => {
        this.reset.emit();
    };

    public onContractCreate = () => {
        if (!this.startDatepicker || !this.endDatepicker) {
            return;
        }

        this.save.emit({
            from: moment(this.startDatepicker).format(DATE_FORMAT),
            to: moment(this.endDatepicker).format(DATE_FORMAT),
        });
    };
}
