import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";

@Component({
    selector: "ge-warning-notification-in-use",
    templateUrl: "./warning-notification-in-use.component.html",
})
export class WarningNotificationInUse {
    public data: {
        userName: string;
    };

    public close$ = new Subject<any>();

    public modalWindowOptions: IModalWindowOptions = {
        title: "warningNotificationInUse.title",
    };

    constructor(private modalWindowService: ModalWindowService) {}
}
