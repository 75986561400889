import { Component, OnInit } from "@angular/core";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import { ChatComponent } from "../chat-main/chat.component";
import { ChatApiService } from "../services/chat-api.service";
import { ChatGetMessageService } from "../services/chat.graphql.service";
import { StorageService } from "src/app/services/storage.service";
@Component({
    selector: "ge-modal-button",
    templateUrl: "./modal-button.component.html",
    styleUrl: "./modal-button.component.scss",
})
export class ModalButtonComponent implements OnInit {
    constructor(
        private modalWindowService: ModalWindowService,
        public chatService: ChatApiService,
        private chatGetMessageService: ChatGetMessageService,
        private storage: StorageService,
    ) {}

    ngOnInit() {
        // this.chatGetMessageService
        //     .subscribe({
        //         token: this.storage.getItem('token')
        //     }).subscribe(() => {
        //         this.chatService.newMessage$.next(true);
        //     });
    }
    onOpen() {
        this.chatService.newMessage$.next(false);
        this.modalWindowService.open(ChatComponent);
    }
}
