import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { DestructibleController } from "../../../../../../common/classes/destructible.controller";
import { ToasterNotificationService } from "../../../../../../shared/components/toaster-notification/toaster-notification.service";
import { IBaseContract, IRatePeriod } from "../../contract-modal.models";
import { ContractApiService } from "../../services/contract-api.service";
import { ContractLoaderService } from "../../services/contract-loader.service";

@Component({
    selector: "ge-period-list",
    templateUrl: "./period-list.component.html",
    styleUrl: "./period-list.component.scss",
})
export class PeriodListComponent
    extends DestructibleController
    implements OnChanges
{
    @Input()
    public readonly periods: IRatePeriod[];

    @Input()
    public readonly contract: IBaseContract;

    @Input()
    public readonly periodIndex: number;

    @Output()
    public readonly periodSelect: EventEmitter<number> =
        new EventEmitter<number>();

    @Output()
    public readonly periodsCreate: EventEmitter<IRatePeriod[]> =
        new EventEmitter<IRatePeriod[]>();

    public modifiedPeriods!: IRatePeriod[];

    public selectedPeriod!: IRatePeriod;

    public newPeriodVisible = false;

    public newPeriodStart: string | null = null;

    public newPeriodEnd: string | null = null;

    constructor(
        private apiService: ContractApiService,
        private loaderService: ContractLoaderService,
        private notificationService: ToasterNotificationService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { periods, periodIndex } = changes;

        if (periods && this.periods) {
            this.modifiedPeriods = [...this.periods];

            this.selectedPeriod = this.modifiedPeriods[this.periodIndex || 0];
        }

        if (periodIndex && this.modifiedPeriods) {
            this.selectedPeriod = this.modifiedPeriods[this.periodIndex || 0];
        }
    }

    public selectPeriod(index: number): void {
        this.selectedPeriod = this.modifiedPeriods[index];

        if (this.periods[index]) {
            this.periodSelect.emit(index);
        }
    }

    public addPeriod: () => void = () => {
        this.newPeriodVisible = true;
    };

    public updateRatePeriod(
        index: number,
        options: Partial<IRatePeriod>,
    ): void {
        const period = this.periods[index];

        this.loaderService.startLoading();

        this.apiService
            .updateContractPeriod(this.contract.id, [{ ...period, ...options }])
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (periods: IRatePeriod[] = []) => {
                    this.periods.splice(index, 1, periods[0]);
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public createRatePeriods: () => void = () => {
        const period = {
            from: this.newPeriodStart,
            to: this.newPeriodEnd,
        };

        this.loaderService.startLoading();

        this.apiService
            .updateContractPeriod(this.contract.id, [period])
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (periods: IRatePeriod[]) => {
                    this.periodsCreate.emit(periods);

                    this.newPeriodVisible = false;
                    this.newPeriodStart = null;
                    this.newPeriodEnd = null;

                    this.notificationService.successToaster(
                        "relations.contracts.periodCreated",
                    );
                },
                ({ message }: any & object) => {
                    this.notificationService.errorToaster(
                        message.indexOf("00 90") !== -1
                            ? "relations.contracts.periodHasTimeInSamePeriod"
                            : "errors.commonError",
                    );
                },
            );
    };
}
