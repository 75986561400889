import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { NetworkErrorModalComponent } from "../components/network-error-modal/network-error-modal.component";
import { ModalWindowService } from "../components/modal-window/modal-window.service";
import { ToasterNotificationService } from "../components/toaster-notification/toaster-notification.service";
import { Router } from "@angular/router";

@Injectable()
export class NetworkErrorInterceptor {
    private isModalOpen = false;
    private modalTimeout: any;
    private toasterService: ToasterNotificationService;
    private router: Router;

    constructor(
        private modalWindowService: ModalWindowService,
        private injector: Injector,
    ) {
        this.router = injector.get(Router);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                () => {},
                (err) => {
                    if (
                        (err.status === 0 ||
                            err.status === 500 ||
                            err.status === 502) &&
                        !this.isModalOpen
                    ) {
                        const isOnOfficeScreensPage = this.router.url.includes(
                            "/office-screens-planboard",
                        );
                        if (isOnOfficeScreensPage) {
                            return;
                        }

                        // TODO: it's should be moved to the constructor, but it calls cycled dependency issue :), something is wrong
                        if (!this.toasterService) {
                            this.toasterService = this.injector.get(
                                ToasterNotificationService,
                            );
                        }

                        this.toasterService.setDisableToaster(true);
                        this.isModalOpen = true;

                        this.modalWindowService
                            .open(NetworkErrorModalComponent, {
                                ngbOptions: {
                                    centered: true,
                                    backdropClass: "network-error-backdrop",
                                    windowClass: "network-error-window",
                                },
                            })
                            .subscribe(
                                this.onModalClose.bind(this),
                                this.onModalClose.bind(this),
                            );
                    }
                },
            ),
        );
    }

    onModalClose() {
        if (this.modalTimeout) {
            clearTimeout(this.modalTimeout);
        }

        this.modalTimeout = setTimeout(() => {
            this.isModalOpen = false;
        }, 500);
    }
}
