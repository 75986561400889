import { Component, OnInit } from "@angular/core";
import { LayoutService } from "./layout.service";
import { delay, takeUntil } from "rxjs/operators";
import { StorageService } from "../services/storage.service";
import { RootLoader } from "../trips/trips-overview/services/root-loader.service";
import { DestructibleController } from "../common/classes/destructible.controller";

@Component({
    selector: "ge-layout",
    templateUrl: "./layout.component.html",
    styleUrl: "./layout.component.scss",
})
export class LayoutComponent extends DestructibleController implements OnInit {
    public isMenuCollapsed = false;
    public userCompany: string;
    protected isLoading = false;

    constructor(
        private layoutService: LayoutService,
        private storage: StorageService,
        private loaderService: RootLoader,
    ) {
        super();
        this.layoutService.isMenuCollapsed$
            .pipe(delay(0), takeUntil(this.destroy$))
            .subscribe((res) => {
                this.isMenuCollapsed = res;
            });
        this.userCompany = this.storage.getItem("company");
    }

    ngOnInit() {
        this.loaderService.loading$
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: boolean) => {
                this.isLoading = value;
            });
    }
}
