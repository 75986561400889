<div class="chatModalContainer ge-form-group no-bottom-margin">
    <div class="chat-container">
        <ge-modal-header
            data-translate-me
            [translateMe]="'chat.chat'"
            title="{{ 'chat.chat' | i18n }}"
        ></ge-modal-header>
        <div class="chatPart">
            <aside class="driversSide">
                <div class="inputBtn">
                    <input
                        id="search"
                        autocomplete="off"
                        class="ge-form-control"
                        [(ngModel)]="searchText"
                        data-translate-me
                        [translateMe]="'chat.searchForDrivers'"
                        placeholder="{{ 'chat.searchForDrivers' | i18n }}"
                    />
                    <div class="selectAll">
                        <ge-checkbox
                            id="allBtn"
                            maxWidth="150px"
                            [checkboxValue]="isAllSelected"
                            (checkboxToggle)="toggleDriversSelection()"
                        ></ge-checkbox>
                        <span
                            (click)="toggleDriversSelection()"
                            data-translate-me
                            [translateMe]="'chat.selectAll'"
                            >{{ "chat.selectAll" | i18n }}</span
                        >
                    </div>
                    <!-- <button class="pickAllDrivers" (click)="pickAllDrivers()">Pick all drivers</button> -->
                </div>
                <ge-scroll-view class="driversWrapper">
                    <ng-container
                        *ngFor="let driver of drivers | filter: searchText"
                    >
                        <ge-chat-drivers-item
                            [driver]="driver"
                            [currentDriverId]="currentDriverId"
                            [selectedDriverIds]="selectedDriverIds"
                            (check)="onCheck($event, driver)"
                            (selectCurrent)="onSelectDriver($event)"
                        >
                        </ge-chat-drivers-item>
                    </ng-container>
                    <div
                        *ngIf="this.drivers && isLoaded && isSearchResult"
                        data-translate-me
                        [translateMe]="'chat.filterResult'"
                    >
                        {{ "chat.filterResult" | i18n }}
                    </div>
                </ge-scroll-view>
            </aside>

            <div class="chatWindowContainer">
                <div class="chat-plug" *ngIf="!selectedDriverIds.length">
                    <p data-translate-me [translateMe]="'chat.startChatPlug'">
                        {{ "chat.startChatPlug" | i18n }}
                    </p>
                </div>
                <div [hidden]="!selectedDriverIds.length" class="scrollView">
                    <div
                        class="mass-plag"
                        [hidden]="selectedDriverIds.length < 2"
                    >
                        <p
                            data-translate-me
                            [translateMe]="'chat.massSendingPlug'"
                        >
                            {{
                                "chat.massSendingPlug"
                                    | i18n
                                        : {
                                              checkedForMessageSending:
                                                  selectedDriverIds.length,
                                          }
                            }}
                        </p>
                    </div>
                    <ge-scroll-view
                        class="chat-scroll"
                        [hidden]="selectedDriverIds.length > 1"
                        [onScrollTop]="paginate.bind(this)"
                        #scrollViewRef
                    >
                        <div
                            *ngFor="let message of messages; let i = index"
                            [id]="'chat-message-' + i"
                        >
                            <ge-chat-messages
                                [message]="message"
                            ></ge-chat-messages>
                        </div>
                    </ge-scroll-view>
                    <div class="send-form">
                        <textarea
                            class="message-textarea"
                            placeholder="{{ 'chat.typeAMessage' | i18n }}"
                            data-translate-me
                            [translateMe]="'chat.typeAMessage'"
                            [ngModelOptions]="{ standalone: true }"
                            (keydown.enter)="onMessage()"
                            [(ngModel)]="message"
                            autosize
                        ></textarea>
                        <button class="send-message-btn" (click)="onMessage()">
                            <img src="/assets/imgs/chat_sending_button.svg" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <ge-loader [loading]="loading"></ge-loader>
    </div>
</div>
