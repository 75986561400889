import { CommonModule } from "@angular/common";
import { ToFixedDirective } from "./to-fixed.directive";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [ToFixedDirective],
    imports: [CommonModule],
    exports: [ToFixedDirective],
})
export class ToFixedModule {}
