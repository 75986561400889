/* src/app/relations/clients/modals/contract-modal/components/contract-types/viabus-contract/viabus-details/viabus-details.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.seat-name {
  width: 100px;
  line-height: 40px;
  margin-right: 10px;
  font-weight: 600;
}
.bustype-item {
  height: 40px;
  display: flex;
  align-items: center;
}
.bustype-item .remove-btn {
  width: 20px;
  opacity: 0;
  line-height: 40px;
  cursor: pointer;
  transition: 180ms ease;
}
.bustype-item.bustype-form input[type=number] {
  width: 65px;
}
.bustype-item input[type=number] {
  width: 100px;
}
.bustype-item:hover .remove-btn {
  opacity: 1;
}
.seat-block {
  width: 33%;
  display: flex;
  font-size: 16px;
}
.bustype-select {
  width: 160px;
}
.add-btn {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  color: #32946e;
  border: 1px solid #dbdfe8;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04);
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}
.add-btn.disabled {
  pointer-events: none;
  cursor: auto;
  opacity: 0.35;
}
/*# sourceMappingURL=viabus-details.component.css.map */
