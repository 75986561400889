<form
    class="middle-box text-center loginscreen animated fadeInDown"
    [formGroup]="loginForm"
>
    <img id="authMainLogo" alt="Gebotours" src="assets/imgs/logo.png" />
    <br />
    <br />
    <p data-translate-me [translateMe]="'login.info'">
        {{ "login.info" | i18n }}
    </p>

    <div class="m-t">
        <ge-input
            formControlName="login"
            placeholder="{{ 'login.login' | i18n }}"
            data-translate-me
            [translateMe]="'login.login'"
        >
            <div
                geValidatorMessage="required"
                data-translate-me
                [translateMe]="'login.errors.requiredLogi'"
            >
                {{ "login.errors.requiredLogin" | i18n }}
            </div>
            <div
                geValidatorMessage="minlength"
                data-translate-me
                [translateMe]="'login.errors.minLogin'"
            >
                {{ "login.errors.minLogin" | i18n }}
            </div>
            <div
                geValidatorMessage="maxlength"
                data-translate-me
                [translateMe]="'login.errors.maxLogin'"
            >
                {{ "login.errors.maxLogin" | i18n }}
            </div>
        </ge-input>

        <ge-input
            type="password"
            formControlName="password"
            placeholder="{{ 'login.password' | i18n }}"
            data-translate-me
            [translateMe]="'login.password'"
        >
            <div
                geValidatorMessage="required"
                data-translate-me
                [translateMe]="'login.errors.requiredPassword'"
            >
                {{ "login.errors.requiredPassword" | i18n }}
            </div>
            <div
                geValidatorMessage="minlength"
                data-translate-me
                [translateMe]="'login.errors.minPassword'"
            >
                {{ "login.errors.minPassword" | i18n }}
            </div>
            <div
                geValidatorMessage="maxlength"
                data-translate-me
                [translateMe]="'login.errors.maxPassword'"
            >
                {{ "login.errors.maxPassword" | i18n }}
            </div>

            <div
                class="errors"
                data-translate-me
                [translateMe]="getErrorDescription()"
            >
                <p>{{ getErrorDescription() | i18n }}</p>
            </div>
        </ge-input>

        <ge-button
            id="logInbtn"
            theme="cranberry"
            [click]="submit.bind(this)"
            [disabled]="loginForm?.invalid"
            data-translate-me
            [translateMe]="'login.loginButton'"
        >
            {{ "login.loginButton" | i18n }}
        </ge-button>
    </div>
</form>
