<div>
    <div *ngIf="details?.length">
        <h3 data-translate-me [translateMe]="'relations.contracts.ratesInfo'">
            {{ "relations.contracts.ratesInfo" | i18n }}
        </h3>
        <div
            class="margin-top-20 d-flex align-items-center"
            *ngFor="let item of details; let index = index"
        >
            <ge-period-input
                [startDate]="item.period.from"
                [endDate]="item.period.to"
                [inputVisible]="true"
                (startDateUpdate)="updateStartDate($event, index)"
                (endDateUpdate)="updateEndDate($event, index)"
            ></ge-period-input>
            <div class="rate-info ge-form-group">
                <div>
                    <span class="info-title">Driver Hour</span>
                    <ge-editor-field autoOpen #driverInput>
                        <span class="info-value" editorLabel>
                            € {{ item.driversHourRate | gePrice }}
                        </span>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            autocomplete="off"
                            [(ngModel)]="item.driversHourRate"
                            (blur)="
                                driverInput.hideInput();
                                updateField($event, index, 'driversHourRate')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </div>

                <div>
                    <span class="info-title">Kilometer</span>
                    <ge-editor-field autoOpen #kilometersInput>
                        <span class="info-value" editorLabel>
                            € {{ item.kilometersRate | gePrice }}
                        </span>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            autocomplete="off"
                            [(ngModel)]="item.kilometersRate"
                            (blur)="
                                kilometersInput.hideInput();
                                updateField($event, index, 'kilometersRate')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </div>

                <div>
                    <span class="info-title">Minimum Kilometers</span>
                    <ge-editor-field autoOpen #minKmInput>
                        <span class="info-value" editorLabel>
                            {{ item.minKm }}
                        </span>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            autocomplete="off"
                            [(ngModel)]="item.minKm"
                            (blur)="
                                minKmInput.hideInput();
                                updateField($event, index, 'minKm')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </div>

                <div>
                    <span class="info-title">Minimum passengers</span>
                    <ge-editor-field autoOpen #minPassengersInput>
                        <span class="info-value" editorLabel>
                            {{ item.minPassengers }}
                        </span>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="item.minPassengers"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                minPassengersInput.hideInput();
                                updateField($event, index, 'minPassengers')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </div>

                <div class="d-flex align-items-center">
                    <div
                        *ngIf="isDeleteAvailable"
                        class="remove-btn"
                        (click)="removeRate(index)"
                    >
                        <ge-svg icon="trash"></ge-svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-top-20 d-flex align-items-end">
        <ge-period-input
            [inputVisible]="true"
            [startDate]="newFromDate"
            [endDate]="newToDate"
            (startDateUpdate)="newFromDate = $event"
            (endDateUpdate)="newToDate = $event"
        ></ge-period-input>
        <div class="rate-info ge-form-group">
            <div>
                <span class="info-title">Driver Hour</span>
                <input
                    class="ge-form-control no-arrow-btns"
                    type="number"
                    (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    [(ngModel)]="newDriversHourRate"
                />
            </div>

            <div>
                <span class="info-title">Kilometer</span>
                <input
                    class="ge-form-control no-arrow-btns"
                    type="number"
                    (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    [(ngModel)]="newKilometersRate"
                />
            </div>

            <div>
                <span class="info-title">Minimum Kilometers</span>
                <input
                    class="ge-form-control no-arrow-btns"
                    type="number"
                    (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    [(ngModel)]="newMinKm"
                />
            </div>

            <div>
                <span class="info-title">Minimum passengers</span>
                <input
                    class="ge-form-control no-arrow-btns"
                    type="number"
                    (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    [(ngModel)]="newMinPassengers"
                />
            </div>

            <div class="d-flex align-items-center">
                <button
                    class="add-btn margin-right-5"
                    [ngClass]="{
                        disabled: !(
                            newFromDate &&
                            newToDate &&
                            newDriversHourRate > 0 &&
                            newKilometersRate > 0 &&
                            newMinKm > 0 &&
                            newMinPassengers >= 0
                        ),
                    }"
                    (click)="createRate()"
                >
                    +
                </button>
            </div>
        </div>
    </div>
</div>
