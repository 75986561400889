<h3 data-translate-me [translateMe]="'relations.contracts.periods'">
    {{ "relations.contracts.periods" | i18n }}
</h3>
<div class="d-flex">
    <ge-period-list
        [periods]="periods"
        [contract]="contract"
        [periodIndex]="selectedPeriodIndex"
        (periodSelect)="onPeriodSelect($event)"
        (periodsCreate)="setNewPeriods($event)"
    ></ge-period-list>
</div>
<div
    *ngIf="details"
    class="contract-period-details ge-form-group no-bottom-margin"
>
    <h3
        data-translate-me
        [translateMe]="'relations.contracts.secondDriverPrice'"
    >
        {{ "relations.contracts.secondDriverPrice" | i18n }}
    </h3>
    <div class="d-flex align-items-center">
        <input
            class="ge-form-control no-arrow-btns margin-right-10"
            autocomplete="off"
            style="width: 250px"
            type="number"
            (wheel)="EventHelper.cancelOnWheelEvent($event)"
            [value]="details.secondDriverRate"
            (input)="newSecondDriver = +driverRateInput.value"
            #driverRateInput
        />
        <ge-button
            *ngIf="newSecondDriver !== details.secondDriverRate"
            theme="cranberry"
            [click]="contractUpdateSecondDriverAndCollectionReturn"
            data-translate-me
            [translateMe]="'relations.contracts.update'"
        >
            {{ "relations.contracts.update" | i18n }}
        </ge-button>
    </div>
</div>

<!-- Collection&return -->
<div
    *ngIf="details"
    class="contract-period-details ge-form-group no-bottom-margin"
>
    <h3
        data-translate-me
        [translateMe]="'relations.contracts.collectionReturn'"
    >
        {{ "relations.contracts.collectionReturn" | i18n }}
    </h3>
    <div class="d-flex align-items-center">
        <input
            class="ge-form-control no-arrow-btns margin-right-10"
            autocomplete="off"
            style="width: 250px"
            type="string"
            [value]="details.collectionAndReturnPrice | numberToPriceString"
            (input)="collectionReturn = collectionReturnInput.value"
            #collectionReturnInput
        />
        <ge-button
            *ngIf="
                collectionReturn !==
                (details.collectionAndReturnPrice | numberToPriceString)
            "
            theme="cranberry"
            [click]="contractUpdateSecondDriverAndCollectionReturn"
            data-translate-me
            [translateMe]="'relations.contracts.update'"
        >
            {{ "relations.contracts.update" | i18n }}
        </ge-button>
    </div>
</div>

<div class="contract-period-details">
    <h3 data-translate-me [translateMe]="'relations.contracts.ratesInfo'">
        {{ "relations.contracts.ratesInfo" | i18n }}
    </h3>
    <ge-viabus-details
        [details]="rateDetails"
        [customBusTypes]="availableBusTypes"
        (ratesChange)="updateDetails($event)"
        (rateDelete)="deleteRate($event)"
        (createRate)="createRate($event)"
    ></ge-viabus-details>
</div>
<div class="margin-top-20">
    <ge-new-bus-type
        [contractId]="contract.id"
        (bustypeCreate)="createNewBusType($event)"
    ></ge-new-bus-type>
</div>
