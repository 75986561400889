/* src/app/layout/top-navbar/top-navbar.component.scss */
.navbar {
  padding: 0 24px;
}
.notifications {
  width: 135px;
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
/*# sourceMappingURL=top-navbar.component.css.map */
