import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { DestructibleController } from "../../../../../../../../common/classes/destructible.controller";
import { ToasterNotificationService } from "../../../../../../../../shared/components/toaster-notification/toaster-notification.service";
import {
    IContractBusTypeSummary,
    ICountry,
    ICountryItem,
    ICustomBusType,
    IRatePeriod,
    ISunwebCountry,
    ISunwebPriceDetail,
} from "../../../../contract-modal.models";
import { ContractApiService } from "../../../../services/contract-api.service";
import { ContractLoaderService } from "../../../../services/contract-loader.service";
import {
    ContractType,
    nonDeleteBusTypesContracts,
} from "src/app/shared/models/contracts.models";
import { EventHelper } from "../../../../../../../../shared/helpers/event-helper";

interface ISunwebRow {
    customBusType: ICustomBusType;
    countries: Record<
        string,
        {
            id: number;
            fridayPrice: number;
            midweekPrice: number;
        }
    >;
}

export interface ISunwebPriceUpdate {
    id: number;
    country: string;
    countryISO: string;
    midweekPrice: number;
    fridayPrice: number;
    ratesPeriod: IRatePeriod;
    customBusType: {
        id: number;
        name: string;
    };
}

@Component({
    selector: "ge-sunweb-table",
    templateUrl: "./sunweb-table.component.html",
    styleUrl: "./sunweb-table.component.scss",
})
export class SunwebTableComponent
    extends DestructibleController
    implements OnChanges
{
    @Input("data")
    public readonly _data!: ISunwebPriceDetail[];

    @Input()
    public readonly busTypes!: IContractBusTypeSummary[];

    @Input()
    public readonly period!: IRatePeriod;

    @Input()
    public readonly countries!: ISunwebCountry[];

    @Output()
    public readonly updateRate: EventEmitter<ISunwebPriceUpdate> =
        new EventEmitter<ISunwebPriceUpdate>();

    @Output()
    public readonly addCountry: EventEmitter<ICountryItem> =
        new EventEmitter<ICountryItem>();

    @Output()
    public readonly deleteRate: EventEmitter<number> =
        new EventEmitter<number>();

    @ViewChild("countryInput", {
        static: false,
    })
    public readonly countryInput!: ElementRef<HTMLInputElement>;

    @ViewChild("busTypeSelect", {
        static: false,
    })
    public readonly busTypeSelect!: ElementRef<HTMLSelectElement>;

    public showCountryInput = false;

    public availableTypes: IContractBusTypeSummary[] = [];

    public data!: ISunwebPriceDetail[];

    public newBusTypeId!: number | null;

    public rows!: ISunwebRow[];

    constructor(
        private apiService: ContractApiService,
        private loaderService: ContractLoaderService,
        private notificationService: ToasterNotificationService,
        private zone: NgZone,
    ) {
        super();
    }

    public get isDeleteAvailable(): boolean {
        return nonDeleteBusTypesContracts.indexOf(ContractType.Sunweb) < 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { _data, period, countries, busTypes } = changes;

        if (_data || period || countries || busTypes) {
            this.data = (this._data || []).filter(
                (item: ISunwebPriceDetail) => {
                    return item.period.id === this.period.id;
                },
            );

            this.buildTableRows();

            this.newBusTypeId = null;

            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    if (
                        this.busTypeSelect &&
                        this.busTypeSelect.nativeElement
                    ) {
                        this.busTypeSelect.nativeElement.value = "";
                    }
                });
            });
        }
    }

    public buildTableRows(): void {
        this.rows = [];

        this.data.forEach((item: ISunwebPriceDetail) => {
            const existRow = this.rows.find(
                (row: ISunwebRow) =>
                    row.customBusType.id === item.customBusType.id,
            );

            if (existRow) {
                existRow.countries[item.sunwebCountry.name] = {
                    id: item.id,
                    fridayPrice: item.fridayPrice,
                    midweekPrice: item.midweekPrice,
                };
            } else {
                this.rows.push({
                    customBusType: item.customBusType,
                    countries: {
                        [item.sunwebCountry.name]: {
                            id: item.id,
                            fridayPrice: item.fridayPrice,
                            midweekPrice: item.midweekPrice,
                        },
                    },
                });
            }
        });

        this.availableTypes = (this.busTypes || []).filter(
            (item: IContractBusTypeSummary) => {
                return !this.rows.find(
                    (row: ISunwebRow) => row.customBusType.id === item.id,
                );
            },
        );
    }

    public removePrice(id: number): void {
        this.deleteRate.emit(id);
    }

    public addCountryInput(): void {
        this.showCountryInput = true;

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.countryInput && this.countryInput.nativeElement) {
                    this.countryInput.nativeElement.focus();
                }
            });
        });
    }

    public createCountry(value: string): void {
        this.showCountryInput = false;

        if (!value.trim()) {
            return;
        }

        this.loaderService.startLoading();

        this.apiService
            .getCountryISO(value)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (resCountries: ICountry[]) => {
                    if (!resCountries[0]) {
                        return;
                    }

                    const country = this.countries.find(
                        (item: ISunwebCountry) => {
                            return item.iso === resCountries[0].iso;
                        },
                    );

                    if (country) {
                        this.notificationService.errorToaster(
                            "relations.contracts.countryExist",
                        );

                        return;
                    }

                    this.addCountry.emit({
                        countryName: resCountries[0].name,
                        iSO_CountryCode: resCountries[0].iso,
                    });
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public changePrice(
        event: Event,
        price: "fridayPrice" | "midweekPrice",
        row: null | ISunwebRow,
        country: string,
        countryISO: string,
    ): void {
        const { value } = event.target as HTMLInputElement;
        let id: number;
        let midweekPrice: number;
        let fridayPrice: number;

        if (row && row.countries[country]) {
            id = row.countries[country].id;
        }

        fridayPrice =
            price === "fridayPrice"
                ? parseFloat(value)
                : id
                  ? row.countries[country].fridayPrice
                  : 0;

        midweekPrice =
            price === "midweekPrice"
                ? parseFloat(value)
                : id
                  ? row.countries[country].midweekPrice
                  : 0;

        const newBusType = this.availableTypes.find(
            (item: IContractBusTypeSummary) => item.id === +this.newBusTypeId,
        );

        const customBusType = row
            ? row.customBusType
            : {
                  id: newBusType.id,
                  name: newBusType.name,
              };
        this.updateRate.emit({
            id,
            midweekPrice,
            fridayPrice,
            country,
            countryISO,
            customBusType,
            ratesPeriod: this.period,
        });
    }

    protected readonly EventHelper = EventHelper;
}
