<ge-modal-window>
    <div class="row">
        <div class="col-md-12">
            <div class="geupload" [class.hide]="attachments.length">
                <ge-upload
                    #fileUploader
                    dropAllowed="true"
                    [multiple]="false"
                    (onChanged)="readFile($event)"
                ></ge-upload>
            </div>
        </div>

        <div class="col-md-12">
            <div *ngIf="attachments.length">
                <ge-attachment-preview
                    [isEdit]="true"
                    [attachment]="attachments"
                    (onChange)="removeAttachment($event)"
                >
                </ge-attachment-preview>
            </div>
        </div>
    </div>
</ge-modal-window>
