<div>
    <h3
        class="margin-bottom-15"
        data-translate-me
        [translateMe]="'relations.contracts.busType'"
    >
        {{ "relations.contracts.busType" | i18n }}
    </h3>
    <div class="d-flex bustype-container margin-bottom-20">
        <div
            class="bustype-block"
            *ngFor="let item of busTypes; let index = index"
            [ngClass]="{ selected: index === selectedItem }"
            (click)="selectBusType(index)"
        >
            {{ item.name }}
        </div>
    </div>
    <ng-container *ngIf="ort">
        <h3
            class="margin-bottom-15"
            data-translate-me
            [translateMe]="'management.pricing.ort'"
        >
            {{ "management.pricing.ort" | i18n }}
        </h3>
        <div
            class="d-flex justify-content-between margin-bottom-20 table-vertical-middle"
            style="width: 60%"
        >
            <table class="table ge-form-group no-bottom-margin ort-table">
                <tbody>
                    <tr>
                        <td
                            data-translate-me
                            [translateMe]="'management.pricing.saturday'"
                        >
                            {{ "management.pricing.saturday" | i18n }}
                        </td>
                        <td>
                            <ge-editor-field autoOpen #saturdayRef>
                                <span
                                    editorLabel
                                    data-translate-me
                                    [translateMe]="'management.pricing.perHour'"
                                >
                                    {{ ort.saturday | gePrice }} /
                                    {{ "management.pricing.perHour" | i18n }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="ort.saturday"
                                    autocomplete="off"
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                    (blur)="
                                        saturdayRef.hideInput();
                                        updateOrt('saturday', $event)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                    <tr>
                        <td
                            data-translate-me
                            [translateMe]="'management.pricing.holidays'"
                        >
                            {{ "management.pricing.holidays" | i18n }}
                        </td>
                        <td>
                            <ge-editor-field #holidaysRef>
                                <span
                                    editorLabel
                                    data-translate-me
                                    [translateMe]="'management.pricing.perHour'"
                                >
                                    {{ ort.holidays | gePrice }} /
                                    {{ "management.pricing.perHour" | i18n }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="ort.holidays"
                                    autocomplete="off"
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                    (blur)="
                                        holidaysRef.hideInput();
                                        updateOrt('holidays', $event)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                    <tr>
                        <td
                            data-translate-me
                            [translateMe]="'management.pricing.nightWork'"
                        >
                            {{ "management.pricing.nightWork" | i18n }}
                        </td>
                        <td>
                            <ge-editor-field #nightWorkRef>
                                <span
                                    editorLabel
                                    data-translate-me
                                    [translateMe]="'management.pricing.perHour'"
                                >
                                    {{ ort.nightWork | gePrice }} /
                                    {{ "management.pricing.perHour" | i18n }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="ort.nightWork"
                                    autocomplete="off"
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                    (blur)="
                                        nightWorkRef.hideInput();
                                        updateOrt('nightWork', $event)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                    <tr>
                        <td
                            data-translate-me
                            [translateMe]="
                                'management.pricing.secondDriverHourRate'
                            "
                        >
                            {{
                                "management.pricing.secondDriverHourRate" | i18n
                            }}
                        </td>
                        <td>
                            <ge-editor-field #secondDriverHourRateRef>
                                <span
                                    editorLabel
                                    data-translate-me
                                    [translateMe]="'management.pricing.perHour'"
                                >
                                    {{ ort.secondDriverHourRate | gePrice }} /
                                    {{ "management.pricing.perHour" | i18n }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    autocomplete="off"
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                    [value]="ort.secondDriverHourRate"
                                    (blur)="
                                        secondDriverHourRateRef.hideInput();
                                        updateOrt(
                                            'secondDriverHourRate',
                                            $event
                                        )
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <h3
        class="margin-bottom-15"
        data-translate-me
        [translateMe]="'relations.contracts.ratesInfo'"
    >
        {{ "relations.contracts.ratesInfo" | i18n }}
    </h3>
    <div
        *ngIf="multiplierDateToMultipliersObjId && multipliers"
        class="margin-bottom-20"
    >
        <div class="d-flex margin-bottom-10 justify-content-between">
            <div class="d-flex margin-bottom-10 flex-grow-1 overflow-auto">
                <div
                    *ngFor="let multiplier of multipliers"
                    class="multiplier"
                    [ngClass]="{ active: selectedMultiplier === multiplier }"
                    (click)="selectedMultiplier = multiplier"
                >
                    <div
                        class="marker-color"
                        [ngStyle]="{ background: multiplier.color }"
                    ></div>
                    <span>{{ multiplier.value }}</span>
                </div>
            </div>
            <div
                class="d-flex margin-left-15 white-space-no ge-form-group no-bottom-margin"
            >
                <ng-container *ngIf="!multiplierInputVisible">
                    <div class="margin-right-10">
                        <ge-button
                            [click]="openMultiplierInput"
                            data-translate-me
                            [translateMe]="
                                'relations.contracts.createMultiplier'
                            "
                        >
                            {{ "relations.contracts.createMultiplier" | i18n }}
                        </ge-button>
                    </div>
                    <div class="margin-right-10" *ngIf="changedMultipliers">
                        <ge-button
                            [click]="resetMultipliers"
                            data-translate-me
                            [translateMe]="'relations.contracts.resetChanges'"
                        >
                            {{ "relations.contracts.resetChanges" | i18n }}
                        </ge-button>
                    </div>
                    <ge-button
                        *ngIf="changedMultipliers"
                        theme="cranberry"
                        [click]="saveMultipliers"
                        data-translate-me
                        [translateMe]="'busCardInfo.save'"
                    >
                        {{ "busCardInfo.save" | i18n }}
                    </ge-button>
                </ng-container>
                <ng-container *ngIf="multiplierInputVisible">
                    <input
                        class="ge-form-control margin-right-10 no-arrow-btns"
                        type="number"
                        [(ngModel)]="multiplierValue"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                    <div style="min-width: 39px" class="margin-right-10">
                        <input
                            class="ge-form-control"
                            type="color"
                            [(ngModel)]="multiplierColor"
                        />
                    </div>
                    <ge-button
                        class="margin-right-10"
                        [click]="cancelMultiplier"
                        data-translate-me
                        [translateMe]="'relations.contracts.cancel'"
                    >
                        {{ "relations.contracts.cancel" | i18n }}
                    </ge-button>
                    <ge-button
                        theme="cranberry"
                        [disabled]="!multiplierColor || !multiplierValue"
                        [click]="createMultiplier"
                        data-translate-me
                        [translateMe]="'relations.contracts.create'"
                    >
                        {{ "relations.contracts.create" | i18n }}
                    </ge-button>
                </ng-container>
            </div>
        </div>
        <ge-full-year-calendar
            [selectedDates]="multiplierDateToMultipliersObjId"
            [multipliers]="multipliers"
            [multiplierId]="selectedMultiplier?.id"
            [minDate]="contract.activeFrom"
            [maxDate]="contract.activeTo"
            [readOnly]="false"
            [minYear]="minYear"
            [maxYear]="maxYear"
            (onDateChanged)="onDateChanged($event)"
            (yearChanged)="updateYear($event)"
        ></ge-full-year-calendar>
    </div>
    <div
        *ngIf="dayRate && hourRates && kilometerRates"
        class="data d-flex justify-content-between ge-form-group no-bottom-margin"
    >
        <div class="table-vertical-middle">
            <h3
                class="margin-bottom-15"
                data-translate-me
                [translateMe]="'management.pricing.ratesType.minimumDayRate'"
            >
                {{ "management.pricing.ratesType.minimumDayRate" | i18n }}
            </h3>
            <table class="table">
                <thead>
                    <tr>
                        <th
                            data-translate-me
                            [translateMe]="
                                'management.pricing.ratesType.minimumDayRate'
                            "
                        >
                            {{
                                "management.pricing.ratesType.minimumDayRate"
                                    | i18n
                            }}
                        </th>
                        <th>€</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            data-translate-me
                            [translateMe]="
                                'management.pricing.ratesType.minimumDayRate'
                            "
                        >
                            {{
                                "management.pricing.ratesType.minimumDayRate"
                                    | i18n
                            }}
                        </td>
                        <td class="day-width">
                            <ge-editor-field
                                *ngIf="dayRate"
                                autoOpen
                                #dayRatesRef
                            >
                                <span editorLabel>
                                    € {{ dayRate.price | gePrice }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="dayRate.price"
                                    (blur)="
                                        dayRatesRef.hideInput();
                                        updateDayRate($event, dayRate)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-vertical-middle">
            <h3
                class="margin-bottom-15"
                data-translate-me
                [translateMe]="'management.pricing.ratesType.hoursRates'"
            >
                {{ "management.pricing.ratesType.hoursRates" | i18n }}
            </h3>
            <table class="table">
                <thead>
                    <tr>
                        <th
                            data-translate-me
                            [translateMe]="'management.pricing.ratesType.from'"
                        >
                            {{ "management.pricing.ratesType.from" | i18n }}
                        </th>
                        <th
                            data-translate-me
                            [translateMe]="'management.pricing.ratesType.to'"
                        >
                            {{ "management.pricing.ratesType.to" | i18n }}
                        </th>
                        <th
                            data-translate-me
                            [translateMe]="
                                'management.pricing.ratesType.pricePerHour'
                            "
                        >
                            {{
                                "management.pricing.ratesType.pricePerHour"
                                    | i18n
                            }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let rate of hourRates;
                            let i = index;
                            let last = last
                        "
                    >
                        <td>{{ last ? "> 24 hrs" : rate.displayHour.from }}</td>
                        <td>
                            <span *ngIf="!last">{{ rate.displayHour.to }}</span>
                        </td>
                        <td class="hour-width">
                            <ge-editor-field autoOpen #hourRef>
                                <span editorLabel>
                                    € {{ rate.price | gePrice }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="rate.price"
                                    (blur)="
                                        hourRef.hideInput();
                                        updateHourRate($event, rate)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="table-vertical-middle">
            <h3
                class="margin-bottom-15"
                data-translate-me
                [translateMe]="'management.pricing.ratesType.kilometersRates'"
            >
                {{ "management.pricing.ratesType.kilometersRates" | i18n }}
            </h3>
            <table class="table">
                <thead>
                    <tr>
                        <th
                            data-translate-me
                            [translateMe]="'management.pricing.ratesType.from'"
                        >
                            {{ "management.pricing.ratesType.from" | i18n }}
                        </th>
                        <th
                            data-translate-me
                            [translateMe]="'management.pricing.ratesType.to'"
                        >
                            {{ "management.pricing.ratesType.to" | i18n }}
                        </th>
                        <th
                            data-translate-me
                            [translateMe]="
                                'management.pricing.ratesType.pricePerKilometer'
                            "
                        >
                            {{
                                "management.pricing.ratesType.pricePerKilometer"
                                    | i18n
                            }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let rate of kilometerRates;
                            let i = index;
                            let first = first;
                            let last = last
                        "
                    >
                        <td>{{ last ? "> 501 km" : rate.from }}</td>
                        <td>
                            <span *ngIf="!last">{{
                                first ? rate.from + 50 : rate.from + 49
                            }}</span>
                        </td>
                        <td class="kilometer-width">
                            <ge-editor-field autoOpen #kilometerRateRef>
                                <span editorLabel>
                                    € {{ rate.price | gePrice }}
                                </span>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    [value]="rate.price"
                                    (blur)="
                                        kilometerRateRef.hideInput();
                                        updateKilometerRate($event, rate)
                                    "
                                    editorInput
                                />
                            </ge-editor-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
