import { DaysOffService } from "./driver-hours/days-off/shared/days-off.service";
import { DaysOffModule } from "./driver-hours/days-off/days-off.module";
import { registerLocaleData } from "@angular/common";
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import localeNl from "@angular/common/locales/nl";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Apollo, ApolloModule } from "apollo-angular";
import { NgScrollbarModule } from "ngx-scrollbar";
import { throwError } from "rxjs";
import { SharedModule } from "src/app/shared/shared.module";
import { environment } from "src/environments/environment";
import { AddTaskModalComponent } from "./actions/components/modals/add-task-modal/add-task-modal.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
// import { ChatModule } from './Chat/chat.module';
import { AdditionalModule } from "./common/additional.module";
import { AddBusModalComponent } from "./data/busses/modals/add-bus-modal/add-bus-modal.component";
import { AddCarModalComponent } from "./data/busses/modals/add-car-modal/add-car-modal.component";
import { DeleteBusModalComponent } from "./data/busses/modals/delete-bus-modal/delete-bus-modal.component";
import { EditBusModalComponent } from "./data/busses/modals/edit-bus-modal/edit-bus-modal.component";
import { EditCarModalComponent } from "./data/busses/modals/edit-car-modal/edit-car-modal.component";
import { DriverEditComponent } from "./data/drivers/edit-driver/edit-driver.component";
import { AddNoteComponent } from "./data/maps/add-note/add-note.component";
import { LayoutModule } from "./layout/layout.module";
import { EditDictionaryColorComponent } from "./management/dictionary/edit-dictionary/edit-dictionary-color/edit-dictionary-color.component";
import { EditDictionaryIconComponent } from "./management/dictionary/edit-dictionary/edit-dictionary-icon/edit-dictionary-icon.component";
import { EditDictionarySimpleComponent } from "./management/dictionary/edit-dictionary/edit-dictionary-simple/edit-dictionary-simple.component";
import { PassangerDetailModalModule } from "./orders/common/components/passanger-detail-modal/passanger-detail-modal.module";
import { ConfirmOrderChangesModal } from "./orders/common/confirm-changes-modal/confirm-changes-modal.component";
import { PaymentModule } from "./payments/payment.module";
import { AddOrEditClientComponent } from "./relations/clients/modals/add-or-edit-client/add-or-edit-client.component";
import { ContractModalModule } from "./relations/clients/modals/contract-modal/contract-modal.module";
import { AddVendorModalComponent } from "./relations/vendors/modals/add-vendor-modal/add-vendor-modal.component";
import { DeleteVendorModalComponent } from "./relations/vendors/modals/delete-vendor-modal/delete-vendor-modal.component";
import { EditVendorModalComponent } from "./relations/vendors/modals/edit-vendor-modal/edit-vendor-modal.component";
import { StorageService } from "./services/storage.service";
import { EditDictionaryFinancialComponent } from "./management/dictionary/edit-dictionary/edit-dictionary-financial/edit-dictionary-financial.component";
// Modals
// Modals
import { CreateCustomFilterComponent } from "./shared/components/custom-filter/custom-filter-details/create/create-custom-filter.component";
import { EditCustomFilterComponent } from "./shared/components/custom-filter/custom-filter-details/edit/edit-custom-filter.component";
import { DateTimeInputrModule } from "./shared/components/date-time-input/date-time-input.module";
import { InputModule } from "./shared/components/input/input.module";
import { MapModule } from "./shared/components/map/map.module";
import { RadioButtonSelectModule } from "./shared/components/radio-button-select/radio-button-select.module";
import { SearchFieldModule } from "./shared/components/search-field/search-field.module";
import { SwitcherModule } from "./shared/components/switcher/switcher.module";
import { FormValidatorModule } from "./shared/derectives/form-validator/validator.module";
import { I18nModule } from "./shared/i18n/i18n.module";
import { DeleteTripTemplateModalComponent } from "./trip-templates/components/delete-trip-template-modal/delete-trip-template-modal.component";
import { TemplateApiService } from "./trip-templates/services/template-api.service";
import { DeleteOrderModalComponent } from "./orders/delete-order-modal/delete-order-modal.component";
import { SimilarContractsModalComponent } from "./orders/common/components/similar-contracts-modal/similar-contracts-modal.component";
import { EmailDetailsPopupComponent } from "./orders/email-details-popup/email-details-popup.component";
import { TextEditorModule } from "./shared/components/text-editor/text-editor.module";
import { EmailListManagementControlModule } from "./shared/components/email-list-management-control/email-list-management-control.module";
import { WarningDateModal } from "./orders/common/warning-date-modal/warning-date-modal.component";
import { EditDictionaryPriceComponent } from "./management/dictionary/edit-dictionary/edit-dictionary-price/edit-dictionary-price.component";
import { WarningNotificationInUse } from "./orders/common/warning-notification-in-use/warning-notification-in-use.component";
import { AuthCallbackPageComponent } from "./management/exact/auth-callback-page/auth-callback-page.component";
import { OfferConfirmationPageComponent } from "./offer-confirmation-page/offer-confirmation-page.component";
import { DismissVehicleModalComponent } from "./data/busses/modals/dismiss-bus-modal/dismiss-vehicle-modal.component";
import { SendEmailsModalComponent } from "./driver-hours/worked-hours/modals/send-emails-modal/send-emails-modal.component";
import { RowDriverDetailModalComponent } from "./driver-hours/driver-details/row-driver-detail-modal/row-driver-detail-modal.component";
import { DeleteDriverDetailModalComponent } from "./driver-hours/driver-details/delete-driver-detail-modal/delete-driver-detail-modal.component";
import { DriverDetailClosePageModalComponent } from "./driver-hours/driver-details/driver-detail-close-page-modal/driver-detail-close-page-modal.component";
import { AddDocumentModalComponent } from "./management/documents/add-document-modal/add-document-modal.component";
import { NewsModalComponent } from "./management/news/news-modal/news-modal.component";
import { NewsRemoveModalComponent } from "./management/news/news-remove-modal/news-remove-modal.component";
import { RemoveDocumentModalComponent } from "./management/documents/remove-document-modal/remove-document-modal.component";
import { ChangeBusArrangementConfirmationPopupComponent } from "./shared/components/change-bus-arrangement-confirmation-popup/change-bus-arrangement-confirmation-popup.component";
import { BalanceVacationComponent } from "./data/drivers/modals/balance-vacation/balance-vacation.component";
import { UploadBalanceVacationComponent } from "./data/drivers/modals/upload-balance-vacation/upload-balance-vacation.component";
import { AddDayOffRequestComponentModal } from "./driver-hours/days-off/modals/add-day-off-request-modal/add-day-off-request-modal.component";
import { ManagePeriodModalComponent } from "./management/ort/components/manage-period-modal/manage-period-modal.component";
import { CircleButtonModule } from "./shared/components/circle-button/circle-button.module";
import { UTC_OFFSET_SECONDS } from "./data/maps/constants";
import { AttachPdfModalComponent } from "./invoices/shared/modals/attach-pdf-modal/attach-pdf-modal.component";
import { OverlapBusAssignmentConfirmationComponent } from "./shared/components/overlap-bus-assignment-confirmation/overlap-bus-assignment-confirmation.component";
import { WarningDayOffComponent } from "./driver-hours/days-off/modals/warning-day-off/warning-day-off.component";
import { AssignWarningPopupComponent } from "./shared/components/assign-warning-popup/assign-warning-popup.component";
import { OverlapAssignmentConfirmationComponent } from "./shared/components/overlap-driver-assignment-confirmation/overlap-assignment-confirmation.component";
import { NetworkErrorInterceptor } from "./shared/interceptors/network-error.interceptor";
import { NetworkErrorModalComponent } from "./shared/components/network-error-modal/network-error-modal.component";
import { MannualReturnTripDialogComponent } from "./planning/components/mannual-return-trip-dialog/mannual-return-trip-dialog.component";
import { BlockingErrorModalComponent } from "./shared/components/blocking-error-modal/blocking-error-modal.component";
import { ExactTokenExpirationModalComponent } from "./shared/modals/exact-token-expiration-modal/exact-token-expiration-modal.component";
import { WarningTrailerPopupComponent } from "./shared/components/warning-trailer-popup/warning-trailer-popup.component";
import { InvoiceEmailModalComponent } from "./invoices/shared/modals/send-email-modal/send-email-modal.component";
import { WarningChangesModalComponent } from "./orders/common/warning-changes-modal/warning-changes-modal.component";
import { PassengersChangedModalComponent } from "./orders/common/passengers-changed-modal/passengers-changed-modal.component";
import { NotifyAttributesChangesModalComponent } from "./orders/common/notify-attributes-changes-modal/notify-attributes-changes-modal.component";
import { RequestNotificationModalComponent } from "./shared/components/request-notification-modal/request-notification-modal.component";
import { enGbLocale, nlLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import {
    NGX_TRANSLATE_LANGUAGE,
    NGX_TRANSLATE_PREFIX,
} from "./shared/services/datepicker-translation.service";
import { TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { ToFixedModule } from "./shared/derectives/to-fixed/to-fixed.module";
import { NgxMaskModule } from "ngx-mask";
import {
    ApolloLink,
    InMemoryCache,
    split,
    HttpLink,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { OperationDefinitionNode } from "graphql";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ClientOptions, createClient } from "graphql-ws";
import {
    ExportExactSpecificErrorModalComponent
} from "./driver-hours/worked-hours/modals/export-exact-specific-error-modal/export-exact-specific-error-modal.component";
registerLocaleData(localeNl);

defineLocale(`${NGX_TRANSLATE_PREFIX}${NGX_TRANSLATE_LANGUAGE.en}`, enGbLocale);
defineLocale(`${NGX_TRANSLATE_PREFIX}${NGX_TRANSLATE_LANGUAGE.nl}`, nlLocale);
// Tiny MCE Issue
function handlePromise(promiseList) {
    return promiseList.map((promise) =>
        promise.then(
            (res) => ({ status: "ok", res }),
            (err) => ({ status: "not ok", err }),
        ),
    );
}

window["Promise"]["allSettled"] = (promiseList) => {
    return Promise.all(handlePromise(promiseList));
};

// it's supports now
// Miserable angular doesn't support entry components with lazy loading
// so we have to add these components into the root module
const ENTRY_COMPONENTS = [
    // Custom filters component
    CreateCustomFilterComponent,
    EditCustomFilterComponent,
    // Orders
    NotifyAttributesChangesModalComponent,
    ConfirmOrderChangesModal,
    WarningDateModal,
    PassengersChangedModalComponent,
    WarningNotificationInUse,
    SimilarContractsModalComponent,
    EmailDetailsPopupComponent,
    WarningChangesModalComponent,

    // Drivers components
    DriverEditComponent,
    BalanceVacationComponent,
    UploadBalanceVacationComponent,

    // Busses components
    AddBusModalComponent,
    AddCarModalComponent,
    DeleteBusModalComponent,
    EditBusModalComponent,
    EditCarModalComponent,
    DismissVehicleModalComponent,

    // Clients components
    AddOrEditClientComponent,

    // Vendors components
    AddVendorModalComponent,
    EditVendorModalComponent,
    DeleteVendorModalComponent,

    // Dictionary data components
    EditDictionarySimpleComponent,
    EditDictionaryIconComponent,
    EditDictionaryColorComponent,
    EditDictionaryPriceComponent,
    EditDictionaryFinancialComponent,

    AddNoteComponent,
    // Chat
    // ChatComponent

    // Individual order
    // PassangerDetailModalComponent

    // Fulfillments
    // TripTemplate
    DeleteTripTemplateModalComponent,

    // Actions
    AddTaskModalComponent,
    DeleteOrderModalComponent,
    SendEmailsModalComponent,
    ExportExactSpecificErrorModalComponent,

    // TranslateMePopupComponent,

    // Driver hours
    RowDriverDetailModalComponent,
    DeleteDriverDetailModalComponent,
    DriverDetailClosePageModalComponent,
    AddDayOffRequestComponentModal,
    WarningDayOffComponent,

    // NewsComponent
    NewsModalComponent,
    NewsRemoveModalComponent,

    // Data documents component
    AddDocumentModalComponent,
    RemoveDocumentModalComponent,

    ChangeBusArrangementConfirmationPopupComponent,
    MannualReturnTripDialogComponent,
    WarningTrailerPopupComponent,
    OverlapBusAssignmentConfirmationComponent,
    OverlapAssignmentConfirmationComponent,
    BlockingErrorModalComponent,

    AssignWarningPopupComponent,
    RequestNotificationModalComponent,
    ManagePeriodModalComponent,

    // Invoices
    AttachPdfModalComponent,
    InvoiceEmailModalComponent,
    NetworkErrorModalComponent,

    // Shared
    ExactTokenExpirationModalComponent,
];

@NgModule({
    declarations: [
        AppComponent,
        AuthCallbackPageComponent,
        ENTRY_COMPONENTS,
        OfferConfirmationPageComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        AdditionalModule,
        ApolloModule,
        AppRoutingModule,
        AuthModule,
        BrowserModule,
        FormsModule,
        I18nModule,
        // ChatModule,
        LayoutModule,
        NgScrollbarModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SharedModule,
        NgbModule,
        MapModule,
        SearchFieldModule,
        PassangerDetailModalModule,
        DateTimeInputrModule,
        FormValidatorModule,
        InputModule,
        SwitcherModule,
        ToFixedModule,
        PaymentModule,
        ContractModalModule,
        RadioButtonSelectModule,
        TextEditorModule,
        EmailListManagementControlModule,
        DaysOffModule,
        NgxMaskModule.forRoot(),
        CircleButtonModule,
    ],
    providers: [
        DaysOffService,
        NetworkErrorInterceptor,
        TemplateApiService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkErrorInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    constructor(
        apollo: Apollo,
        private storage: StorageService,
        private router: Router,
    ) {
        const graphUrl = environment.apiGraphUrl;
        const wsGraphUrl = environment.wsGraphUrl;
        const authGraphUrl = environment.apiAuthGraphUrl;
        const wssChatGraphUrl = environment.wssChatGraphUrl;
        // const fragmentMatcher = new IntrospectionFragmentMatcher({introspectionQueryResultData});

        const httpErrorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path }) => {
                    throwError(message);
                    console.error(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    );
                });
            }

            if (networkError) {
                // console.error(`[Network error]: ${networkError.message}`);
            }
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
            const timezoneOffset = UTC_OFFSET_SECONDS;

            operation.setContext({
                headers: {
                    token: this.storage.getItem("token"),
                    timeOffset: timezoneOffset.toString(),
                },
            });
            return forward(operation);
        });

        const http = new HttpLink({ uri: graphUrl });

        const clientOptions: ClientOptions = {
            url: wsGraphUrl,
            shouldRetry: () => true,
            lazy: true,
            connectionParams: {
                authToken: this.storage.getItem("token") || null,
            },
        };
        const ws = new GraphQLWsLink(createClient(clientOptions));

        const link = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                const { kind } = definition;
                if (kind !== "OperationDefinition") {
                    return false;
                }
                const { operation } = definition as OperationDefinitionNode;
                return operation === "subscription";
            },
            ws,
            http,
        );

        const httpLinkWithErrorHandling = ApolloLink.from([
            authMiddleware,
            httpErrorLink,
            link,
            // http
        ]);

        const options: any = {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        };

        apollo.create({
            link: httpLinkWithErrorHandling,
            cache: new InMemoryCache(),
            // cache: new InMemoryCache({possibleTypes: introspectionQueryResultData}),
            defaultOptions: options,
        });

        const httpAuth = new HttpLink({ uri: authGraphUrl });
        apollo.create(
            {
                link: httpAuth,
                cache: new InMemoryCache(),
            },
            "auth",
        );

        if (window.addEventListener) {
            window.addEventListener(
                "WINDOW_POST_MESSAGE",
                (res) => console.log("resdlksj1", res),
                false,
            );
        }
    }
}
