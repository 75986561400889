import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AuthService } from "../authServices/auth.service";
import { StorageService } from "../../services/storage.service";
import { HelperService } from "../../services/helper.service";
import { Router } from "@angular/router";
// TODO: check that is right type
import { User } from "../authClasses/user";

@Component({
    selector: "ge-login",
    templateUrl: "./login.component.html",
    styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    entityErrorCode = "";
    entityErrorCodeDescription = "";
    serverErrors = "";

    constructor(
        private authService: AuthService,
        private storage: StorageService,
        private helper: HelperService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            login: [
                "",
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(30),
                ],
            ],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(30),
                ],
            ],
        });
    }

    submit = () => {
        const { login, password } = this.loginForm.value;

        this.authService.login(login, password).subscribe(
            (user: User) => {
                this.storage.setItem("token", user.token);
                this.storage.setItem("company", user.company);
                switch (user.company) {
                    case "Gebo":
                        this.router.navigate(["/dashboard"]);
                        break;
                    case "Sunweb":
                        this.router.navigate(["/busLocator/Sunweb"]);
                        break;
                }
            },
            (error) => {
                let codes: any;
                const regex = /\d{2}\s\d{2}/;
                const errorCode = error.message.match(regex);
                if (errorCode && errorCode[0]) {
                    codes = errorCode[0].split(" ");
                    this.entityErrorCode = `${codes[0]}.`;
                    this.entityErrorCodeDescription = `${codes[1]}`;
                    this.serverErrors = `serverErrors.`;
                }
            },
        );
    };

    getErrorDescription(): string {
        return `${this.serverErrors}${this.entityErrorCode}${this.entityErrorCodeDescription}`;
    }
}
