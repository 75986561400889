import { Component, OnInit } from "@angular/core";

@Component({
    selector: "ge-not-found",
    templateUrl: "./not-found.component.html",
    styleUrl: "./not-found.component.scss",
})
export class NotFoundComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
