import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { IChatMessage } from "../chat-main/chat.models";

@Component({
    selector: "ge-chat-messages",
    templateUrl: "./messages.component.html",
    styleUrl: "./messages.component.scss",
})
export class MessagesComponent implements OnInit {
    @Input()
    public message: IChatMessage;

    public isDriver: boolean;

    public avatar: string;

    public environmentHost: string = environment.host;

    public ngOnInit(): void {
        this.avatar = this.message.creator.displayName.match(/[A-Z]/g)
            ? this.message.creator.displayName.match(/[A-Z]/g).join("")
            : "OU";
        this.isDriver = !!this.message.creator.driver;
    }
}
