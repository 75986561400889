import { Component, OnInit } from "@angular/core";
import { ExactService } from "../exact.service";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ToasterNotificationService } from "../../../shared/components/toaster-notification/toaster-notification.service";
import { MainRouting } from "../../../main-routing.enum";

@Component({
    selector: "ge-auth-callback-page",
    templateUrl: "./auth-callback-page.component.html",
    styleUrl: "./auth-callback-page.component.scss",
})
export class AuthCallbackPageComponent implements OnInit {
    loading: boolean;

    constructor(
        private exactService: ExactService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterNotificationService: ToasterNotificationService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params.code) {
                this.loading = true;

                this.exactService
                    .refreshToken(decodeURI(params.code))
                    .pipe(
                        finalize(() => {
                            this.loading = false;
                            this.showMsg();

                            setTimeout(() => {
                                this.router.navigateByUrl(
                                    MainRouting.Dashboard,
                                );
                            }, 1000);
                        }),
                    )
                    .subscribe((res) => {});
            }
        });
    }

    showMsg() {
        this.toasterNotificationService.setToaster({
            toasterType: "success",
            toasterTitle: "Refresh exact token",
            toasterText: `Token was refreshed successfully`,
        });
    }
}
