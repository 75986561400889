import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateFn,
    CanMatchFn,
    UrlSegment,
    Route,
} from "@angular/router";
import { MaintenseService } from "./maintense.service";

export const maintenseCanActivate: CanActivateFn = (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
) => {
    return inject(MaintenseService).proceed();
};

export const maintenseCanMatch: CanMatchFn = (
    _route: Route,
    _segments: UrlSegment[],
) => {
    return inject(MaintenseService).proceed();
};
