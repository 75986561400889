import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from "@angular/core";

import { BusSeatType } from "../../../../contract-modal.constants";
import {
    IContractBusTypeSummary,
    IViabusPrice,
} from "../../../../contract-modal.models";
import {
    nonDeleteBusTypesContracts,
    ContractType,
} from "src/app/shared/models/contracts.models";
import { EventHelper } from "../../../../../../../../shared/helpers/event-helper";

export interface ICreateViabusRate {
    price: number;
    customBusType: number;
}

@Component({
    selector: "ge-viabus-details",
    templateUrl: "./viabus-details.component.html",
    styleUrl: "./viabus-details.component.scss",
})
export class ViabusDetailsComponent implements OnChanges {
    @Input()
    public readonly details!: IViabusPrice[];

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    @Output()
    public readonly ratesChange: EventEmitter<IViabusPrice[]> =
        new EventEmitter<IViabusPrice[]>();

    @Output()
    public readonly rateDelete: EventEmitter<number> =
        new EventEmitter<number>();

    @Output()
    public readonly createRate: EventEmitter<ICreateViabusRate> =
        new EventEmitter<ICreateViabusRate>();

    @ViewChildren("selectElement")
    public selectElementList!: QueryList<ElementRef<HTMLSelectElement>>;

    public rateSeatTypes!: Record<string, IViabusPrice[]> | null;

    public availableBusTypesBySeat!: Record<
        string,
        IContractBusTypeSummary[]
    > | null;

    public newRatePrice!: Record<string, number>;

    public newRateBusType!: Record<string, number>;

    public allExistBusTypes: BusSeatType[] = [];

    constructor(private zone: NgZone) {}

    public get isDeleteAvailable(): boolean {
        return nonDeleteBusTypesContracts.indexOf(ContractType.Viabus) < 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { details, customBusTypes } = changes;

        if (
            (details || customBusTypes) &&
            this.details &&
            this.customBusTypes
        ) {
            this.availableBusTypesBySeat = null;
            this.rateSeatTypes = null;
            this.newRateBusType = {};
            this.newRatePrice = {};

            (this.details || []).forEach((item: IViabusPrice) => {
                const seatName = item.customBusType.seatingType.name;

                if (this.rateSeatTypes && this.rateSeatTypes[seatName]) {
                    this.rateSeatTypes[seatName].push(item);
                } else {
                    this.rateSeatTypes = {
                        ...this.rateSeatTypes,
                        [seatName]: [item],
                    };
                }
            });

            const busTypes = this.details
                ? this.details.map(
                      (item: IViabusPrice) => item.customBusType.id,
                  )
                : [];
            this.customBusTypes
                .filter(
                    (item: IContractBusTypeSummary) =>
                        !busTypes.some((id: null) => id === item.id),
                )
                .forEach((item: IContractBusTypeSummary) => {
                    const seatName = item.seatingType.name;

                    if (
                        this.availableBusTypesBySeat &&
                        this.availableBusTypesBySeat[seatName]
                    ) {
                        this.availableBusTypesBySeat[seatName].push(item);
                    } else {
                        this.availableBusTypesBySeat = {
                            ...this.availableBusTypesBySeat,
                            [seatName]: [item],
                        };
                    }
                });

            this.allExistBusTypes = Array.from(
                new Set([
                    ...Object.keys(this.availableBusTypesBySeat || {}),
                    ...Object.keys(this.rateSeatTypes || {}),
                ]),
            ) as BusSeatType[];

            this.zone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.selectElementList.forEach(
                        (item: ElementRef<HTMLSelectElement>) => {
                            item.nativeElement.value = "";
                        },
                    );
                });
            });
        }
    }

    public priceChange(event: Event, price: IViabusPrice): void {
        const { value } = event.target as HTMLInputElement;

        this.ratesChange.emit(
            this.details.map((item: IViabusPrice) =>
                item.id !== price.id
                    ? item
                    : {
                          ...item,
                          price: parseFloat(value),
                      },
            ),
        );
    }

    public onCreateRate(seat: BusSeatType): void {
        const [customBusType, price] = [
            this.newRateBusType[seat],
            this.newRatePrice[seat],
        ];

        this.createRate.emit({ customBusType: +customBusType, price });
    }

    protected readonly EventHelper = EventHelper;
}
