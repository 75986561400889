import { Directive, HostListener, Self } from "@angular/core";
import { NgControl } from "@angular/forms";
import { DestructibleController } from "src/app/common/classes/destructible.controller";

@Directive({
    selector: "[geToFixed]",
})
export class ToFixedDirective extends DestructibleController {
    constructor(@Self() private ngControl: NgControl) {
        super();
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(e) {
        const value = String(this.ngControl.value);
        if (value.split(".").length === 2) {
            const [number, decimal] = value.split(".");
            const isNumberEntered = !isNaN(e.key);
            if (isNumberEntered && decimal.length === 2) {
                e.preventDefault();
            }
        }
    }
}
