import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from "@angular/core";
import moment from "moment";
import { merge, Subject } from "rxjs";
import {
    concatMap,
    debounceTime,
    finalize,
    map,
    pluck,
    takeUntil,
    tap,
} from "rxjs/operators";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import { DestructibleController } from "../../common/classes/destructible.controller";
import { StorageService } from "../../services/storage.service";
import { ScrollViewComponent } from "../../shared/components/scroll-view/scroll-view/scroll-view.component";
import { ToasterNotificationService } from "../../shared/components/toaster-notification/toaster-notification.service";
import { ChatApiService } from "../services/chat-api.service";
import { ChatGetMessageService } from "../services/chat.graphql.service";
import { IChatMessage, IDriver, IUser } from "./chat.models";

// const tokenForWss = 'f369f88d-b02f-4194-a4a9-b3cbd9311b6c';

@Component({
    selector: "ge-fab-chatButton",
    templateUrl: "./chat.component.html",
    styleUrl: "./chat.component.scss",
})
export class ChatComponent extends DestructibleController implements OnInit {
    @ViewChild("scrollViewRef", {
        static: true,
    })
    public scroll: ScrollViewComponent;

    public loading = false;
    public isLoaded = false;

    public paginate$: Subject<{
        page: number;
        driverId: string;
    }> = new Subject<{ page: number; driverId: string }>();

    public searchText = "";

    public drivers: IDriver[] = [];

    public messages: IChatMessage[] = [];

    public currentUser: IUser;

    public currentDriverId: string;

    public message = "";

    public chatCurrentPage = 0;

    public isAllSelected = false;

    public selectedDriverIds: string[] = [];

    constructor(
        private chatService: ChatApiService,
        private elementRef: ElementRef,
        private modalWindowService: ModalWindowService,
        private chatGetMessageService: ChatGetMessageService,
        private notificationService: ToasterNotificationService,
        private storage: StorageService,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();

        // this.chatGetMessageService.subscribe({
        //     token: this.storage.getItem('token')
        // });
    }

    public ngOnInit(): void {
        this.loadDrivers();
        // this.initSubscription();

        this.currentUser = this.chatService.getAdminFromDb();
    }

    public loadDrivers(): void {
        this.loading = true;

        this.chatService
            .getDriversFromDb()
            .pipe(
                map((drivers: IDriver[]) => {
                    return drivers.filter((driver) => !driver.is3rdPartyDriver);
                }),
                finalize(() => (this.loading = false)),
                takeUntil(this.destroy$),
            )
            .subscribe((drivers: IDriver[]) => {
                this.drivers = drivers;
                this.isLoaded = true;
                this.selectedDriverIds = [];
            });
    }

    public onSelectDriver(driver: IDriver): void {
        this.setCurrentDriver(driver);
        this.focusOnInput();

        this.isAllSelected = false;
    }

    public onCheck(event: Event, driver: IDriver): void {
        const { checked } = event.target as HTMLInputElement;

        if (
            this.selectedDriverIds.length === 1 &&
            !checked &&
            this.currentDriverId === driver.chatId
        ) {
            this.currentDriverId = "";
        } else if (!this.selectedDriverIds.length && checked) {
            this.onSelectDriver(driver);

            return;
        }

        if (checked) {
            this.selectedDriverIds = [...this.selectedDriverIds, driver.chatId];
        } else if (this.selectedDriverIds.length) {
            const index = this.selectedDriverIds.indexOf(driver.chatId);

            this.selectedDriverIds.splice(index, 1);
            this.selectedDriverIds = [...this.selectedDriverIds];
        }
    }

    // public searchFilter(driver: IDriver): boolean {
    //     return (driver.firstName + ' ' + driver.lastName)
    //         .toLowerCase()
    //         .indexOf(this.searchText.toLowerCase()) !== -1
    //         ;
    // }

    public timeCombine(messages: IChatMessage[]): void {
        const today = moment().format("dd, DD-MM-YYYY");
        console.table(this.messages);
        this.messages = this.messages.sort(
            (a, b) => +moment(b.createDate).isBefore(a.createDate) - 1,
        );
        console.table(this.messages);

        messages.forEach((item: IChatMessage, index: number) => {
            if (index === messages.length - 1) {
                return;
            }
            const nextIndex = index + 1;
            const nextMessage = messages[nextIndex];

            if (
                !nextMessage ||
                item.formatedDate === nextMessage.formatedDate
            ) {
                return;
            }

            nextMessage.groupDate =
                item.formatedDate !== today
                    ? nextMessage.formatedDate
                    : "Today";
        });
    }

    public concatinationOldNewMessages(
        oldMess: IChatMessage[],
        newMess: IChatMessage[],
    ): IChatMessage[] {
        const oldFirst = oldMess && oldMess[0];
        const newFirst = newMess && newMess[0];

        if (this.chatCurrentPage !== 1 && !newFirst) {
            return oldMess;
        }

        this.takeScrollTo();

        return oldFirst && newFirst && newFirst.chatId === oldFirst.chatId
            ? newMess.concat(oldMess)
            : newMess;
    }

    public dateFormatting(messages: IChatMessage[]): void {
        messages.forEach((message: IChatMessage) => {
            const dateCreated = moment.utc(message.createDate).toDate();
            const dateToRender = moment(dateCreated).format("HH:mm");
            const groupDate = moment(dateCreated).format("dd, DD-MM-YYYY");

            message.dateToRender = dateToRender;
            message.formatedDate = groupDate;
        });
    }

    public initSubscription(): void {
        const pagination$ = this.paginate$.pipe(
            debounceTime(200),
            concatMap(
                ({ page, driverId }: { page: number; driverId: string }) => {
                    this.chatCurrentPage++;
                    this.loading = true;

                    return this.chatService.getMessagesForCurrentChat(
                        page,
                        driverId,
                    );
                },
            ),
            tap((messages: IChatMessage[]) => {
                this.dateFormatting(messages);
                this.focusOnInput();
            }),
            tap((messages: IChatMessage[]) => {
                this.messages = [...this.messages, ...messages.reverse()];
                this.timeCombine(this.messages);
            }),
            takeUntil(this.destroy$),
        );

        const newMessages$ = this.chatGetMessageService
            .subscribe({
                token: this.storage.getItem("token"),
            })
            .pipe(
                pluck("data", "messageAdded"),
                map((message: IChatMessage) => [message]),
                tap((messages: IChatMessage[]) => {
                    this.dateFormatting(messages);
                    this.loading = false;
                    this.chatService.newMessage$.next(true);
                }),
                tap((messages: IChatMessage[]) => {
                    if (
                        !messages.some((message) =>
                            this.selectedDriverIds.some(
                                (id) => message.chatId === id,
                            ),
                        )
                    ) {
                        return;
                    }

                    this.messages = [...this.messages, ...messages];
                    this.timeCombine(this.messages);
                }),
                tap(() => this.scrollToBottom()),
            );

        const messagesPipeline$ = merge(pagination$, newMessages$);

        messagesPipeline$.subscribe(
            () => {
                this.loading = false;
                this.changeDetectorRef.detectChanges();
            },
            () => {
                this.loading = false;

                this.notificationService.errorToaster("errors.commonError");

                if (this.chatCurrentPage === 1) {
                    this.setCurrentDriver(null);
                }

                this.initSubscription();

                return;
            },
        );
    }

    public setCurrentDriver(driver: IDriver | null): void {
        if (driver === null) {
            this.messages = [];
            this.selectedDriverIds = [];
            this.currentDriverId = "";
            this.chatCurrentPage = 0;

            return;
        }

        if (this.currentDriverId !== driver.chatId) {
            this.chatCurrentPage = 0;
            this.currentDriverId = driver.chatId;
            this.selectedDriverIds = [this.currentDriverId];

            this.paginate();
            this.scrollToBottom();

            this.chatService
                .messageIsRed(driver.chatId)
                .pipe(takeUntil(this.destroy$))
                .subscribe();
        }

        this.messages = [];

        driver.unreadMessages = 0;
    }

    public onMessage(): void {
        if (!this.message.trim()) {
            return;
        }

        this.loading = true;

        this.chatService
            .sendMessage(this.message, this.selectedDriverIds)
            .pipe(
                finalize(() => (this.loading = false)),
                tap((message: IChatMessage) => {
                    if (this.selectedDriverIds.length > 1) {
                        this.chatCurrentPage = 0;
                        this.toggleDriversSelection(false);

                        return;
                    }

                    // this.messages.push(message);
                    this.dateFormatting(this.messages);
                    this.timeCombine(this.messages);

                    this.messages = [...this.messages];
                }),
                takeUntil(this.destroy$),
            )
            .subscribe({
                error: () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            });

        this.scrollToBottom();
        this.focusOnInput();

        this.message = "";
    }

    public scrollToBottom(): void {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => this.scroll.scrollToBottom());
        });
    }

    public focusOnInput(): void {
        const input =
            this.elementRef.nativeElement.querySelector(".msgTextArea");

        if (input) {
            input.focus();
        }
    }

    public toggleDriversSelection(forceValue?: boolean): void {
        this.isAllSelected =
            typeof forceValue === "boolean" ? forceValue : !this.isAllSelected;

        this.selectedDriverIds = this.isAllSelected
            ? this.drivers.map((driver: IDriver) => driver.chatId)
            : [];
    }

    public takeScrollTo(): void {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.scroll.scrollbarRef.scrollToElement(".chat-message-9");
            }, 200);
        });
    }

    public paginate(): void {
        this.paginate$.next({
            page: this.chatCurrentPage,
            driverId: this.currentDriverId,
        });
    }
    get isSearchResult(): boolean {
        const result = this.drivers.filter((driver) =>
            (driver.firstName + driver.lastName)
                .toLowerCase()
                .includes(this.searchText),
        );
        return !result.length;
    }
}
