/* src/app/relations/clients/modals/contract-modal/components/period-list/period-list.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.period-list {
  margin-bottom: -15px;
  display: flex;
  flex-wrap: wrap;
}
.period-item {
  display: flex;
  align-items: center;
  transition: all 240ms ease;
  cursor: pointer;
  margin: 0 15px 15px 0;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid rgba(219, 223, 232, 0.5);
}
.period-item:hover,
.period-item.selected {
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0.05);
}
ge-button {
  margin-bottom: 15px;
}
/*# sourceMappingURL=period-list.component.css.map */
