/* src/app/relations/clients/modals/contract-modal/components/contract-list/contract-item/contract-item.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.page-container {
  padding: 24px;
}
.ibox-content {
  border: none;
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main-content {
  flex-grow: 1;
}
.scroll,
.page-content-height-xl,
.page-content-height-lg,
.page-content-height {
  overflow-y: auto;
}
.page-content-height {
  height: calc(100vh - 260px);
}
.page-content-height-lg {
  height: calc(100vh - 240px);
}
.page-content-height-xl {
  height: calc(100vh - 260px);
}
.pac-container {
  z-index: 2201;
}
.route .form-group {
  display: grid;
  grid-template-columns: 200px 585px 50px;
  align-items: flex-start;
}
.route .form-group__label {
  padding-top: 10px;
}
.route .form-group__value {
  display: flex;
  padding-top: 10px;
}
.multiple-controls {
  display: flex;
  align-items: flex-start;
}
.multiple-controls ge-place-autocomplete {
  flex-grow: 1;
}
ge-loader {
  z-index: 999;
  height: 100%;
  width: 100%;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.p-0 {
  padding: 0px;
}
.p-3 {
  padding: 3px;
}
.p-5 {
  padding: 5px;
}
.p-9 {
  padding: 9px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-3 {
  padding-bottom: 3px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-3 {
  padding-left: 3px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.m-0 {
  margin: 0px;
}
.m-3 {
  margin: 3px;
}
.m-5 {
  margin: 5px;
}
.m-7 {
  margin: 7px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-11 {
  margin-right: 11px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-0 {
  margin-left: 0px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.order-part {
  background: #fff;
  border-radius: 4px;
  border: solid 1px #f3f4f7;
  padding: 10px;
}
.passengers {
  display: grid;
  row-gap: 17px;
}
.passengers .error {
  margin-top: 10px;
  margin-left: 200px;
}
.two-columns {
  display: grid;
  grid-template-columns: 200px 585px;
}
.two-columns.second-stretch {
  grid-template-columns: 200px 1fr;
}
.two-columns.all-stretch {
  grid-template-columns: 1fr 1fr;
}
.three-columns {
  grid-template-columns: 200px 1fr 1fr;
}
input[disabled] {
  background: #f7f7f7;
}
.contacts table tbody {
  font-weight: 16px;
}
ge-radio-button-group {
  margin: 0;
}
.order-detail {
  height: calc(100vh - 200px);
  padding: 10px;
  padding-bottom: 0px;
  background: #f3f4f7;
  display: grid;
  position: relative;
  grid-template-columns: 1220px;
}
.order-detail form,
.order-detail .form {
  grid-template-columns: 1200px;
  width: 1300px;
}
.order-detail form hr,
.order-detail .form hr {
  width: 1200px;
}
:host-context(.menu-collapsed) .order-detail {
  grid-template-columns: 1360px;
}
:host-context(.menu-collapsed) .order-detail form,
:host-context(.menu-collapsed) .order-detail .form {
  grid-template-columns: 1315px;
  width: 1335px;
}
:host-context(.menu-collapsed) .order-detail form hr,
:host-context(.menu-collapsed) .order-detail .form hr {
  width: 1315px;
}
.seatings {
  display: grid;
  grid-template-columns: 200px 585px;
}
ge-radio-button-group.order-input {
  display: flex;
}
ge-radio-button-group.order-input ge-radio-button {
  display: block;
  margin-right: 40px;
}
form.order-form {
  background: #f3f4f7;
  padding: 0px;
  display: grid;
  grid-row-gap: 10px;
}
div.resend-offer {
  display: flex;
}
div.resend-offer ge-select {
  display: block;
  width: 250px;
  margin-right: 30px;
}
.download-offer__actions {
  display: grid;
  gap: 10px;
}
.download-offer__actions ge-button {
  display: block;
}
.download-offer__actions .resend-offer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.download-offer__actions .resend-offer ge-select {
  margin-right: 0;
  width: auto;
}
.download-offer__actions .resend-offer .dropdown-offer::ng-deep .d-inline-block,
.download-offer__actions .resend-offer .dropdown-offer::ng-deep ge-button {
  display: block !important;
}
.download-offer__actions .dropdown-offer__action-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.contract-item {
  padding: 15px 20px 15px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(219, 223, 232, 0.5);
  transition: background 280ms ease;
  position: relative;
}
.contract-item:not(.edit-mode):hover,
.contract-item:not(.edit-mode).selected {
  background: rgba(0, 0, 0, 0.05);
}
.contract-item:not(.edit-mode):hover .submenu {
  opacity: 1;
}
.contract-item.edit-mode .contact-name {
  font-weight: 600;
}
.contract-item .contact-name {
  padding: 3px 10px;
  border-radius: 12px;
  transition: background 180ms ease;
}
.contract-item .contact-name:hover {
  background: rgba(0, 0, 0, 0.05);
}
.contract-item.disabled {
  cursor: auto;
  background: #ffffff !important;
}
.contract-item.disabled .contact-name {
  opacity: 0.7;
}
.contract-item.active:after {
  content: "";
  height: 100%;
  width: 2px;
  background: #1ac084;
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.contract-item .submenu-container {
  right: 5px;
  top: 5px;
  position: absolute;
}
.contract-item .submenu-dropdown {
  z-index: 1;
  position: relative;
}
.contract-item .submenu {
  opacity: 0;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 240ms ease;
}
.contract-item .submenu:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dropdown-container {
  background: #ffffff;
}
.indent-input {
  margin: 10px 0 10px 5px;
}
/*# sourceMappingURL=contract-item.component.css.map */
