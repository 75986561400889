/* src/app/relations/clients/modals/contract-modal/components/period-list/period-input/period-input.component.scss */
.range-datepicker {
  display: inline-block;
  width: 152px;
}
.range-datepicker label {
  margin-bottom: 0;
}
.date-input-span {
  line-height: 40px;
}
/*# sourceMappingURL=period-input.component.css.map */
