/* src/app/layout/layout.component.scss */
.page-layout-container {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: 220px auto;
  height: 100vh;
}
.without-menu {
  display: block;
}
.header {
  grid-row: 1;
  grid-column: 1/span 2;
}
.menu {
  grid-row: 2;
  grid-column: 1;
}
.content {
  padding: 0;
  height: 100vh;
}
.menu-collapsed {
  grid-template-columns: 80px auto;
}
/*# sourceMappingURL=layout.component.css.map */
