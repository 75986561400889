import gql from "graphql-tag";

export const LOGIN_FRAGMENT = gql`
    fragment loginFragment on LoginData {
        user {
            displayName
            token
            company
        }
    }
`;
