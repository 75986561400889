<div>
    <div class="data-input-block">
        <span data-translate-me [translateMe]="'relations.contracts.startDate'">
            {{ "relations.contracts.startDate" | i18n }}
        </span>
        <ge-datepicker
            class="data-input"
            [value]="startDatepicker"
            (dateChange)="startDatepicker = $event"
        ></ge-datepicker>
    </div>
    <div class="margin-top-15 data-input-block">
        <span data-translate-me [translateMe]="'relations.contracts.endDate'">
            {{ "relations.contracts.endDate" | i18n }}
        </span>

        <ge-datepicker
            class="data-input"
            [value]="endDatepicker"
            [minDate]="startDatepicker"
            (dateChange)="endDatepicker = $event"
        ></ge-datepicker>
    </div>
    <div class="d-flex margin-top-15">
        <ge-button
            class="flex-grow-1 margin-right-5"
            [click]="onReset"
            data-translate-me
            [translateMe]="'relations.contracts.cancel'"
        >
            {{ "relations.contracts.cancel" | i18n }}
        </ge-button>
        <ge-button
            class="flex-grow-1 margin-left-5"
            theme="cranberry"
            [disabled]="!startDatepicker || !endDatepicker"
            [click]="onContractCreate"
        >
            {{ okLabel }}
        </ge-button>
    </div>
</div>
