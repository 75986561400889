import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { StorageService } from "../../services/storage.service";
import { Observable } from "rxjs";
import { LOGIN_FRAGMENT } from "../fragments";
import { pluck } from "rxjs/operators";
import { Router, RouterStateSnapshot } from "@angular/router";

const TOKEN_NAME = "token";
const COMPANY_NAME = "company";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(
        private apollo: Apollo,
        private storage: StorageService,
        private router: Router,
    ) {}

    public validateAuthentication(url: string) {
        if (!this.isAuthenticated) {
            this.router.navigate(["login"]).then(() => {
                // do nothing
            });
            return false;
        }
        if (this.userCompanyIsSunweb && url.includes("/busLocator/Sunweb")) {
            this.router.navigate(["/busLocator"]).then(() => {
                // do nothing
            });
            return false;
        }
        return true;
    }

    login(user: string, password: string): Observable<any> {
        const application = "Backoffice";

        const mutation = gql`
            ${LOGIN_FRAGMENT}

            mutation Auth(
                $user: String
                $password: String
                $application: String
            ) {
                login(
                    user: $user
                    password: $password
                    application: $application
                ) {
                    ...loginFragment
                }
            }
        `;

        const variables: any = {
            user,
            password,
            application,
        };

        return this.apollo
            .use("auth")
            .mutate({ mutation, variables })
            .pipe(pluck("data", "login", "user"));
    }

    logout(token?: string): void {
        this.storage.cleanStorage();
    }

    /**
     * Checks whether use is authorized
     */
    get isAuthenticated(): boolean {
        return !!this.storage.getItem(TOKEN_NAME);
    }

    get userCompanyIsSunweb(): boolean {
        return this.storage.getItem(COMPANY_NAME) === "Sunweb";
    }
}
