import { Component, OnInit } from "@angular/core";

@Component({
    templateUrl: "./maintense.component.html",
    styleUrl: "./maintense.component.scss",
})
export class MaintenseComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
