import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "../modal-window/modal-options.interface";
import { ModalWindowService } from "../modal-window/modal-window.service";

@Component({
    selector: "ge-overlap-bus-assignment-confirmation",
    templateUrl: "./overlap-bus-assignment-confirmation.component.html",
    styleUrl: "./overlap-bus-assignment-confirmation.component.scss",
})
export class OverlapBusAssignmentConfirmationComponent implements OnInit {
    modalWindowOptions: IModalWindowOptions = {
        title: "overlapBusAssignmentPopup.title",
        isNotCancellable: true,
        buttons: [
            {
                label: "changeBusArrangementConfirmationPopup.okBtn",
                click: this.close.bind(this),
            },
        ],
    };
    data: any = {};
    constructor(private modalWindowService: ModalWindowService) {}

    ngOnInit() {}
    close() {
        this.modalWindowService.close();
    }
}
