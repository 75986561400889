/* src/app/relations/clients/modals/contract-modal/components/contract-types/grovenbeek-contract/grovenbeek-contract.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
:host {
  width: 100%;
}
.rate-info {
  margin-left: 30px;
  margin-bottom: 0;
  font-size: 16px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.rate-info .info-title {
  font-weight: 600;
  font-size: 14px;
}
.rate-info .info-value {
  display: block;
  width: 120px;
  height: 40px;
  line-height: 40px;
}
.rate-info input {
  width: 120px;
}
.rate-info > *:not(.d-flex) {
  display: flex;
  flex-direction: column;
}
.rate-info:hover .remove-btn {
  opacity: 1;
}
.remove-btn {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: 18px;
  opacity: 0;
  transition: all 180ms ease;
}
.remove-btn:hover {
  background: rgba(0, 0, 0, 0.05);
}
.block-hidden {
  opacity: 0;
  pointer-events: none;
}
.add-btn {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32946e;
  border: 1px solid rgba(219, 223, 232, 0.5);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04);
  background: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.add-btn.disabled {
  pointer-events: none;
  cursor: auto;
  opacity: 0.35;
}
/*# sourceMappingURL=grovenbeek-contract.component.css.map */
