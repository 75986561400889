/* src/app/layout/navigation/navigation.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
li.nav-item {
  cursor: pointer;
  border-left: 2px solid transparent;
  padding: 18px 25px;
  margin: 0;
  font-weight: 600;
  display: block;
}
li.nav-item.active > span {
  font-weight: bold;
  color: #0b162d;
  word-break: break-word;
}
li.nav-item > img {
  width: 24px;
}
li.nav-item:hover,
li.nav-item.active {
  background-color: #f3f4f7;
  border-left: 2px solid #98002c;
}
span {
  margin-left: 25px;
  font-size: 16px;
  color: #0b162d;
  word-break: break-word;
}
:host ::ng-deep ge-svg svg {
  fill: #c6c8cd !important;
}
:host ::ng-deep .active ge-svg svg {
  fill: #98002c !important;
}
.menu-toggle-icons {
  position: absolute;
  bottom: 20px;
  left: 26px;
}
.menu-toogle-language {
  position: absolute;
  bottom: 62px;
  left: 26px;
}
/*# sourceMappingURL=navigation.component.css.map */
