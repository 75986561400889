/* src/app/offer-confirmation-page/offer-confirmation-page.component.scss */
.offer-confirmation-page {
  background: #f3f4f7;
  padding: 45px 120px;
  height: 100%;
}
.offer-confirmation-page .request-message {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.offer-confirmation-page .request-message .text-msg {
  background: #fff;
  padding: 50px;
  border: none;
  display: inline-block;
  border-radius: 8px;
}
.offer-confirmation-page .request-message h1 {
  font-weight: bold;
  font-size: 40px;
}
.offer-confirmation-page .request-message p {
  font-size: 24px;
}
/*# sourceMappingURL=offer-confirmation-page.component.css.map */
