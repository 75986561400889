import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { FullYearCalendarModule } from "../../../../common/components/full-year-calendar/full-year-calendar.module";
import { SharedModule } from "../../../../shared/shared.module";
import { ContractItemComponent } from "./components/contract-list/contract-item/contract-item.component";
import { ContractListComponent } from "./components/contract-list/contract-list.component";
import { ContratRangeInputsComponent } from "./components/contract-list/contrat-range-inputs/contrat-range-inputs.component";
import { RateTableComponent } from "./components/contract-types/drenth-van-gogh-contract/drenth-table/rate-table.component";
import { DrenthVanGoghContractComponent } from "./components/contract-types/drenth-van-gogh-contract/drenth-van-gogh-contract.component";
import { GrovenbeekContractComponent } from "./components/contract-types/grovenbeek-contract/grovenbeek-contract.component";
import { SimpleContractComponent } from "./components/contract-types/simple-contract/simple-contract.component";
import { SunwebContractComponent } from "./components/contract-types/sunweb-contract/sunweb-contract.component";
import { SunwebTableComponent } from "./components/contract-types/sunweb-contract/sunweb-table/sunweb-table.component";
import { ViabusContractComponent } from "./components/contract-types/viabus-contract/viabus-contract.component";
import { ViabusDetailsComponent } from "./components/contract-types/viabus-contract/viabus-details/viabus-details.component";
import { EditorFieldComponent } from "./components/editor-field/editor-field.component";
import { NewBusTypeComponent } from "./components/new-bus-type/new-bus-type.component";
import { PeriodInputComponent } from "./components/period-list/period-input/period-input.component";
import { PeriodListComponent } from "./components/period-list/period-list.component";
import { ContractModalComponent } from "./contract-modal.component";
import { PipesModule } from "src/app/shared/pipes/pipes.module";
import { InputModule } from "../../../../shared/components/input/input.module";
import { NgClickOutsideDirective } from "ng-click-outside2";

@NgModule({
    declarations: [
        ContractListComponent,
        DrenthVanGoghContractComponent,
        GrovenbeekContractComponent,
        PeriodInputComponent,
        PeriodListComponent,
        RateTableComponent,
        SunwebContractComponent,
        SunwebTableComponent,
        ViabusContractComponent,
        ViabusDetailsComponent,
        EditorFieldComponent,
        ContractModalComponent,
        ContractItemComponent,
        NewBusTypeComponent,
        SimpleContractComponent,
        ContratRangeInputsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        FullYearCalendarModule,
        InputModule,
        NgClickOutsideDirective,
        PipesModule,
        SharedModule,
    ],
})
export class ContractModalModule {}
