<div class="offer-confirmation-page">
    <div class="logo">
        <img alt="Gebotours" src="assets/imgs/logo.png" />
    </div>

    <div class="request-message">
        <div class="text-msg" *ngIf="isVisibleSuccessMsg">
            <h1>{{ "confirmOfferPage.successMsgHeader" | i18n }}</h1>
            <p>{{ "confirmOfferPage.successMsgText" | i18n }}</p>
        </div>

        <div class="text-msg" *ngIf="isVisibleErrorMsg">
            <h1>{{ "confirmOfferPage.errorMsgHeader" | i18n }}</h1>
            <p>{{ "confirmOfferPage.errorMsgText" | i18n }}</p>
        </div>

        <div class="text-msg" *ngIf="isVisibleNotNeededMsg">
            <h1>{{ "confirmOfferPage.notNeededMsgHeader" | i18n }}</h1>
            <p>{{ "confirmOfferPage.notNeededMsgText" | i18n }}</p>
        </div>
    </div>

    <ge-spinner-loader *ngIf="loading"></ge-spinner-loader>
</div>
