<div class="table-vertical-middle">
    <table class="table">
        <thead>
            <tr class="header-row">
                <th
                    data-translate-me
                    [translateMe]="'relations.contracts.busType'"
                    data-translate-me
                    [translateMe]="'relations.contracts.busType'"
                >
                    {{ "relations.contracts.busType" | i18n }}
                </th>
                <th
                    *ngIf="VincentVanGoghType === type"
                    data-translate-me
                    [translateMe]="'relations.contracts.waitingTimeHourRate'"
                    data-translate-me
                    [translateMe]="'relations.contracts.waitingTimeHourRate'"
                >
                    {{ "relations.contracts.waitingTimeHourRate" | i18n }}
                </th>
                <th
                    data-translate-me
                    [translateMe]="'relations.contracts.minRate'"
                    data-translate-me
                    [translateMe]="'relations.contracts.minRate'"
                >
                    {{ "relations.contracts.minRate" | i18n }}
                </th>
                <th
                    data-translate-me
                    [translateMe]="'relations.contracts.priceHour'"
                    data-translate-me
                    [translateMe]="'relations.contracts.priceHour'"
                >
                    {{ "relations.contracts.priceHour" | i18n }}
                </th>
                <th
                    data-translate-me
                    [translateMe]="'relations.contracts.priceKm'"
                    data-translate-me
                    [translateMe]="'relations.contracts.priceKm'"
                >
                    {{ "relations.contracts.priceKm" | i18n }}
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let row of tableRows; let index = index"
                class="table-row ge-form-group"
            >
                <td>
                    <div>{{ row.customBusType.name }}</div>
                </td>
                <td *ngIf="VincentVanGoghType === type">
                    <ge-editor-field autoOpen #waitingTimeHourRate>
                        <div class="price-label" editorLabel>
                            € {{ row.waitingTimeHourRate | gePrice }}
                        </div>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="row.waitingTimeHourRate"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                waitingTimeHourRate.hideInput();
                                updateField(
                                    $event,
                                    index,
                                    'waitingTimeHourRate'
                                )
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </td>
                <td>
                    <ge-editor-field autoOpen #minimumOrderRate>
                        <div class="price-label" editorLabel>
                            € {{ row.minimumOrderRate | gePrice }}
                        </div>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="row.minimumOrderRate"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                minimumOrderRate.hideInput();
                                updateField($event, index, 'minimumOrderRate')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </td>
                <td>
                    <ge-editor-field autoOpen #hourRate>
                        <div class="price-label" editorLabel>
                            € {{ row.hourRate | gePrice }}
                        </div>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="row.hourRate"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                hourRate.hideInput();
                                updateField($event, index, 'hourRate')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </td>
                <td>
                    <ge-editor-field autoOpen #kilometerRate>
                        <div class="price-label" editorLabel>
                            € {{ row.kilometerRate | gePrice }}
                        </div>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="row.kilometerRate"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                kilometerRate.hideInput();
                                updateField($event, index, 'kilometerRate')
                            "
                            editorInput
                        />
                    </ge-editor-field>
                </td>
                <td>
                    <span
                        *ngIf="isDeleteAvailable"
                        class="remove-btn"
                        (click)="deleteRate.emit(row.id)"
                    >
                        <ge-svg icon="trash"></ge-svg>
                    </span>
                </td>
            </tr>
            <tr
                *ngIf="availableBusTypes && availableBusTypes.length"
                class="ge-form-group no-bottom-margin"
            >
                <td>
                    <select
                        class="ge-form-control"
                        [(ngModel)]="newCustomBusType"
                        #busTypeSelect
                    >
                        <option
                            *ngFor="let item of availableBusTypes"
                            [value]="item.id"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </td>
                <td *ngIf="VincentVanGoghType === type">
                    <input
                        class="ge-form-control no-arrow-btns"
                        type="number"
                        [(ngModel)]="waitingTimePrice"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </td>
                <td>
                    <input
                        class="ge-form-control no-arrow-btns"
                        type="number"
                        [(ngModel)]="minRate"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </td>
                <td>
                    <input
                        class="ge-form-control no-arrow-btns"
                        type="number"
                        [(ngModel)]="perPriceHour"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </td>
                <td>
                    <input
                        class="ge-form-control no-arrow-btns"
                        type="number"
                        [(ngModel)]="preKilometer"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </td>
                <td>
                    <button
                        class="add-btn"
                        [ngClass]="{
                            disabled: !(
                                newCustomBusType >= 0 &&
                                preKilometer >= 0 &&
                                perPriceHour >= 0 &&
                                minRate >= 0 &&
                                (VincentVanGoghType !== type ||
                                    waitingTimePrice >= 0)
                            ),
                        }"
                        (click)="addRate()"
                    >
                        +
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
