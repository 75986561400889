import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import moment from "moment";
import { DATE_FORMAT } from "src/app/shared/constants";
@Component({
    selector: "ge-period-input",
    styleUrl: "./period-input.component.scss",
    templateUrl: "./period-input.component.html",
})
export class PeriodInputComponent implements OnChanges {
    @Input()
    public readonly startDate!: string;

    @Input()
    public readonly endDate!: string;

    @Input()
    public readonly minDate!: string;

    @Input()
    public readonly maxDate!: string;

    @Input()
    public readonly inputVisible: boolean = false;

    @Output()
    public readonly startDateUpdate: EventEmitter<string> =
        new EventEmitter<string>();

    @Output()
    public readonly endDateUpdate: EventEmitter<string> =
        new EventEmitter<string>();

    public datepickerMin!: Date;

    public datepickerMax!: Date;

    public datepickerStart!: Date;

    public datepickerEnd!: Date;

    public dateNow: Date = new Date();

    public ngOnChanges(changes: SimpleChanges): void {
        const { startDate, endDate, minDate, maxDate } = changes;
        const createDate = (date: string) => (date ? new Date(date) : null);

        if (startDate) {
            this.datepickerStart = createDate(this.startDate);
        }

        if (endDate) {
            this.datepickerEnd = createDate(this.endDate);
        }

        if (minDate) {
            this.datepickerMin = createDate(this.minDate);
        }

        if (maxDate) {
            this.datepickerMax = createDate(this.maxDate);
        }
    }

    public startEdit(date: Date): void {
        if (date) {
            this.startDateUpdate.emit(moment(date).format(DATE_FORMAT));
        }
    }

    public endEdit(date: Date): void {
        if (date) {
            this.endDateUpdate.emit(moment(date).format(DATE_FORMAT));
        }
    }
}
