<div class="container-fluid">
    <div class="row border-bottom">
        <nav
            class="navbar navbar-static-top"
            role="navigation"
            style="margin-bottom: 0"
        >
            <ul class="nav navbar-top-links navbar-right">
                <li class="notifications">
                    <ge-svg icon="map"></ge-svg>
                    <ge-svg icon="envelope"></ge-svg>
                    <ge-svg icon="bell"></ge-svg>
                </li>
            </ul>
        </nav>
    </div>
</div>
