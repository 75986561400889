<div class="contract-list">
    <div *ngFor="let contract of contacts; let index = index">
        <ge-contract-item
            [contract]="contract"
            [selectedContract]="selectedContract"
            (selectContract)="selectContract.emit($event)"
            (updateContract)="updateContract($event, contract.id, index)"
            (updateContractState)="
                updateContractState($event, contract.id, index)
            "
            (updateRenameContract)="updateRenameContract($event, index)"
        ></ge-contract-item>
    </div>
    <div>
        <ng-container *ngIf="!newContractType">
            <ge-dropdown>
                <span
                    dropdownLabel
                    #dropdownLabel
                    (click)="isClicked = !isClicked"
                    (clickOutside)="changeVisibilityBtn()"
                >
                    <ge-button
                        theme="cranberry"
                        class="add-button"
                        data-translate-me
                        [translateMe]="'clients.addClientPopup.createContract'"
                        [click]="dropdownLabel.click.bind(dropdownLabel)"
                    >
                        {{ "clients.addClientPopup.createContract" | i18n }}
                    </ge-button>
                </span>

                <div dropdownList>
                    <ng-container *ngFor="let item of availableContracts">
                        <ge-dropdown-item
                            *ngIf="
                                item.type !== types.Simple || !hasSimpleContract
                            "
                            class="contract-dropdown-items"
                            (click)="selectNewContractType(item)"
                        >
                            {{ item.type }}
                        </ge-dropdown-item>
                    </ng-container>
                </div>
            </ge-dropdown>
        </ng-container>

        <ng-container *ngIf="!newContractType && !isClicked">
            <ge-dropdown>
                <span dropdownLabel #dropdownLabel>
                    <ge-button
                        theme="cranberry"
                        class="add-button margin-top-20"
                        data-translate-me
                        [translateMe]="
                            'clients.addClientPopup.existingContract'
                        "
                        [click]="dropdownLabel.click.bind(dropdownLabel)"
                    >
                        {{ "clients.addClientPopup.existingContract" | i18n }}
                    </ge-button>
                </span>

                <div dropdownList>
                    <ng-container
                        *ngFor="let item of availableExistingContract"
                    >
                        <ge-dropdown-item
                            class="contract-dropdown-items"
                            (click)="selectExistingContract(item)"
                        >
                            {{ item.name | shortName: 35 }}
                        </ge-dropdown-item>
                    </ng-container>
                </div>
            </ge-dropdown>
        </ng-container>

        <div *ngIf="newContractType" class="new-contract-block">
            <div
                class="margin-bottom-10"
                data-translate-me
                [translateMe]="'relations.contracts.new'"
            >
                {{ "relations.contracts.new" | i18n }}:
                <span class="font-bold">{{ newContractType.type }}</span>
            </div>
            <ge-contract-range-inputs
                data-translate-me
                [translateMe]="'relations.contracts.create'"
                [okLabel]="'relations.contracts.create' | i18n"
                (reset)="resetNewTheme()"
                (save)="onContractCreate($event)"
            ></ge-contract-range-inputs>
        </div>
    </div>
</div>
