import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
    ContractType,
    IExistingContractType,
} from "../../../../../../../shared/models/contracts.models";
import {
    IBaseContract,
    IDateRange,
    INameContract,
} from "../../../contract-modal.models";
import { FormGroupDirective } from "@angular/forms";

@Component({
    selector: "ge-contract-item",
    styleUrl: "./contract-item.component.scss",
    templateUrl: "./contract-item.component.html",
    providers: [FormGroupDirective],
})
export class ContractItemComponent implements OnInit {
    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly selectedContract!: IBaseContract;

    @Output()
    public readonly selectContract: EventEmitter<IBaseContract> =
        new EventEmitter<IBaseContract>();

    @Output()
    public readonly updateContract: EventEmitter<IDateRange> =
        new EventEmitter<IDateRange>();

    @Output()
    public readonly updateContractState: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    @Output()
    public readonly updateRenameContract: EventEmitter<INameContract> =
        new EventEmitter<INameContract>();

    private get oldContractName() {
        return this.contract.name
            ? this.contract.name
            : this.contract.contractType.type + " " + this.contract.id;
    }
    public types: typeof ContractType = ContractType;

    public editMode = false;
    public isRename = false;
    public renameContract;
    public changeNameContract = false;

    ngOnInit() {
        this.renameContract = this.oldContractName;
    }

    public updatePeriod(dates: IDateRange): void {
        this.updateContract.emit(dates);

        this.editMode = false;
    }
    public cancelRenameBlock() {
        this.isRename = false;
        this.renameContract = this.oldContractName;
    }
    public saveRenameContract(contract: IExistingContractType) {
        this.changeNameContract
            ? this.updateRenameContract.emit({
                  contractId: contract.id,
                  name: this.renameContract,
              })
            : null;
        this.isRename = false;
        this.changeNameContract = false;
    }
    public showRenameBlock() {
        this.isRename = !this.isRename;
    }
}
