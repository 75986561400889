import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NotFoundComponent } from "./not-found/not-found.component";
import { MaintenseComponent } from "./maintense/maintense.component";

@NgModule({
    declarations: [MaintenseComponent, NotFoundComponent],
    imports: [
        CommonModule, //, NgbModule
    ],
    exports: [],
})
export class AdditionalModule {}
