import gql from "graphql-tag";

export const getDriversAndDriversUnreadMessages = gql`
    query {
        drivers {
            firstName
            lastName
            chatId
            is3rdPartyDriver
        }
        allDriverMessage {
            notSeenMessagesCount
            chatId
        }
    }
`;

export const getMessagesForCurrentDriver = gql`
    query allDriverMessage($chatId: String, $take: Int, $skip: Int) {
        allDriverMessage(chatId: $chatId, take: $take, skip: $skip) {
            chatId
            notSeenMessagesCount
            messages {
                chatId

                content
                createDate
                creator {
                    name
                    driver {
                        lastName
                        photo {
                            url
                        }
                        id
                    }
                    displayName
                    token
                }
            }
        }
    }
`;

export const sendMessage = gql`
    mutation addMessage($content: String, $chatId: [String]) {
        addMessage(content: $content, chatId: $chatId) {
            chatId
            content
            createDate
            creator {
                displayName
                name
                token
            }
        }
    }
`;

export const messageIsRed = gql`
    mutation readsMessage($chatId: String) {
        readsMessage(chatId: $chatId)
    }
`;

export const messageAdded = gql`
    subscription messageAdded($token: String) {
        messageAdded(token: $token) {
            content
            createDate
            chatId
            creator {
                driver {
                    photo {
                        url
                    }
                    id
                }
                token
                name
                displayName
            }
        }
    }
`;
