/* src/app/shared/components/radio-button-select/radio-button-select.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.drop-toggle {
  display: flex;
  justify-content: space-between;
  align-self: center;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(219, 223, 232, 0.5);
  color: #828282;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.3s;
}
.drop-toggle b {
  color: #0b162d;
}
.drop-toggle i {
  float: right;
}
.drop-show {
  position: absolute;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
  min-width: 160px;
  max-width: 300px;
  max-height: 250px;
  overflow: auto;
  z-index: 100;
}
.drop-show label {
  display: block;
  font-size: 15px;
  cursor: pointer;
  padding: 7px 10px;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drop-show label input {
  vertical-align: top;
}
.drop-show label span {
  display: inline-block;
}
.drop-toggle-label {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
  margin-right: 10px;
  margin-left: 5px;
}
.pointer:hover {
  cursor: pointer;
}
.drop-show label span {
  font-size: 12px;
  line-height: 1.33;
  color: #0b162d;
  margin-left: 24px;
}
.drop-show .control {
  display: block;
  position: relative;
}
.drop-show .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.drop-show .control-indicator {
  position: absolute;
  top: 9px;
  left: 0;
  height: 20px;
  width: 20px;
  background: white;
  border: 1px solid rgba(219, 223, 232, 0.5);
}
.drop-show .control-radio .control-indicator {
  border-radius: 50%;
}
.drop-show .control input:checked ~ .control-indicator {
  background: #231e80;
}
.drop-show .control-indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.drop-show .control input:checked ~ .control-indicator:after {
  display: block;
}
.drop-show .control-radio .control-indicator:after {
  left: 3px;
  top: 3px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #fff;
}
.svgArrow {
  pointer-events: none;
}
.filter-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}
.close-icon {
  position: absolute;
  right: -7px;
  top: -7px;
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(219, 223, 232, 0.5);
  background-color: #ffffff;
}
.close-icon ge-svg {
  position: absolute;
  top: -5px;
  left: 5px;
}
.ng-touched.ng-invalid:host ::ng-deep .drop-toggle {
  border: 1px solid #98002c;
}
/*# sourceMappingURL=radio-button-select.component.css.map */
