import { CommonModule } from "@angular/common";
import {
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AutosizeModule } from "ngx-autosize";

import { SearchFieldModule } from "src/app/shared/components/search-field/search-field.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CheckBoxModule } from "../shared/components/checkbox/checkbox.module";
import { ChatDriversItemComponent } from "./chat-drivers-list/chat-drivers-item.component";
import { ChatComponent } from "./chat-main/chat.component";
import { MessagesComponent } from "./messages/messages.component";
import { ModalButtonComponent } from "./modal-button/modal-button.component";
import { ChatApiService } from "./services/chat-api.service";
import { FilterPipe } from "./components/filter.pipe";

const declarations = [
    ChatComponent,
    MessagesComponent,
    ChatDriversItemComponent,
    ModalButtonComponent,
    FilterPipe,
];

@NgModule({
    declarations: [...declarations],
    exports: [...declarations],
    imports: [
        AutosizeModule,
        BrowserModule,
        CheckBoxModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchFieldModule,
        SharedModule,
    ],
    providers: [ChatApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class ChatModule {}
