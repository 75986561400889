import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ToasterNotificationService } from "src/app/shared/components/toaster-notification/toaster-notification.service";
import { I18nService } from "src/app/shared/i18n/i18n.service";
import { Driver, IVacationBalance } from "../../driver";
import { DriversDataService } from "../../drivers-data.service";
import { EventHelper } from "../../../../shared/helpers/event-helper";

@Component({
    selector: "ge-balance-vacation",
    templateUrl: "./balance-vacation.component.html",
    styleUrl: "./balance-vacation.component.scss",
})
export class BalanceVacationComponent implements OnInit {
    modalWindowOptions: IModalWindowOptions = {
        title: "Drivers.balanceVacation.title",

        buttons: [
            {
                label: "Drivers.balanceVacation.save",
                click: this.edit.bind(this),
            },
        ],
    };

    balanceVacationFormGroup: UntypedFormGroup;
    private data: { driver: Driver };
    driver: Driver;
    dateOfChange: Date = new Date();
    constructor(
        private fb: UntypedFormBuilder,
        private driversDataService: DriversDataService,
        private toasterNotificationService: ToasterNotificationService,
        private activeModal: NgbActiveModal,
        private i18nService: I18nService,
    ) {}

    ngOnInit() {
        this.driver = this.data.driver;
        this.createForm();
    }

    createForm() {
        this.balanceVacationFormGroup = this.fb.group({
            driverId: [this.driver.id, [Validators.required]],
            dateOfChange: [this.dateOfChange, [Validators.required]],
            regularBalanceChange: [null],
            extraBalanceChange: [null],
            employerBalanceChange: [null],
        });
    }

    onDateChanged(event: Date) {
        if (event) {
            this.balanceVacationFormGroup.patchValue({
                dateOfChange: event,
            });
        }
    }

    edit() {
        if (this.balanceVacationFormGroup.valid) {
            const params: IVacationBalance =
                this.balanceVacationFormGroup.getRawValue();
            this.driversDataService
                .balanceDriverVacation(params)
                .pipe(
                    catchError((error) => {
                        this.toasterNotificationService.errorToaster(
                            this.i18nService.getTranslatedWord(
                                "Drivers.uploadBalance.notifications.error",
                            ),
                        );
                        return throwError(error);
                    }),
                )
                .subscribe(() => {
                    this.toasterNotificationService.successToaster(
                        this.i18nService.getTranslatedWord(
                            "Drivers.uploadBalance.notifications.success",
                        ),
                    );
                    this.activeModal.close();
                });
        }
    }

    protected readonly EventHelper = EventHelper;
}
