import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { DestructibleController } from "../../../../../../common/classes/destructible.controller";
import { ToasterNotificationService } from "../../../../../../shared/components/toaster-notification/toaster-notification.service";
import {
    ContractType,
    IContractType,
    IExistingContractType,
} from "../../../../../../shared/models/contracts.models";
import { IBaseContract, IDateRange } from "../../contract-modal.models";
import { ContractApiService } from "../../services/contract-api.service";
import { ContractLoaderService } from "../../services/contract-loader.service";
import { ClientsService } from "src/app/relations/clients/clients.service";

export interface ICreateContractEvent {
    contractType?: IContractType;
    dateFrom?: string;
    dateTo?: string;
    namedContractId?: number;
}

@Component({
    selector: "ge-modal-contract-list",
    styleUrl: "./contract-list.component.scss",
    templateUrl: "./contract-list.component.html",
})
export class ContractListComponent
    extends DestructibleController
    implements OnChanges
{
    @Input()
    public readonly contacts: IBaseContract[];

    @Input()
    public readonly selectedContract: IBaseContract;

    @Input()
    public readonly availableContracts: IContractType[];

    @Input()
    public readonly availableExistingContract: IExistingContractType[];

    @Output()
    public readonly selectContract: EventEmitter<IBaseContract> =
        new EventEmitter<IBaseContract>();

    @Output()
    public readonly createContract: EventEmitter<ICreateContractEvent> =
        new EventEmitter<ICreateContractEvent>();

    public newContractType: IContractType | null = null;

    public newExistingContract: IExistingContractType;

    public types: typeof ContractType = ContractType;

    public hasSimpleContract = false;
    public isClicked = false;

    constructor(
        private contractApiService: ContractApiService,
        private loaderService: ContractLoaderService,
        private notificationService: ToasterNotificationService,
        private clientsService: ClientsService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { contacts } = changes;

        if (contacts && this.contacts) {
            this.hasSimpleContract = false;

            for (const contract of this.contacts) {
                if (contract.contractType.type === ContractType.Simple) {
                    this.hasSimpleContract = true;

                    break;
                }
            }
        }
    }

    public selectNewContractType(item: IContractType): void {
        this.newContractType = item;
        this.changeVisibilityBtn();
    }

    public selectExistingContract(item: IExistingContractType): void {
        this.newExistingContract = item;

        this.createContract.emit({
            namedContractId: this.newExistingContract.id,
            contractType: this.newExistingContract.contractType,
        });

        this.resetNewTheme();
    }
    public resetNewTheme(): void {
        this.newContractType = null;
        this.newExistingContract = null;
    }

    public onContractCreate(date?: IDateRange): void {
        this.createContract.emit({
            contractType: this.newContractType,
            dateFrom: date && date.from,
            dateTo: date && date.to,
        });

        this.resetNewTheme();
    }

    public updateContract(
        { from, to }: IDateRange,
        contractId: number,
        index: number,
    ): void {
        this.loaderService.startLoading();

        this.contractApiService
            .updateContractTimeRange(contractId, from, to)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (contract: IBaseContract) => {
                    const oldContract = this.contacts[index];

                    this.contacts.splice(index, 1, {
                        ...oldContract,
                        ...contract,
                    });
                    this.clientsService.isContractChanged.next({
                        isChanged: true,
                        contract,
                    });
                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public updateContractState(
        isActive: boolean,
        id: number,
        index: number,
    ): void {
        this.loaderService.startLoading();

        this.contractApiService
            .updateContractState(id, isActive)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => {
                    const oldItem = this.contacts[index];
                    this.clientsService.isContractChanged.next({
                        isChanged: true,
                    });
                    this.contacts.splice(index, 1, { ...oldItem, isActive });
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public updateRenameContract(renamedContractData, index) {
        this.loaderService.startLoading();

        this.contractApiService
            .updateContractName(
                renamedContractData.name,
                renamedContractData.contractId,
            )
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (contract: IBaseContract) => {
                    const oldContract = this.contacts[index];

                    this.contacts.splice(index, 1, {
                        ...oldContract,
                        ...contract,
                    });
                    this.clientsService.isContractChanged.next({
                        isChanged: true,
                        contract,
                    });
                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }
    public changeVisibilityBtn() {
        if (this.isClicked) {
            this.isClicked = false;
        }
    }
}
