/* src/app/relations/clients/modals/contract-modal/components/contract-types/simple-contract/simple-contract.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
:host {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bustype-container {
  margin-top: -15px;
}
td {
  height: 43px;
}
.ort-table input {
  width: 110px;
}
.white-space-no {
  white-space: nowrap;
}
.bustype-block {
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgba(219, 223, 232, 0.5);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 15px;
}
.bustype-block.selected,
.bustype-block:hover {
  background: rgba(0, 0, 0, 0.035);
}
.multiplier {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background: #fff;
  margin-right: 10px;
  min-width: 78px;
  padding: 0 15px;
  border: 1px solid rgba(219, 223, 232, 0.5);
  border-radius: 3px;
  cursor: pointer;
}
.multiplier.active {
  background: #f3f4f7;
}
.multiplier .marker-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid rgba(219, 223, 232, 0.5);
}
.hour-width {
  width: 135px;
}
.kilometer-width {
  width: 172px;
}
.day-width {
  width: 100px;
}
/*# sourceMappingURL=simple-contract.component.css.map */
