/* src/app/Chat/modal-button/modal-button.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.openChat {
  position: fixed;
  background: none;
  box-shadow: none;
  border: none;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 100px;
  bottom: 100px;
}
.active {
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  transform-origin: center;
  animation: pulse 0.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}
/*# sourceMappingURL=modal-button.component.css.map */
