<div class="busform-wrapper">
    <ge-button
        *ngIf="!formVisible"
        theme="cranberry"
        [click]="toggleForm"
        data-translate-me
        [translateMe]="'relations.contracts.createCustomBusType'"
    >
        {{ "relations.contracts.createCustomBusType" | i18n }}
    </ge-button>
    <div
        *ngIf="formVisible"
        class="d-flex ge-form-group no-bottom-margin align-items-end"
    >
        <div class="flex-grow-1 margin-right-15">
            <div data-translate-me [translateMe]="'relations.contracts.name'">
                {{ "relations.contracts.name" | i18n }}
            </div>
            <input
                class="ge-form-control"
                autocomplete="off"
                type="text"
                [(ngModel)]="busTypeName"
            />
        </div>
        <div class="short-input margin-right-15">
            <div
                data-translate-me
                [translateMe]="'relations.contracts.seatType'"
            >
                {{ "relations.contracts.seatType" | i18n }}
            </div>
            <select class="ge-form-control" [(ngModel)]="seatType">
                <option
                    *ngFor="let value of availableSeatTypes"
                    [value]="value"
                >
                    {{ value }}
                </option>
            </select>
        </div>
        <div class="short-input margin-right-15">
            <div
                data-translate-me
                [translateMe]="'relations.contracts.passengersFrom'"
            >
                {{ "relations.contracts.passengersFrom" | i18n }}
            </div>
            <input
                class="ge-form-control no-arrow-btns"
                autocomplete="off"
                type="number"
                (wheel)="EventHelper.cancelOnWheelEvent($event)"
                [(ngModel)]="passengersFrom"
            />
        </div>
        <div class="short-input margin-right-15">
            <div
                data-translate-me
                [translateMe]="'relations.contracts.passengersTo'"
            >
                {{ "relations.contracts.passengersTo" | i18n }}
            </div>
            <input
                class="ge-form-control no-arrow-btns"
                autocomplete="off"
                type="number"
                (wheel)="EventHelper.cancelOnWheelEvent($event)"
                [(ngModel)]="passengersTo"
            />
        </div>
        <ge-button
            class="margin-right-15"
            theme="cranberry"
            [disabled]="
                !busTypeName?.length || passengersFrom < 1 || passengersTo < 1
            "
            [click]="createBusType"
            data-translate-me
            [translateMe]="'relations.contracts.createBusType'"
        >
            {{ "relations.contracts.createBusType" | i18n }}
        </ge-button>

        <ge-button
            [click]="cancel"
            data-translate-me
            [translateMe]="'relations.contracts.cancel'"
        >
            {{ "relations.contracts.cancel" | i18n }}
        </ge-button>
    </div>
</div>
