import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import {
    ContractType,
    nonDeleteBusTypesContracts,
} from "../../../../../../../shared/models/contracts.models";
import {
    IBaseContract,
    IContractBusTypeSummary,
    IGrovenbeekRate,
    IRatePeriod,
} from "../../../contract-modal.models";
import { ContractDetailsBaseController } from "../contract-details-base.controller";
import { EventHelper } from "../../../../../../../shared/helpers/event-helper";

@Component({
    selector: "ge-grovenbeek-contract",
    templateUrl: "./grovenbeek-contract.component.html",
    styleUrl: "./grovenbeek-contract.component.scss",
})
export class GrovenbeekContractComponent
    extends ContractDetailsBaseController<IGrovenbeekRate[]>
    implements OnChanges
{
    @Input()
    public readonly details!: IGrovenbeekRate[];

    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    public type: ContractType = ContractType.Groevenbeek;

    public newDriversHourRate!: number | null;

    public newKilometersRate!: number | null;

    public newMinKm!: number | null;

    public newMinPassengers!: number | null;

    public newFromDate!: string | null;

    public newToDate!: string | null;

    public get isDeleteAvailable(): boolean {
        return nonDeleteBusTypesContracts.indexOf(this.type) < 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { details } = changes;

        if (details && this.details) {
            this.newDriversHourRate = null;
            this.newKilometersRate = null;
            this.newMinKm = null;
            this.newMinPassengers = null;
            this.newFromDate = null;
            this.newToDate = null;
        }
    }

    public updateField(
        event: Event,
        index: number,
        key: keyof IGrovenbeekRate,
    ): void {
        const { value } = event.target as HTMLInputElement;
        const detailsCopy = [...this.details];

        const newRow = { ...this.details[index], [key]: parseFloat(value) };

        detailsCopy.splice(index, 1, newRow);

        this.updateRateRequest(detailsCopy);
    }

    public removeRate(index: number): void {
        this.loaderService.startLoading();

        const { id } = this.details[index];

        this.apiService
            .deleteContractRate<IGrovenbeekRate[]>(
                ContractType.Groevenbeek,
                this.contract.id,
                id,
            )
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => {
                    this.details.splice(index, 1);

                    this.contractUpdate.emit(this.details);
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public updateStartDate(from: string, index: number): void {
        const { period } = this.details[index];

        this.updateRatePeriod({ ...period, from });
    }

    public updateEndDate(to: string, index: number): void {
        const { period } = this.details[index];

        this.updateRatePeriod({ ...period, to });
    }

    public updateRatePeriod(period: IRatePeriod): void {
        this.loaderService.startLoading();

        this.apiService
            .updateContractPeriod(this.contract.id, [period])
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (periods: IRatePeriod[]) => {
                    this.details.forEach((item: IGrovenbeekRate) => {
                        if (item.period.id === periods[0].id) {
                            item.period = periods[0];
                        }
                    });

                    this.contractUpdate.emit([...this.details]);

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public createRate(): void {
        if (
            !(
                this.newFromDate &&
                this.newToDate &&
                this.newDriversHourRate > 0 &&
                this.newKilometersRate > 0 &&
                this.newMinKm > 0 &&
                this.newMinPassengers >= 0
            )
        ) {
            return;
        }

        const period = {
            from: this.newFromDate,
            to: this.newToDate,
        };

        const newRate = {
            driversHourRate: this.newDriversHourRate,
            kilometersRate: this.newKilometersRate,
            minKm: this.newMinKm,
            minPassengers: this.newMinPassengers,
        };

        const periods = this.details.map(
            (item: IGrovenbeekRate) => item.period,
        );

        this.loaderService.startLoading();

        this.apiService
            .updateContractPeriod(this.contract.id, [...periods, period])
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (periodsRes: IRatePeriod[]) => {
                    const periodDate = periodsRes[periodsRes.length - 1];

                    this.updateRateRequest([
                        ...this.details,
                        { ...newRate, period: periodDate } as IGrovenbeekRate,
                    ]);
                },
                () => {
                    this.loaderService.stopLoading();
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    private updateRateRequest(details: IGrovenbeekRate[]): void {
        this.loaderService.startLoading();

        this.apiService
            .updateContractDetails<IGrovenbeekRate[]>(this.type, {
                groevenbeekRates: details.map((item: IGrovenbeekRate) => ({
                    ...item,
                    ratesPeriodId: item.period.id,
                    period: undefined,
                })),
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (newData: IGrovenbeekRate[]) => {
                    this.contractUpdate.emit(
                        newData.map((item: IGrovenbeekRate) => {
                            return item.period
                                ? item
                                : {
                                      ...item,
                                      period: details[details.length - 1]
                                          .period,
                                  };
                        }),
                    );

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    protected readonly EventHelper = EventHelper;
}
