import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { GraphqlService } from "../services/graphql.service";

@Injectable({
    providedIn: "root",
})
export class OfferConfirmationService {
    constructor(private graphqlService: GraphqlService) {}

    confirm(token: string) {
        const query = gql`
            mutation confirmOfferByEmail($token: String!) {
                confirmOfferByEmail(token: $token) {
                    id
                }
            }
        `;

        return this.graphqlService.query(query, { token });
    }
}
