import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { PassangerDetailModalComponent } from "./passanger-detail-modal.component";
import { SvgModule } from "src/app/common/components/svg/svg.module";
import { I18nModule } from "../../../../shared/i18n/i18n.module";
import { ButtonModule } from "src/app/shared/components/button/button.module";
import { InputModule } from "../../../../shared/components/input/input.module";
import { SelectModule } from "src/app/shared/components/select/select.module";
import { PipesModule } from "../../../../shared/pipes/pipes.module";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
    declarations: [PassangerDetailModalComponent],
    imports: [
        ButtonModule,
        CommonModule,
        I18nModule,
        InputModule,
        PipesModule,
        ReactiveFormsModule,
        SelectModule,
        SharedModule,
        SvgModule,
    ],
})
export class PassangerDetailModalModule {}
