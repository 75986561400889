import { Component, OnInit, ViewChild } from "@angular/core";
import { pluck } from "rxjs/operators";
import { AttachmentService } from "src/app/services/attachment.service";
import { AttachmentRemoveEvent } from "src/app/shared/components/attachment-preview/attachment-remove-event.type";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";
import { ToasterNotificationService } from "src/app/shared/components/toaster-notification/toaster-notification.service";
import { UploadComponent } from "src/app/shared/components/upload/upload/upload.component";
import { I18nService } from "src/app/shared/i18n/i18n.service";

const enum FileTypes {
    PDF = "pdf",
}

@Component({
    selector: "ge-attach-pdf-modal",
    templateUrl: "./attach-pdf-modal.component.html",
    styleUrl: "./attach-pdf-modal.component.scss",
})
export class AttachPdfModalComponent implements OnInit {
    modalWindowOptions: IModalWindowOptions = {
        ngbOptions: {
            windowClass: "sm",
        },
        title: "invoices.attach",
        buttons: [
            {
                label: "invoices.attach",
                click: this.attach.bind(this),
                disabled: () => !this.attachments.length,
            },
        ],
    };

    @ViewChild("fileUploader") fileUploader: UploadComponent;
    public attachments: File[] = [];
    public data: {
        invoiceId: number;
    };
    invoiceId: number;

    constructor(
        private toasterNotificationService: ToasterNotificationService,
        private i18nService: I18nService,
        private modalWindowService: ModalWindowService,
        private attachmentService: AttachmentService,
    ) {}

    ngOnInit() {
        this.invoiceId = this.data.invoiceId;
    }

    readFile(file: File) {
        const fileType = file.name.split(".").pop().toLowerCase();

        if (fileType !== FileTypes.PDF) {
            this.toasterNotificationService.errorToaster(
                this.i18nService.getTranslatedWord(
                    "invoices.error.uploadPDFFile",
                ),
            );
            return;
        }

        this.attachments.push(file);
    }

    attach() {
        this.fileUploader.attach();

        this.attachmentService
            .attachPDFtoInvoice(this.invoiceId)
            .pipe(pluck("data", "attachPdfToInvoice"))
            .subscribe((data) => {
                this.toasterNotificationService.successToaster(
                    this.i18nService.getTranslatedWord(
                        "invoices.success.attachedPDF",
                    ),
                );
                this.modalWindowService.close(data);
            });
    }

    removeAttachment(event: AttachmentRemoveEvent) {
        const { index } = event;
        this.fileUploader.files.splice(index, 1);
    }
}
