import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { of, zip } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

import { ContractType } from "../../../../../../../shared/models/contracts.models";
import {
    IBaseContract,
    IContractBusTypeSummary,
    ICountryItem,
    ICustomBusType,
    IRatePeriod,
    ISunwebCountry,
    ISunwebPriceDetail,
} from "../../../contract-modal.models";
import { ContractDetailsBaseController } from "../contract-details-base.controller";
import { ISunwebPriceUpdate } from "./sunweb-table/sunweb-table.component";

@Component({
    selector: "ge-sunweb-contract",
    templateUrl: "./sunweb-contract.component.html",
    styleUrl: "./sunweb-contract.component.scss",
})
export class SunwebContractComponent
    extends ContractDetailsBaseController<ISunwebPriceDetail[]>
    implements OnChanges
{
    @Input()
    public readonly details!: ISunwebPriceDetail[];

    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    public periods!: IRatePeriod[];

    public selectedPeriod!: IRatePeriod | null;

    public countries: ISunwebCountry[] = [];

    public availableBusType: IContractBusTypeSummary[];

    public type: ContractType = ContractType.Sunweb;

    public ngOnChanges(changes: SimpleChanges): void {
        const { customBusTypes, contract } = changes;

        if (customBusTypes && this.customBusTypes) {
            this.availableBusType = [...this.customBusTypes];
        }

        if (contract && this.contract.id) {
            this.selectedPeriod = null;
            this.periods = [];
            this.loadSunwebFullData();
        }
    }

    public setNewPeriods(periods: IRatePeriod[]): void {
        this.periods = this.periods.concat(periods);

        if (this.periods.length === 1) {
            this.selectedPeriod = this.periods[0];
        }
    }

    public addBusType(busType: ICustomBusType): void {
        const { passengersCount, ...rest } = busType;

        this.availableBusType = [
            ...this.availableBusType,
            rest as IContractBusTypeSummary,
        ];
    }

    public deleteSunwebPrice(id: number): void {
        this.loaderService.startLoading();

        this.apiService
            .deleteContractRate<ISunwebPriceDetail[]>(
                ContractType.Sunweb,
                this.contract.id,
                id,
            )
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (details: ISunwebPriceDetail[]) => {
                    this.contractUpdate.emit(details);
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public addCountry(country: ICountryItem): void {
        this.loaderService.startLoading();

        this.apiService
            .setSunwebCountries(country)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => {
                    this.loadSunwebFullData("countries");
                    this.notificationService.successToaster(
                        "relations.contracts.countryCreated",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public updateSunwebPrice({
        midweekPrice,
        fridayPrice,
        id,
        country,
        countryISO,
        customBusType,
        ratesPeriod,
    }: ISunwebPriceUpdate): void {
        this.loaderService.startLoading();

        const price = id
            ? { midweekPrice, fridayPrice, id, customBusType, ratesPeriod }
            : {
                  sunWebCountry: {
                      countryName: country,
                      iSO_CountryCode: countryISO,
                  },
                  ratesPeriod,
                  customBusType,
                  midweekPrice,
                  fridayPrice,
              };
        this.apiService
            .updateContractDetails<ISunwebPriceDetail[]>(ContractType.Sunweb, {
                clientContractId: this.contract.id,
                sunwebPrices: [price],
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (details: ISunwebPriceDetail[]) => {
                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );

                    if (!details[0]) {
                        return;
                    }

                    for (const item of this.details) {
                        if (item.id === details[0].id) {
                            item.midweekPrice = midweekPrice;
                            item.fridayPrice = fridayPrice;

                            this.contractUpdate.emit([...this.details]);

                            return;
                        }
                    }

                    this.contractUpdate.emit([...this.details, details[0]]);
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    private loadSunwebFullData(loadOnly?: "periods" | "countries"): void {
        this.loaderService.startLoading();

        const periodsReq =
            loadOnly === "countries"
                ? of(null)
                : this.apiService.loadRatesByContract(this.contract.id);
        const countriesReq =
            loadOnly === "periods"
                ? of(null)
                : this.apiService.getSunwebCountries();
        zip<[IRatePeriod[], ISunwebCountry[]]>(periodsReq, countriesReq)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                ([periods, countries]: [IRatePeriod[], ISunwebCountry[]]) => {
                    if (periods) {
                        this.periods = periods;
                        this.selectedPeriod = this.periods[0];
                    }

                    if (countries) {
                        this.countries = countries;
                    }
                },
                () =>
                    this.notificationService.errorToaster("errors.commonError"),
            );
    }
}
