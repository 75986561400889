import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable, BehaviorSubject } from "rxjs";
import { map, pluck } from "rxjs/operators";

import { GraphqlService } from "../../services/graphql.service";
import { IChatMessage, IDriver, IUser } from "../chat-main/chat.models";
import {
    getDriversAndDriversUnreadMessages,
    getMessagesForCurrentDriver,
    messageIsRed,
    sendMessage,
} from "./api/query";

const ITEMS_PER_PAGE = 10;

@Injectable()
export class ChatApiService {
    public newMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false,
    );

    public messages: IChatMessage[];

    public message: IChatMessage;

    public currentUser: IUser = {
        id: "admin1",
        avatarUrl: "../../assets/img/admin.png",
        name: "Admin",
    };

    constructor(
        private apollo: Apollo,
        private graphqlService: GraphqlService,
    ) {}

    public getMessagesForCurrentChat(
        currentPage: number,
        driverId: string,
    ): Observable<IChatMessage[]> {
        return this.graphqlService
            .query(getMessagesForCurrentDriver, {
                chatId: driverId,
                take: ITEMS_PER_PAGE,
                skip: currentPage * ITEMS_PER_PAGE,
            })
            .pipe(
                pluck("data", "allDriverMessage", "0", "messages"),
                map((messages: IChatMessage[]) => {
                    if (!messages) {
                        messages = [];
                    }
                    return messages.reverse();
                }),
            );
    }

    public messageIsRed(chatId: string): Observable<any> {
        return this.apollo.mutate({
            mutation: messageIsRed,
            variables: { chatId },
        });
    }

    public sendMessage(
        content: string,
        chatId: string[],
    ): Observable<IChatMessage> {
        return this.apollo
            .mutate({
                mutation: sendMessage,
                variables: {
                    content,
                    chatId,
                },
            })
            .pipe(pluck("data", "addMessage", "0"));
    }

    public getAdminFromDb(): IUser {
        return this.currentUser;
    }

    public getDriversFromDb(): Observable<IDriver[]> {
        return this.graphqlService
            .query(getDriversAndDriversUnreadMessages)
            .pipe(
                pluck("data"),
                map((data: any) => {
                    if (!data) {
                        return [];
                    }

                    const { drivers, allDriverMessage } = data;

                    if (!drivers || !allDriverMessage) {
                        return [];
                    }

                    const newDrivers = [];

                    drivers.map((driver: IDriver) => {
                        allDriverMessage.filter((message: any) => {
                            if (message.chatId === driver.chatId) {
                                driver.unreadMessages =
                                    message.notSeenMessagesCount;
                            }
                        });
                        newDrivers.push(driver);
                    });
                    const not3dPartyDrivers = newDrivers.filter(
                        (driver) => !driver.is3rdPartyDriver,
                    );
                    this.sortDriverByUnreadMessages(not3dPartyDrivers);

                    return newDrivers;
                }),
            );
    }

    private sortDriverByUnreadMessages(drivers: IDriver[]): void {
        drivers.sort((a: IDriver, b: IDriver) =>
            a.unreadMessages < b.unreadMessages ? 1 : -1,
        );
    }
}
