import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Observable, throwError, zip } from "rxjs";
import { finalize, map, switchMap, takeUntil } from "rxjs/operators";

import { FullYearCalendarComponent } from "../../../../../../../common/components/full-year-calendar/full-year-calendar/full-year-calendar.component";
import { ContractType } from "../../../../../../../shared/models/contracts.models";
import {
    IBusType,
    IBusTypeRateItem,
    IContractOrt,
    IDayRateUpdate,
    IFullBusType,
    IMultiplier,
    IRateMultiplier,
    ISimpleContract,
} from "../../../contract-modal.models";
import { ContractDetailsBaseController } from "../contract-details-base.controller";
import { EventHelper } from "../../../../../../../shared/helpers/event-helper";

@Component({
    selector: "ge-simple-contract",
    templateUrl: "./simple-contract.component.html",
    styleUrl: "./simple-contract.component.scss",
})
export class SimpleContractComponent
    extends ContractDetailsBaseController<ISimpleContract>
    implements OnInit, OnChanges
{
    @ViewChild(FullYearCalendarComponent)
    public readonly fullYearCalendarComponent: FullYearCalendarComponent;

    public busTypes: IBusType[] = [];

    public selectedItem = 0;

    public currentYear: number = new Date().getFullYear();

    public selectedMultiplier!: IMultiplier;

    public multipliers: IMultiplier[] = [];

    public changedMultipliers!: Record<string, number> | null;

    public multiplierDateToMultipliersObjId!: Record<string, number>;

    public multiplierDateToMultiplierId!: Record<string, number>;

    public kilometerRates!: IBusTypeRateItem[];

    public hourRates!: IBusTypeRateItem[];

    public dayRate!: IBusTypeRateItem;

    public ort!: IContractOrt;

    public minYear: number = this.currentYear;

    public maxYear: number = this.currentYear;

    public multiplierValue: number | null;

    public multiplierColor: string | null;

    public selectedYear: number = this.currentYear;

    public type: ContractType = ContractType.Simple;

    public multiplierInputVisible = false;

    // public selectedMultiplierDates: any = {};

    public ngOnInit(): void {
        this.apiService.getStaticBusTypes().subscribe((busTypes) => {
            if (!busTypes || !busTypes.length) {
                return throwError("Bus type is required");
            }

            this.busTypes = busTypes;

            this.init();
        });

        this.clientsService.isContractChanged.subscribe((res) => {
            if (res.isChanged && res.contract) {
                this.contract.activeFrom = res.contract.activeFrom;
                this.contract.activeTo = res.contract.activeTo;
                this.setContractYear();
                this.init();
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { contract } = changes;

        if (contract && this.contract) {
            this.setContractYear();
        }
    }

    setContractYear() {
        this.minYear = new Date(this.contract.activeFrom).getFullYear();
        this.maxYear = new Date(this.contract.activeTo).getFullYear();
    }

    public init() {
        this.loaderService.startLoading();

        zip<[IMultiplier[], IContractOrt, IBusType[]]>(
            this.apiService.getMultipliers(this.contract.id),
            this.apiService.getOrt(this.contract.id),
            // this.apiService.getStaticBusTypes()
        )
            .pipe(
                switchMap(
                    ([multipliers, ort, busTypes]: [
                        IMultiplier[],
                        IContractOrt,
                        IBusType[],
                    ]) => {
                        // this.busTypes = busTypes;
                        this.multipliers = multipliers;
                        this.ort = ort;

                        if (this.multipliers && this.multipliers.length) {
                            this.selectedMultiplier = this.multipliers[0];
                        }

                        // if (!busTypes || !busTypes.length) {
                        //     return throwError('Bus type is required');
                        // }

                        const { id } = this.busTypes[this.selectedItem];

                        return zip<[IFullBusType, IRateMultiplier[]]>(
                            this.apiService.getSpecificBusType(
                                this.contract.id,
                                id,
                            ),
                            this.apiService.getSimpleContractRateMultipliers(
                                this.contract.id,
                                id,
                            ),
                        );
                    },
                ),
                finalize(() => this.loaderService.stopLoading()),
                map(
                    ([busType, multipliers]: [
                        IFullBusType,
                        IRateMultiplier[],
                    ]) => [this.processBusTypeDetails(busType), multipliers],
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(
                ([rate, multipliers]: [IFullBusType, IRateMultiplier[]]) => {
                    this.processMultipliers(multipliers);

                    this.hourRates = rate.hourRates;
                    this.dayRate = rate.dayRate;
                    this.kilometerRates = rate.kilometerRates;
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public onDateChanged(event): void {
        const { selectedDates } = this.fullYearCalendarComponent;
        this.changedMultipliers = null;

        // set date with new multipliers
        for (const date in selectedDates) {
            if (selectedDates.hasOwnProperty(date)) {
                if (
                    this.multiplierDateToMultipliersObjId[date] !==
                    selectedDates[date]
                ) {
                    this.changedMultipliers = this.changedMultipliers || {};
                    this.changedMultipliers[date] = selectedDates[date];
                }
            }
        }
    }

    public resetMultipliers(): void {
        this.multiplierDateToMultipliersObjId = {
            ...this.multiplierDateToMultipliersObjId,
        };
        this.changedMultipliers = null;
    }

    public openMultiplierInput: () => void = () => {
        this.multiplierInputVisible = true;
    };

    public cancelMultiplier: () => void = () => {
        this.multiplierInputVisible = false;
        this.multiplierColor = "";
        this.multiplierValue = null;
    };

    public updateOrt(key: keyof IContractOrt, event: FocusEvent): void {
        const { value } = event.target as HTMLInputElement;

        if (!value) {
            return;
        }

        this.loaderService.startLoading();

        this.apiService
            .updateContractOrt(this.contract.id, {
                ...this.ort,
                [key]: +value,
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (ort: IContractOrt) => {
                    this.ort = ort;

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public createMultiplier: () => void = () => {
        if (!this.multiplierColor || !this.multiplierValue) {
            return;
        }

        this.loaderService.startLoading();

        this.apiService
            .createMultiplier({
                clientContractId: this.contract.id,
                value: this.multiplierValue,
                color: this.multiplierColor,
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (item: IMultiplier) => {
                    this.multipliers.push(item);
                    this.cancelMultiplier();

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    };

    public saveMultipliers: () => void = () => {
        const { id } = this.busTypes[this.selectedItem];

        this.loaderService.startLoading();

        const newMultipliers = Object.keys(this.changedMultipliers).map(
            (date: string) => ({
                id: this.multiplierDateToMultiplierId[date],
                busTypeId: id,
                simpleContractMultiplierId: this.changedMultipliers[date],
                date,
                clientContractId: this.contract.id,
            }),
        );

        this.apiService
            .setSimpleContractRateMultiplier(newMultipliers)
            .pipe(
                switchMap(() =>
                    this.apiService.getSimpleContractRateMultipliers(
                        this.contract.id,
                        id,
                    ),
                ),
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (multipliers: IRateMultiplier[]) => {
                    this.processMultipliers(multipliers);
                    this.resetMultipliers();

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    };

    public updateDayRate(event: FocusEvent, rate: IBusTypeRateItem): void {
        const { value } = event.target as HTMLInputElement;

        this.updateRate(
            {
                id: rate.id,
                clientContractId: this.contract.id,
                busTypeId: this.busTypes[this.selectedItem].id,
                price: +value,
            },
            this.apiService.setSimpleContractBusTypeDayRate.bind(
                this.apiService,
            ),
            this.dayRate[0],
        );
    }

    public updateHourRate(event: FocusEvent, rate: IBusTypeRateItem): void {
        const { value } = event.target as HTMLInputElement;

        this.updateRate(
            [
                {
                    id: rate.id,
                    clientContractId: this.contract.id,
                    from: rate.from,
                    busTypeId: this.busTypes[this.selectedItem].id,
                    price: +value,
                },
            ],
            this.apiService.setSimpleContractBusTypeHourRates.bind(
                this.apiService,
            ),
            rate,
        );
    }

    public updateKilometerRate(
        event: FocusEvent,
        rate: IBusTypeRateItem,
    ): void {
        const { value } = event.target as HTMLInputElement;

        this.updateRate(
            [
                {
                    id: rate.id,
                    clientContractId: this.contract.id,
                    from: rate.from,
                    busTypeId: this.busTypes[this.selectedItem].id,
                    price: +value,
                },
            ],
            this.apiService.setSimpleContractBusTypeKilometerRates.bind(
                this.apiService,
            ),
            rate,
        );
    }

    public selectBusType(index: number): void {
        this.selectedItem = index;

        this.init();
    }

    public updateYear(year: number): void {
        if (this.selectedYear === year) {
            return;
        }

        this.selectedYear = year;

        const { name } = this.busTypes[this.selectedItem];

        this.loaderService.startLoading();

        this.apiService
            .getBusTypeByYear(name, year)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                map((busType: IFullBusType) =>
                    this.processBusTypeDetails(busType),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (rate: IFullBusType) => {
                    this.hourRates = rate.hourRates;
                    this.dayRate = rate.dayRate;
                    this.kilometerRates = rate.kilometerRates;
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    private processBusTypeDetails(busType: IFullBusType): IFullBusType {
        if (!busType || !busType.hourRates) {
            return {
                ...busType,
                kilometerRates: [],
                hourRates: [],
            };
        }

        busType.hourRates.sort((a: IBusTypeRateItem, b: IBusTypeRateItem) => {
            return a.from > b.from ? 1 : -1;
        });

        busType.hourRates.forEach((rate: IBusTypeRateItem) => {
            const fromRate = rate.from > 0 ? rate.from / 60 : 0;

            rate.displayHour = {
                from: `0${fromRate}`.slice(-2) + ":00",
                to: `0${fromRate}`.slice(-2) + ":59",
            };
        });

        return busType;
    }

    private updateRate(
        rateUpdate: IDayRateUpdate | IDayRateUpdate[],
        method: (rate: IDayRateUpdate | IDayRateUpdate[]) => Observable<number>,
        rate: IBusTypeRateItem,
    ): void {
        this.loaderService.startLoading();

        method(rateUpdate)
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                () => {
                    if (rate) {
                        rate.price = Array.isArray(rateUpdate)
                            ? rateUpdate[0].price
                            : rateUpdate.price;
                    } else {
                        this.dayRate.price = Array.isArray(rateUpdate)
                            ? rateUpdate[0].price
                            : rateUpdate.price;
                    }

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    private processMultipliers(multipliers: IRateMultiplier[]): void {
        this.multiplierDateToMultipliersObjId = multipliers.reduce(
            (obj: Record<string, number>, item: IRateMultiplier) => {
                return {
                    ...obj,
                    [item.date]: item.simpleContractMultiplier.id,
                };
            },
            {},
        );

        this.multiplierDateToMultiplierId = multipliers.reduce(
            (obj: Record<string, number>, item: IRateMultiplier) => ({
                ...obj,
                [item.date]: item.id,
            }),
            {},
        );
    }

    protected readonly EventHelper = EventHelper;
}
