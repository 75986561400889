<div *ngIf="allExistBusTypes.length" class="d-flex w-100">
    <div *ngFor="let seat of allExistBusTypes" class="seat-block">
        <div class="seat-name">
            {{ seat }}
        </div>
        <div>
            <ng-container *ngIf="rateSeatTypes && rateSeatTypes[seat]">
                <div
                    *ngFor="let item of rateSeatTypes[seat]"
                    class="bustype-item ge-form-group no-bottom-margin"
                >
                    <span class="margin-right-10">
                        {{ item.customBusType.name }}
                    </span>
                    <ge-editor-field autoOpen #priceField>
                        <span editorLabel> € {{ item.price | gePrice }} </span>
                        <input
                            class="ge-form-control no-arrow-btns"
                            type="number"
                            [(ngModel)]="item.price"
                            autocomplete="off"
                            (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            (blur)="
                                priceField.hideInput();
                                priceChange($event, item)
                            "
                            editorInput
                        />
                    </ge-editor-field>
                    <span
                        *ngIf="isDeleteAvailable"
                        class="remove-btn margin-left-15"
                        (click)="rateDelete.emit(item.id)"
                    >
                        <ge-svg icon="cross" size="12"></ge-svg>
                    </span>
                </div>
            </ng-container>
            <div
                *ngIf="availableBusTypesBySeat && availableBusTypesBySeat[seat]"
                class="bustype-item bustype-form ge-form-group no-bottom-margin"
            >
                <select
                    class="ge-form-control margin-right-5 bustype-select"
                    [value]="newRateBusType[seat]"
                    (change)="newRateBusType[seat] = +selectElement.value"
                    #selectElement
                >
                    <option
                        *ngFor="let item of availableBusTypesBySeat[seat]"
                        [value]="item.id"
                    >
                        {{ item.name }}
                    </option>
                </select>
                <input
                    class="ge-form-control no-arrow-btns"
                    type="number"
                    [(ngModel)]="newRatePrice[seat]"
                    autocomplete="off"
                    (wheel)="EventHelper.cancelOnWheelEvent($event)"
                />
                <button
                    class="margin-left-10 add-btn margin-right-5"
                    [ngClass]="{
                        disabled: !(
                            newRateBusType[seat] && newRatePrice[seat] > 0
                        ),
                    }"
                    (click)="onCreateRate(seat)"
                >
                    +
                </button>
            </div>
        </div>
    </div>
</div>
