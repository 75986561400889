<div>
    <span data-translate-me [translateMe]="'relations.contracts.from'">{{
        "relations.contracts.from" | i18n
    }}</span>
    <span class="date-input-span margin-left-10 margin-right-10">
        <ng-container *ngIf="startDate && !inputVisible; else startCalendar">
            <ge-editor-field #editorFieldFrom>
                <span class="font-bold" editorLabel>
                    {{ startDate | date: "d MMMM y" }}
                </span>
                <div class="range-datepicker" editorInput>
                    <ge-datepicker
                        [value]="datepickerStart"
                        [minDate]="datepickerMin"
                        [maxDate]="datepickerMax"
                        (dateChange)="
                            editorFieldFrom.hideInput(); startEdit($event)
                        "
                    ></ge-datepicker>
                </div>
            </ge-editor-field>
        </ng-container>

        <ng-template #startCalendar>
            <div class="range-datepicker">
                <ge-datepicker
                    [value]="datepickerStart"
                    [minDate]="datepickerMin"
                    [maxDate]="datepickerMax"
                    (dateChange)="startEdit($event)"
                ></ge-datepicker>
            </div>
        </ng-template>
    </span>
    <span data-translate-me [translateMe]="'relations.contracts.to'">{{
        "relations.contracts.to" | i18n
    }}</span>
    <span class="date-input-span margin-left-10 margin-right-10">
        <ng-container *ngIf="datepickerEnd && !inputVisible; else endCalendar">
            <ge-editor-field #editorFieldTo>
                <span class="font-bold" editorLabel>
                    {{ endDate | date: "d MMMM y" }}
                </span>
                <div class="range-datepicker" editorInput>
                    <ge-datepicker
                        [value]="datepickerEnd"
                        [minDate]="datepickerMin"
                        [maxDate]="datepickerMax"
                        (dateChange)="
                            editorFieldTo.hideInput(); endEdit($event)
                        "
                    ></ge-datepicker>
                </div>
            </ge-editor-field>
        </ng-container>
        <ng-template #endCalendar>
            <div class="range-datepicker">
                <ge-datepicker
                    [value]="datepickerEnd"
                    [minDate]="datepickerMin"
                    [maxDate]="datepickerMax"
                    (dateChange)="endEdit($event)"
                ></ge-datepicker>
            </div>
        </ng-template>
    </span>
</div>
