import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "filter",
})
export class FilterPipe implements PipeTransform {
    transform(drivers: any[], searchText: string): any[] {
        if (!drivers) {
            return [];
        }
        if (!searchText) {
            return drivers;
        }
        searchText = searchText.toLowerCase();
        return drivers.filter((driver) => {
            return (driver.firstName + driver.lastName)
                .toLowerCase()
                .includes(searchText);
        });
    }
}
