import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "../modal-window/modal-options.interface";
import { ModalWindowService } from "../modal-window/modal-window.service";
import { IPlanboardVehicleView } from "src/app/planning/buses/models/vehicle.model";
import { IDraggable } from "src/app/planning/trips/models/tripsHolder.model";

interface AssignWarningPopupData {
    currentBus: IPlanboardVehicleView;
    currentTripHolder: IDraggable;
}

@Component({
    selector: "ge-assign-warning-popup",
    templateUrl: "./assign-warning-popup.component.html",
    styles: [],
})
export class AssignWarningPopupComponent implements OnInit {
    modalWindowOptions: IModalWindowOptions = {
        title: "assignConfirmationPopup.capacityTitle",
        isNotCancellable: true,
        buttons: [
            {
                label: "changeGaragePopup.cancelButton",
                theme: "default",
                click: this.cancel.bind(this),
            },
        ],
    };

    constructor(private modalWindowService: ModalWindowService) {}

    data: AssignWarningPopupData;

    ngOnInit() {
        if (this.data.currentTripHolder.orderType !== "ShuttleLineOrder") {
            this.modalWindowOptions.buttons.push({
                label: "assignConfirmationPopup.divideAndAssign",
                click: this.onAssign.bind(this),
            });
        } else {
            this.modalWindowOptions.buttons.push({
                label: "assignConfirmationPopup.continue",
                click: this.onAssignLessCapacity.bind(this),
            });
        }
    }

    cancel() {
        this.modalWindowService.close(false);
    }

    onAssign() {
        this.modalWindowService.close({
            assignDivide: true,
        });
    }

    onAssignLessCapacity() {
        this.modalWindowService.close({
            assignLessCapacity: true,
        });
    }
}
