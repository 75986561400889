import { Component, EventEmitter, Input, Output } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { DestructibleController } from "../../../../../../common/classes/destructible.controller";
import { ToasterNotificationService } from "../../../../../../shared/components/toaster-notification/toaster-notification.service";
import { BusSeatType } from "../../contract-modal.constants";
import { ICustomBusType } from "../../contract-modal.models";
import { ContractApiService } from "../../services/contract-api.service";
import { ContractLoaderService } from "../../services/contract-loader.service";
import { EventHelper } from "../../../../../../shared/helpers/event-helper";

@Component({
    selector: "ge-new-bus-type",
    templateUrl: "./new-bus-type.component.html",
    styleUrl: "./new-bus-type.component.scss",
})
export class NewBusTypeComponent extends DestructibleController {
    @Input()
    public readonly contractId: number;

    @Output()
    public readonly bustypeCreate: EventEmitter<ICustomBusType> =
        new EventEmitter<ICustomBusType>();

    public availableSeatTypes: BusSeatType[] = Object.values(BusSeatType);

    public seatType: BusSeatType = this.availableSeatTypes[0];

    public formVisible = false;

    public busTypeName = "";

    public passengersFrom!: number;

    public passengersTo!: number;

    constructor(
        private apiService: ContractApiService,
        private loaderService: ContractLoaderService,
        private notificationService: ToasterNotificationService,
    ) {
        super();
    }

    public createBusType: () => void = () => {
        if (
            !this.busTypeName.length ||
            this.passengersFrom < 1 ||
            this.passengersTo < 1
        ) {
            return;
        }

        this.loaderService.startLoading();

        this.apiService
            .createCustomBusType(this.contractId, {
                name: this.busTypeName,
                seatingType: this.seatType,
                passengersCount: {
                    from: this.passengersFrom,
                    to: this.passengersTo,
                },
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (newBusType: ICustomBusType) => {
                    this.bustypeCreate.emit(newBusType);

                    this.notificationService.successToaster(
                        "relations.contracts.busTypeCreated",
                    );
                    this.toggleForm();
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    };

    public cancel: () => void = () => {
        this.resetChanges();
        this.toggleForm();
    };

    public toggleForm: () => void = () => {
        this.formVisible = !this.formVisible;

        if (!this.formVisible) {
            this.resetChanges();
        }
    };

    private resetChanges(): void {
        this.seatType = this.availableSeatTypes[0];
        this.busTypeName = "";
        this.passengersFrom = 0;
        this.passengersTo = 0;
    }

    protected readonly EventHelper = EventHelper;
}
