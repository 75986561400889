import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "../modal-window/modal-options.interface";
import { ModalWindowService } from "../modal-window/modal-window.service";
import { IPlanboardVehicleView } from "../../../planning/buses/models/vehicle.model";
import { IVehicleSeatArrangement } from "../../../planning/buses/models/vehicle-seat-arrangement";

@Component({
    selector: "ge-change-bus-arrangement-confirmation-popup",
    templateUrl: "./change-bus-arrangement-confirmation-popup.component.html",
    styleUrl: "./change-bus-arrangement-confirmation-popup.component.scss",
})
export class ChangeBusArrangementConfirmationPopupComponent implements OnInit {
    data: {
        vehicle: IPlanboardVehicleView;
        seatingArrangement: IVehicleSeatArrangement;
    };

    modalWindowOptions: IModalWindowOptions = {
        title: "changeBusArrangementConfirmationPopup.title",
        isNotCancellable: true,
        buttons: [
            {
                label: "changeBusArrangementConfirmationPopup.okBtn",
                click: this.close.bind(this),
            },
        ],
    };

    busNumber: number;
    newArrangementName: string;

    constructor(private modalWindowService: ModalWindowService) {}

    ngOnInit() {
        this.busNumber = this.data.vehicle.number;
        this.newArrangementName =
            this.data.seatingArrangement && this.data.seatingArrangement.name;
    }

    close() {
        this.modalWindowService.close();
    }
}
