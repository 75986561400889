<div
    class="contract-item"
    [ngClass]="{
        selected: selectedContract?.id === contract.id,
        active: contract.isActive,
        disabled: contract.contractType?.type === types.Base,
        'edit-mode': editMode,
    }"
    (click)="
        contract.contractType.type !== types.Base &&
            selectContract.emit(contract)
    "
>
    <span
        class="contact-name"
        [title]="
            contract.contractType.type !== types.Base &&
            contract.contractType.type !== types.Simple
                ? ('tripsLookup.from' | i18n) +
                  ': ' +
                  (contract.activeFrom | date: 'd MMM y') +
                  '\n' +
                  ('tripsLookup.to' | i18n) +
                  ': ' +
                  (contract.activeTo | date: 'd MMM y')
                : ''
        "
    >
        {{
            (contract.name ? contract.name : contract.contractType?.type)
                | shortName: 35
        }}
        {{ contract.name ? "" : contract.id }}
    </span>
    <div class="submenu-container" (click)="$event.stopPropagation()">
        <ge-dropdown class="submenu-dropdown">
            <div class="submenu" dropdownLabel>
                <ge-svg icon="three-dots-menu"></ge-svg>
            </div>
            <div dropdownList class="dropdown-container">
                <ge-dropdown-item
                    (click)="editMode = true"
                    data-translate-me
                    [translateMe]="'relations.contracts.editDate'"
                >
                    {{ "relations.contracts.editDate" | i18n }}
                </ge-dropdown-item>

                <ge-dropdown-item
                    *ngIf="!contract.isActive"
                    (click)="updateContractState.emit(true)"
                    data-translate-me
                    [translateMe]="'relations.contracts.enable'"
                >
                    {{ "relations.contracts.enable" | i18n }}
                </ge-dropdown-item>

                <ge-dropdown-item
                    *ngIf="contract.isActive"
                    (click)="updateContractState.emit(false)"
                    data-translate-me
                    [translateMe]="'relations.contracts.disable'"
                >
                    {{ "relations.contracts.disable" | i18n }}
                </ge-dropdown-item>
                <ge-dropdown-item
                    *ngIf="contract.isActive"
                    (click)="showRenameBlock()"
                >
                    {{ "relations.contracts.rename" | i18n }}
                </ge-dropdown-item>
            </div>
        </ge-dropdown>
    </div>
    <div
        *ngIf="editMode"
        class="margin-top-15"
        (click)="$event.stopPropagation()"
    >
        <ge-contract-range-inputs
            [startDate]="contract.activeFrom"
            [endDate]="contract.activeTo"
            [okLabel]="'relations.contracts.update' | i18n"
            data-translate-me
            [translateMe]="'relations.contracts.update'"
            (save)="updatePeriod($event)"
            (reset)="editMode = false"
        ></ge-contract-range-inputs>
    </div>
    <div
        *ngIf="isRename"
        class="indent-input"
        (click)="$event.stopPropagation()"
    >
        <div>
            <ge-input
                class="margin-top-small"
                type="text"
                [tabindex]="1"
                [(ngModel)]="renameContract"
                (change)="changeNameContract = true"
            >
            </ge-input>
            <ng-container>
                <ge-button
                    theme="default"
                    [click]="saveRenameContract.bind(this, contract)"
                    class="margin-right-5"
                >
                    <ge-svg icon="check" size="13"></ge-svg>
                </ge-button>

                <ge-button
                    theme="default"
                    [click]="cancelRenameBlock.bind(this)"
                >
                    <ge-svg icon="cross" size="13"></ge-svg>
                </ge-button>
            </ng-container>
        </div>
    </div>
</div>
