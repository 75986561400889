import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";

import { TopNavbarComponent } from "./top-navbar/top-navbar.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { SvgModule } from "../common/components/svg/svg.module";
import { ChatModule } from "../Chat/chat.module";
import { PlanningModule } from "../planning/planning.module";
import { InvoicesModule } from "../invoices/invoices.module";
import { LayoutService } from "./layout.service";

@NgModule({
    declarations: [LayoutComponent, NavigationComponent, TopNavbarComponent],
    imports: [
        ChatModule,
        CommonModule,
        InvoicesModule,
        PlanningModule,
        RouterModule,
        SharedModule,
        SvgModule,
    ],
    providers: [LayoutService],
})
export class LayoutModule {}
