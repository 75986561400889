import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MainRouting } from "src/app/main-routing.enum";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class MaintenseService {
    constructor(private router: Router) {}

    public proceed() {
        if (this.allowGoIn()) {
            return true;
        } else {
            this.router.navigate([MainRouting.Maintense]).then(() => {
                // do nothing
            });
            return false;
        }
    }

    isMaintenseActive(): boolean {
        return environment.appConfig.maintanse.maintense_active;
    }

    isInWhiteList(): boolean {
        return environment.appConfig.maintanse.white_list.includes(
            localStorage.getItem("token"),
        );
    }

    allowGoIn(): boolean {
        if (!this.isMaintenseActive()) {
            return true;
        } else {
            return this.isInWhiteList();
        }
    }
}
