import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OfferConfirmationService } from "./offer-confirmation.service";
import { finalize } from "rxjs/operators";

@Component({
    selector: "ge-offer-confirmation-page",
    templateUrl: "./offer-confirmation-page.component.html",
    styleUrl: "./offer-confirmation-page.component.scss",
})
export class OfferConfirmationPageComponent implements OnInit {
    loading = false;
    isVisibleErrorMsg = false;
    isVisibleNotNeededMsg = false;
    isVisibleSuccessMsg = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private offerConfirmationService: OfferConfirmationService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params.token) {
                this.loading = true;

                this.offerConfirmationService
                    .confirm(params.token)
                    .pipe(
                        finalize(() => {
                            this.loading = false;
                        }),
                    )
                    .subscribe(
                        (res) => {
                            res.data.confirmOfferByEmail != null
                                ? (this.isVisibleSuccessMsg = true)
                                : (this.isVisibleNotNeededMsg = true);
                        },
                        (error) => {
                            this.isVisibleErrorMsg = true;
                        },
                    );
            } else {
                this.redirectToNotFoundPage();
            }
        });
    }

    private redirectToNotFoundPage() {
        this.router.navigateByUrl("not-found");
    }
}
