<nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse" *ngIf="!isMenuCollapsed">
        <div class="navbar-header">
            <img
                alt="Gebotours"
                src="assets/imgs/logo.png"
                class="img-navigation"
            />
        </div>
        <ul class="nav metismenu" id="side-menu">
            <li
                *ngFor="let navigationLink of navigationLinks"
                [ngClass]="{ active: navigationLink.isActive }"
                class="nav-item d-flex"
                [routerLink]="navigationLink.action"
                [ngStyle]="{
                    'pointer-events': isLinkBlocked(navigationLink.action)
                        ? 'none'
                        : 'all',
                }"
                (click)="isLinkActive(navigationLink.title)"
            >
                <ge-svg [icon]="navigationLink.icon" size="24"></ge-svg>
                <span data-translate-me [translateMe]="navigationLink.title">{{
                    navigationLink.title | i18n
                }}</span>
            </li>
            <li
                class="nav-item d-flex align-items-center d-block"
                (click)="logout()"
            >
                <ge-svg icon="logOutNew" size="24"></ge-svg>
                <span data-translate-me [translateMe]="'navigation.logout'">{{
                    "navigation.logout" | i18n
                }}</span>
            </li>
        </ul>
    </div>
    <div class="sidebar-collapse" *ngIf="isMenuCollapsed">
        <div class="navbar-header">
            <img
                alt="Gebotours"
                src="assets/imgs/logo.png"
                class="img-navigation img-navigation-small"
            />
        </div>
        <ul class="nav metismenu" id="side-menu">
            <li
                *ngFor="let navigationLink of navigationLinks"
                [ngClass]="{ active: navigationLink.isActive }"
                (click)="isLinkActive(navigationLink.title)"
                [ngStyle]="{
                    'pointer-events': isLinkBlocked(navigationLink.action)
                        ? 'none'
                        : 'all',
                }"
                class="nav-item d-flex align-items-center d-block"
                [routerLink]="navigationLink.action"
            >
                <ge-svg [icon]="navigationLink.icon" size="24"></ge-svg>
            </li>
            <li
                class="nav-item d-flex align-items-center d-block"
                (click)="logout()"
            >
                <ge-svg icon="logOutNew" size="24"></ge-svg>
            </li>
        </ul>
    </div>
    <div>
        <div class="menu-toogle-language">
            <a (click)="changeLanguage()">
                <ge-svg icon="{{ nextLanguageImg }}" size="24"> </ge-svg>
            </a>
        </div>
        <ge-svg
            class="menu-toggle-icons pointer"
            *ngIf="isMenuCollapsed"
            icon="menuOpen"
            size="24"
            (click)="toggleSidebarMenu()"
        >
        </ge-svg>
        <ge-svg
            class="menu-toggle-icons pointer"
            *ngIf="!isMenuCollapsed"
            icon="menuClose"
            size="24"
            (click)="toggleSidebarMenu()"
        >
        </ge-svg>
    </div>
</nav>
