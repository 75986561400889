import {
    Component,
    forwardRef,
    Input,
    ElementRef,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import {
    NG_VALUE_ACCESSOR,
    FormGroupDirective,
    ControlValueAccessor,
} from "@angular/forms";

@Component({
    selector: "ge-radio-button-select",
    templateUrl: "./radio-button-select.component.html",
    styleUrl: "./radio-button-select.component.scss",
    providers: [
        FormGroupDirective,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonSelectComponent),
            multi: true,
        },
    ],
})
export class RadioButtonSelectComponent
    implements ControlValueAccessor, OnChanges
{
    @Input() public data: any[]; // list of options
    @Input() public valueKey: string; // field key to be used as value of control
    @Input() public labelKey: string; // field key to be used as label of control
    @Input() public placeholder = "Not selected";
    @Input() public radioName = "radioName";
    @Input() public translationRef = "";
    @Input() public disabled = false;

    public isCollapsed = true;
    public value: string | number;
    public selectedItem: any;
    private touched = false;

    constructor(private el: ElementRef) {}

    public writeValue(value: string | number): void {
        this.value = value;

        if (this.data) {
            this.selectedItem = this.data.find((item) => {
                return this.getItemValue(item) === this.value;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { data } = changes;

        if (data) {
            this.writeValue(this.value);
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {}

    public onChange(value: string | number) {
        this.value = value;
    }

    public onSelect(item: any) {
        this.selectedItem = item;
        this.value = this.getItemValue(item);
        this.onChange(this.value);
    }

    public onTouched: any = () => {};

    public onBtnClick(): void {
        this.isCollapsed = !this.isCollapsed;
        this.touched = true;
    }

    public onClickedOutside(event: { target: HTMLElement }): void {
        if (this.el.nativeElement.contains(event.target)) {
            return;
        }
        this.isCollapsed = true;

        if (this.touched) {
            this.onTouched();
        }
    }

    public isSelected(item: string): boolean {
        const selected = this.value === this.getItemValue(item);

        if (selected) {
            this.selectedItem = item;
        }

        return selected;
    }

    public get isValue(): boolean {
        return (
            this.value !== null &&
            typeof this.value !== "undefined" &&
            !!`${this.value}`.trim()
        );
    }
    public get isSelectedItem(): boolean {
        return (
            this.selectedItem !== null &&
            typeof this.selectedItem !== "undefined" &&
            !!`${this.selectedItem}`.trim()
        );
    }

    public getItemValue(item): number | string {
        return this.valueKey ? item[this.valueKey] : item;
    }

    public getItemLabel(item): number | string {
        return this.labelKey ? item[this.labelKey] : item;
    }

    public get i18nPrefix(): string {
        return this.translationRef ? `${this.translationRef}.` : "";
    }
}
