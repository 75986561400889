import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { ContractType } from "../../../../../../../shared/models/contracts.models";
import {
    IContractBusTypeSummary,
    ICustomBusType,
    IRatePeriod,
    IViabusPrice,
    IViabusDetails,
    IViabusFullPrice,
    IBaseContract,
} from "../../../contract-modal.models";
import { ContractDetailsBaseController } from "../contract-details-base.controller";
import { ICreateViabusRate } from "./viabus-details/viabus-details.component";
import { EventHelper } from "../../../../../../../shared/helpers/event-helper";
@Component({
    selector: "ge-viabus-contract",
    templateUrl: "./viabus-contract.component.html",
    styleUrl: "./viabus-contract.component.scss",
})
export class ViabusContractComponent
    extends ContractDetailsBaseController<IViabusDetails>
    implements OnChanges
{
    @Input()
    public readonly details!: IViabusDetails;

    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    public periods!: IRatePeriod[];

    public selectedPeriodIndex = 0;

    public rateDetails!: IViabusPrice[];

    public type: ContractType = ContractType.Viabus;

    public newSecondDriver!: number;

    public collectionReturn: string;

    public availableBusTypes!: IContractBusTypeSummary[];

    public ngOnChanges(changes: SimpleChanges): void {
        const { details, customBusTypes } = changes;
        if (details && this.details) {
            this.periods = this.details.prices.map(
                (item: IViabusFullPrice) => item.ratesPeriod,
            );
            this.rateDetails = this.details.prices[this.selectedPeriodIndex]
                ? this.details.prices[this.selectedPeriodIndex].prices
                : [];

            this.newSecondDriver = this.details.secondDriverRate;
            this.collectionReturn =
                this.replaceComaService.replaceNumberToString(
                    this.details.collectionAndReturnPrice,
                );
        }

        if (customBusTypes && this.customBusTypes) {
            this.availableBusTypes = [...this.customBusTypes];
        }
    }

    public onPeriodSelect(index: number): void {
        this.selectedPeriodIndex = index;

        this.rateDetails = this.details.prices[index]
            ? this.details.prices[index].prices
            : [];
    }

    public setNewPeriods(periods: IRatePeriod[]): void {
        this.periods = this.periods.concat(periods);
    }

    public updateDetails(prices: IViabusPrice[]): void {
        this.loaderService.startLoading();

        this.apiService
            .updateContractDetails<IViabusDetails>(this.type, {
                prices: {
                    prices: prices.map((item: IViabusPrice) => ({
                        ...item,
                        customBusType: {
                            ...item.customBusType,
                            seatingType: item.customBusType.seatingType.id,
                        },
                        ratesPeriod: this.periods[this.selectedPeriodIndex],
                    })),
                    seconDriverRate: this.details.secondDriverRate,
                    collectionAndReturnPrice:
                        this.replaceComaService.replaceStringToNumber(
                            this.collectionReturn,
                        ),
                },
                clientContractId: this.contract.id,
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (data: IViabusDetails) => {
                    this.contractUpdate.emit(data);

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    public createNewBusType(newBusType: ICustomBusType): void {
        this.availableBusTypes = [
            ...this.customBusTypes,
            newBusType as IContractBusTypeSummary,
        ];
    }

    public contractUpdateSecondDriverAndCollectionReturn: () => void = () => {
        this.setUpdateRequest({
            prices: this.rateDetails.map((item: IViabusPrice) => ({
                ...item,
                customBusType: {
                    ...item.customBusType,
                    seatingType: item.customBusType.seatingType.id,
                },
                ratesPeriod: this.periods[this.selectedPeriodIndex],
            })),
            seconDriverRate: this.newSecondDriver,
            collectionAndReturnPrice:
                this.replaceComaService.replaceStringToNumber(
                    this.collectionReturn,
                ),
        });
    };

    public createRate(rate: ICreateViabusRate): void {
        const prices = this.rateDetails.map((item: IViabusPrice) => ({
            ...item,
            customBusType: {
                ...item.customBusType,
                seatingType: item.customBusType.seatingType.id.toString(),
            },
            ratesPeriod: this.periods[this.selectedPeriodIndex],
        }));

        const busType = this.availableBusTypes.find(
            (item: IContractBusTypeSummary) => {
                return item.id === rate.customBusType;
            },
        );

        if (!busType) {
            return;
        }

        const newRate = {
            price: rate.price,
            ratesPeriod: this.periods[this.selectedPeriodIndex],
            customBusType: {
                ...busType,
                seatingType: busType.seatingType.id.toString(),
            },
        };

        this.setUpdateRequest({
            prices: [...prices, newRate],
            seconDriverRate: this.details.secondDriverRate,
        });
    }

    private setUpdateRequest(prices: any & object): void {
        this.loaderService.startLoading();

        this.apiService
            .updateContractDetails<IViabusDetails>(this.type, {
                prices,
                clientContractId: this.contract.id,
            })
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (data: IViabusDetails) => {
                    this.contractUpdate.emit(data);

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }

    protected readonly EventHelper = EventHelper;
}
