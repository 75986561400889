import { Component, OnInit } from "@angular/core";
import { I18nService } from "../../shared/i18n/i18n.service";
import { AuthService } from "../../auth/authServices/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: "ge-top-navbar",
    templateUrl: "./top-navbar.component.html",
    styleUrl: "./top-navbar.component.scss",
})
export class TopNavbarComponent implements OnInit {
    private currentLanguage = this._i18nService.currentLanguageSubject;
    public nextLanguage = "EN";

    constructor(
        private _i18nService: I18nService,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit() {}

    changeLanguage(): void {
        const language = this.currentLanguage.value === "en" ? "nl" : "en";
        this.nextLanguage = this.currentLanguage.value === "en" ? "EN" : "NL";
        this._i18nService.setCurrentLanguage(language);
    }

    logout(): void {
        this.authService.logout();

        this.router.navigate(["login"]);
    }
}
