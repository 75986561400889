<div
    class="page-layout-container"
    [ngClass]="{
        'menu-collapsed': isMenuCollapsed,
        'without-menu': userCompany === 'Sunweb',
    }"
>
    <div class="header"></div>
    <div class="menu" *ngIf="userCompany !== 'Sunweb'">
        <ge-navigation></ge-navigation>
    </div>
    <div class="content gray-bg">
        <router-outlet></router-outlet>
        <!--        <ge-modal-button></ge-modal-button>-->
    </div>
    <ge-loader [loading]="isLoading"></ge-loader>
</div>
