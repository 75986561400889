import { Component, HostListener } from "@angular/core";
import { MouseEventsService } from "./shared/services/mouse-events.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss",
})
export class AppComponent {
    constructor(private mouseEventsService: MouseEventsService) {}

    @HostListener("document:mousemove", ["$event"])
    onMouseClick(e: { x: number; y: number }) {
        this.mouseEventsService.setMouseCoordinates(e);
    }
}
