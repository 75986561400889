import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";

import {
    ContractType,
    nonDeleteBusTypesContracts,
} from "../../../../../../../../shared/models/contracts.models";
import {
    IContractBusTypeSummary,
    IDrentVanGoghRate,
} from "../../../../contract-modal.models";
import { EventHelper } from "../../../../../../../../shared/helpers/event-helper";

@Component({
    selector: "ge-rate-table",
    styleUrl: "./rate-table.component.scss",
    templateUrl: "./rate-table.component.html",
})
export class RateTableComponent implements OnChanges {
    @Input()
    public readonly tableRows: IDrentVanGoghRate[] = [];

    @Input()
    public readonly customBusTypes: IContractBusTypeSummary[] = [];

    @Input()
    public readonly type!: ContractType;

    @Output()
    public readonly rowsUpdate: EventEmitter<IDrentVanGoghRate[]> =
        new EventEmitter<IDrentVanGoghRate[]>();

    @Output()
    public readonly createRate: EventEmitter<IDrentVanGoghRate> =
        new EventEmitter<IDrentVanGoghRate>();

    @Output()
    public readonly deleteRate: EventEmitter<number> =
        new EventEmitter<number>();

    @ViewChild("busTypeSelect", {
        static: false,
    })
    public readonly busTypeSelect!: ElementRef<HTMLSelectElement>;

    public availableBusTypes!: IContractBusTypeSummary[];

    public VincentVanGoghType: ContractType = ContractType.CSVincentVanGogh;

    public newCustomBusType!: number | null;

    public waitingTimePrice!: number | null;

    public minRate!: number | null;

    public perPriceHour!: number | null;

    public preKilometer!: number | null;

    public get isDeleteAvailable(): boolean {
        return nonDeleteBusTypesContracts.indexOf(this.type) < 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { tableRows, customBusTypes } = changes;

        if (tableRows && this.tableRows) {
            this.minRate = null;
            this.newCustomBusType = null;
            this.waitingTimePrice = null;
            this.perPriceHour = null;
            this.preKilometer = null;

            if (this.busTypeSelect && this.busTypeSelect.nativeElement) {
                this.busTypeSelect.nativeElement.value = "";
            }
        }

        if (
            (customBusTypes || tableRows) &&
            this.tableRows &&
            this.customBusTypes
        ) {
            const busTypeIds = this.tableRows.map(
                (item: IDrentVanGoghRate) => item.customBusType.id,
            );

            this.availableBusTypes = this.customBusTypes.filter(
                (item: IContractBusTypeSummary) => {
                    return !busTypeIds.some((id: number) => item.id === id);
                },
            );
        }
    }

    public updateField(
        event: Event,
        index: number,
        fieldName: keyof IDrentVanGoghRate,
    ): void {
        const { value } = event.target as HTMLInputElement;
        const newRows = [...this.tableRows];
        const newField = {
            ...this.tableRows[index],
            [fieldName]: parseFloat(value),
        };

        newRows.splice(index, 1, newField);

        this.rowsUpdate.emit(newRows);
    }

    public addRate(): void {
        if (
            !(
                this.newCustomBusType >= 0 &&
                this.preKilometer >= 0 &&
                this.perPriceHour >= 0 &&
                this.minRate >= 0 &&
                (this.VincentVanGoghType !== this.type ||
                    this.waitingTimePrice >= 0)
            )
        ) {
            return;
        }

        const { name } = this.availableBusTypes.find(
            (item: IContractBusTypeSummary) => {
                return item.id === +this.newCustomBusType;
            },
        );

        this.createRate.emit({
            kilometerRate: this.preKilometer,
            minimumOrderRate: this.minRate,
            waitingTimeHourRate: this.waitingTimePrice,
            hourRate: this.perPriceHour,
            customBusType: {
                id: +this.newCustomBusType,
                name,
            },
        });
    }

    protected readonly EventHelper = EventHelper;
}
