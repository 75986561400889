/* src/app/relations/clients/modals/contract-modal/components/contract-list/contrat-range-inputs/contrat-range-inputs.component.scss */
.data-input-block {
  display: flex;
  align-items: center;
}
.data-input-block span {
  width: 35%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.data-input-block .data-input {
  width: 65%;
}
/*# sourceMappingURL=contrat-range-inputs.component.css.map */
