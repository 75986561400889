<div
    class="driverWrapper"
    [id]="driver.chatId"
    [class.selected]="checked"
    (click)="selectCurrent.emit(driver)"
>
    <div class="avatarContainer">
        <div class="avatar">a</div>
        <span
            *ngIf="driver.unreadMessages"
            title="Amout of new messages"
            class="unreadMessages"
        >
            {{ driver.unreadMessages }}
        </span>
    </div>

    <h4 class="driverName">{{ driver.firstName }} {{ driver.lastName }}</h4>

    <div class="driverCheckbox" (click)="$event.stopPropagation()">
        <ge-checkbox
            maxWidth="150px"
            [checkboxValue]="checked"
            (checkboxToggle)="check.emit($event)"
        ></ge-checkbox>
    </div>
</div>
