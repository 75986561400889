/* src/app/Chat/messages/messages.component.scss */
.messageContainer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}
.messageContainerLeft {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
.dateGroupLineLeft {
  width: 201px;
  height: 1px;
  background-color: #eceef2;
  margin-right: 12px;
}
.dateGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.dateGroupLineRight {
  width: 201px;
  height: 1px;
  background-color: #eceef2;
  margin-left: 12px;
}
.dateGroupDate {
  width: 119px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #0b162d;
}
.messageHeader {
  margin-left: auto;
  height: 40px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.messageHeaderLeft {
  margin-right: auto;
  height: 40px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #828282;
  margin-bottom: 0;
  padding-left: 1px;
  font-size: 22px;
}
.time {
  margin-right: 12px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #828282;
  align-self: center;
  margin-bottom: 0;
}
.timeLeft {
  margin-left: 12px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #828282;
  align-self: center;
  margin-bottom: 0;
}
.name {
  margin-right: 17px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b162d;
  margin-bottom: 0;
}
.nameLeft {
  margin-left: 17px;
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b162d;
  margin-bottom: 0;
}
.textWrapper {
  margin-left: auto;
  width: 404px;
  height: auto;
  font-size: 14px;
  line-height: 1.43;
  color: #0b162d;
  background-color: #fceaee;
  margin-top: 17px;
  overflow: hidden;
}
.textWrapperLeft {
  margin-right: auto;
  width: 404px;
  height: auto;
  font-size: 14px;
  line-height: 1.43;
  color: #0b162d;
  background-color: #f7f8fa;
  margin-top: 17px;
  overflow: hidden;
}
.text {
  width: 404px;
  height: auto;
  margin: 0;
  padding: 8px;
  border: none;
  word-wrap: break-word;
}
.avatarImg {
  width: 100%;
  height: 100%;
  text-align: center;
}
/*# sourceMappingURL=messages.component.css.map */
