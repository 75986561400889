<div
    class="table-vertical-middle width-100 ge-form-group"
    [ngClass]="{ 'overflow-table': countries?.length > 5 }"
>
    <div *ngIf="!countries?.length" class="d-flex align-items-center">
        <span
            class="margin-right-20"
            data-translate-me
            [translateMe]="'relations.contracts.firstCountry'"
            >{{ "relations.contracts.firstCountry" | i18n }}</span
        >
        <input
            class="ge-form-control country-input"
            autocomplete="off"
            style="width: 240px"
            type="text"
            (blur)="createCountry(firstCountryInput.value)"
            #firstCountryInput
        />
    </div>
    <table class="table" *ngIf="countries?.length">
        <thead>
            <tr class="header-row">
                <td></td>
                <ng-container
                    *ngFor="let country of countries; let index = index"
                >
                    <th
                        class="left-border country-header"
                        colspan="2"
                        scope="colgroup"
                    >
                        {{ country.name }}
                    </th>
                    <th>
                        <button
                            *ngIf="
                                countries.length - 1 === index &&
                                !showCountryInput
                            "
                            class="add-btn"
                            (click)="addCountryInput()"
                        >
                            +
                        </button>
                    </th>
                </ng-container>
                <th *ngIf="showCountryInput">
                    <input
                        class="ge-form-control country-input"
                        type="text"
                        (blur)="createCountry(countryInput.value)"
                        autocomplete="off"
                        #countryInput
                    />
                </th>
            </tr>
            <tr class="header-row">
                <th
                    class="no-borders"
                    data-translate-me
                    [translateMe]="'relations.contracts.busType'"
                >
                    {{ "relations.contracts.busType" | i18n }}
                </th>
                <ng-container *ngFor="let country of countries">
                    <th
                        class="left-border"
                        data-translate-me
                        [translateMe]="'relations.contracts.fridayPrice'"
                    >
                        {{ "relations.contracts.fridayPrice" | i18n }}
                    </th>
                    <th
                        colspan="2"
                        data-translate-me
                        [translateMe]="'relations.contracts.MidweekPrice'"
                    >
                        {{ "relations.contracts.MidweekPrice" | i18n }}
                    </th>
                </ng-container>
                <th *ngIf="showCountryInput"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rows" class="table-row">
                <td>
                    {{ row.customBusType.name }}
                </td>
                <ng-container
                    *ngFor="let country of countries; let index = index"
                >
                    <td class="left-border price-cell">
                        <ge-editor-field autoOpen #fridayPrice>
                            <div editorLabel>
                                <ng-container
                                    *ngIf="
                                        row.countries[country.name]
                                            ?.fridayPrice as fridayPriceValue;
                                        else dash
                                    "
                                >
                                    € {{ fridayPriceValue | gePrice }}
                                </ng-container>
                            </div>
                            <input
                                class="ge-form-control no-arrow-btns"
                                type="number"
                                autocomplete="off"
                                [value]="
                                    row.countries[country.name]?.fridayPrice
                                "
                                (blur)="
                                    fridayPrice.hideInput();
                                    changePrice(
                                        $event,
                                        'fridayPrice',
                                        row,
                                        country.name,
                                        country.iso
                                    )
                                "
                                editorInput
                                (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            />
                        </ge-editor-field>
                    </td>
                    <td class="price-cell">
                        <ge-editor-field autoOpen #midweekPrice>
                            <div editorLabel>
                                <ng-container
                                    *ngIf="
                                        row.countries[country.name]
                                            ?.midweekPrice as midweekPriceValue;
                                        else dash
                                    "
                                >
                                    € {{ midweekPriceValue | gePrice }}
                                </ng-container>
                            </div>
                            <input
                                class="ge-form-control no-arrow-btns"
                                type="number"
                                autocomplete="off"
                                [value]="
                                    row.countries[country.name]?.midweekPrice
                                "
                                (blur)="
                                    midweekPrice.hideInput();
                                    changePrice(
                                        $event,
                                        'midweekPrice',
                                        row,
                                        country.name,
                                        country.iso
                                    )
                                "
                                editorInput
                                (wheel)="EventHelper.cancelOnWheelEvent($event)"
                            />
                        </ge-editor-field>
                    </td>
                    <td class="text-center remove-cell">
                        <span
                            *ngIf="
                                isDeleteAvailable && row.countries[country.name]
                            "
                            (click)="
                                removePrice(row.countries[country.name]?.id)
                            "
                        >
                            <ge-svg icon="trash"></ge-svg>
                        </span>
                    </td>
                </ng-container>
                <td *ngIf="showCountryInput"></td>
            </tr>
            <tr *ngIf="availableTypes?.length">
                <td>
                    <select
                        class="ge-form-control width_90"
                        [(ngModel)]="newBusTypeId"
                        #busTypeSelect
                    >
                        <option
                            class="width-100"
                            *ngFor="let item of availableTypes"
                            [value]="item.id"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </td>
                <ng-container *ngIf="newBusTypeId">
                    <ng-container
                        *ngFor="let country of countries; let index = index"
                    >
                        <td class="left-border price-cell">
                            <ge-editor-field autoOpen #fridayPrice>
                                <div editorLabel>
                                    <span>-</span>
                                </div>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    (blur)="
                                        fridayPrice.hideInput();
                                        changePrice(
                                            $event,
                                            'fridayPrice',
                                            null,
                                            country.name,
                                            country.iso
                                        )
                                    "
                                    editorInput
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                />
                            </ge-editor-field>
                        </td>
                        <td class="price-cell">
                            <ge-editor-field autoOpen #midweekPrice>
                                <div editorLabel>
                                    <span>-</span>
                                </div>
                                <input
                                    class="ge-form-control no-arrow-btns"
                                    type="number"
                                    (blur)="
                                        midweekPrice.hideInput();
                                        changePrice(
                                            $event,
                                            'midweekPrice',
                                            null,
                                            country.name,
                                            country.iso
                                        )
                                    "
                                    editorInput
                                    (wheel)="
                                        EventHelper.cancelOnWheelEvent($event)
                                    "
                                />
                            </ge-editor-field>
                        </td>
                        <td class="text-center remove-cell"></td>
                    </ng-container>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>

<ng-template #dash>
    <span>-</span>
</ng-template>
