<div class="period-list">
    <div
        *ngFor="let period of periods; let index = index"
        class="period-item"
        [ngClass]="{ selected: period === selectedPeriod }"
        (click)="selectPeriod(index)"
    >
        <ge-period-input
            [startDate]="period.from"
            [endDate]="period.to"
            [minDate]="contract.activeFrom"
            [maxDate]="contract.activeTo"
            (startDateUpdate)="updateRatePeriod(index, { from: $event })"
            (endDateUpdate)="updateRatePeriod(index, { to: $event })"
        ></ge-period-input>
    </div>

    <ng-container *ngIf="newPeriodVisible">
        <div class="d-flex">
            <ge-period-input
                [minDate]="contract.activeFrom"
                [maxDate]="contract.activeTo"
                (startDateUpdate)="newPeriodStart = $event"
                (endDateUpdate)="newPeriodEnd = $event"
            ></ge-period-input>
            <ge-button theme="cranberry" [click]="createRatePeriods">
                <span
                    class="margin-right-5"
                    data-translate-me
                    [translateMe]="'relations.contracts.create'"
                    >{{ "relations.contracts.create" | i18n }}</span
                >
            </ge-button>
        </div>
    </ng-container>

    <ng-container *ngIf="!newPeriodVisible">
        <ge-button theme="cranberry" [click]="addPeriod">
            <span
                class="margin-right-5"
                data-translate-me
                [translateMe]="'relations.contracts.addPeriod'"
                >{{ "relations.contracts.addPeriod" | i18n }}</span
            >
            <span class="font-16">+</span>
        </ge-button>
    </ng-container>
</div>
