import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { VehiclesService } from "../../vehicles.service";
import { Vehicle } from "../../models/vehicle";
import { ModalWindowService } from "../../../../shared/components/modal-window/modal-window.service";

@Component({
    selector: "ge-delete-bus-modal",
    templateUrl: "./delete-bus-modal.component.html",
    styleUrl: "./delete-bus-modal.component.scss",
})
export class DeleteBusModalComponent implements OnInit {
    data: any = {};
    bus: Vehicle;
    variables = {};

    modalWindowOptions: IModalWindowOptions = {
        title: "busListPage.deleteBus.title",
        buttons: [
            {
                label: "busListPage.deleteBus.deleteBtn",
                click: this.delete.bind(this),
            },
        ],
    };

    constructor(
        private bussesService: VehiclesService,
        private modalWindowService: ModalWindowService,
    ) {}

    ngOnInit() {
        this.bus = this.data.bus;
        this.variables = { brand: this.bus.brand };
    }

    delete() {
        this.bussesService.delete(this.bus.id).subscribe(() => {
            this.modalWindowService.close();
        });
    }
}
