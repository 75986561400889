import { Component, OnInit } from "@angular/core";
import { IModalWindowOptions } from "src/app/shared/components/modal-window/modal-options.interface";
import { ModalWindowService } from "src/app/shared/components/modal-window/modal-window.service";

@Component({
    selector: "ge-confirm-changes",
    templateUrl: "./confirm-changes-modal.component.html",
    styleUrl: "./confirm-changes-modal.component.scss",
})
export class ConfirmOrderChangesModal implements OnInit {
    changesConfirmed = false;
    status: string;
    data: any;
    message: string;

    modalWindowOptions: IModalWindowOptions = {
        title: "editOrderConfirmationModal.title",

        buttons: [
            {
                label: "editOrderConfirmationModal.confirmBtn",
                click: this.confirm.bind(this),
            },
        ],
    };

    constructor(private modalWindowService: ModalWindowService) {}

    ngOnInit() {
        this.status = this.data.status;
        this.message = `editOrderConfirmationModal.${this.status === "WaitingOfferAccepted" ? "messageWaitingOfferAccepted" : "message"}`;
    }

    confirm(): void {
        this.changesConfirmed = true;
        this.modalWindowService.close(this);
    }
}
