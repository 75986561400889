import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { IDriver } from "../chat-main/chat.models";

@Component({
    selector: "ge-chat-drivers-item",
    templateUrl: "./chat-drivers-item.component.html",
    styleUrl: "./chat-drivers-item.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDriversItemComponent implements OnChanges {
    @Input()
    public selectedDriverIds!: string[];

    @Input()
    public currentDriverId: string;

    @Input()
    public parentForm: UntypedFormGroup;

    @Input()
    public driver: IDriver;

    @Input()
    public checkedForMessageSending: string[];

    @Output()
    public selectCurrent: EventEmitter<IDriver> = new EventEmitter<IDriver>();

    @Output()
    public readonly check: EventEmitter<Event> = new EventEmitter<Event>();

    public checked!: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        const { selectedDriverIds, driver } = changes;

        if (
            (selectedDriverIds || driver) &&
            this.selectedDriverIds &&
            this.driver
        ) {
            this.checked =
                this.selectedDriverIds.indexOf(this.driver.chatId) !== -1;
        }
    }
}
