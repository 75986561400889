import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SvgModule } from "src/app/common/components/svg/svg.module";
import { RadioButtonSelectComponent } from "./radio-button-select.component";

import { I18nModule } from "../../i18n/i18n.module";
import { NgClickOutsideDirective } from "ng-click-outside2";

@NgModule({
    declarations: [RadioButtonSelectComponent],
    imports: [
        CommonModule,
        FormsModule,
        I18nModule,

        NgClickOutsideDirective,
        SvgModule,
    ],
    exports: [RadioButtonSelectComponent],
})
export class RadioButtonSelectModule {}
