import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateFn,
    CanMatchFn,
    UrlSegment,
    Route,
} from "@angular/router";
import { AuthService } from "./authServices/auth.service";

export const authCanActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    return inject(AuthService).validateAuthentication(state.url);
};

export const authCanMatch: CanMatchFn = (
    route: Route,
    segments: UrlSegment[],
) => {
    console.log("route", route.path, segments);
    return inject(AuthService).validateAuthentication(
        segments.reduce((acc, segment) => acc + segment.path, ""),
    );
};
