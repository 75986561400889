/* src/app/common/maintense/maintense.component.scss */
.container {
  display: flex;
  flex-direction: column;
  height: 1080px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
section {
  font-size: 16px;
}
/*# sourceMappingURL=maintense.component.css.map */
