<div class="messageContainer" *ngIf="!isDriver">
    <div *ngIf="message.groupDate" class="dateGroup">
        <p class="dateGroupLineLeft"></p>
        <p class="dateGroupDate">{{ message.groupDate }}</p>
        <p class="dateGroupLineRight"></p>
    </div>
    <div class="messageHeader">
        <p class="time">
            {{ message.dateToRender }}
        </p>
        <p class="name">
            {{ message.creator.name }}
        </p>
        <div class="avatar">
            <div class="avatarImg">{{ avatar }}</div>
        </div>
    </div>
    <div class="textWrapper">
        <p class="text">
            {{ message.content }}
        </p>
    </div>
</div>

<div class="messageContainerLeft" *ngIf="isDriver">
    <div *ngIf="message.groupDate" class="dateGroup">
        <p class="dateGroupLineLeft"></p>
        <p class="dateGroupDate">{{ message.groupDate }}</p>
        <p class="dateGroupLineRight"></p>
    </div>
    <div class="messageHeaderLeft">
        <div class="avatar">
            <div class="avatarImg" *ngIf="!message.creator.driver.photo">
                {{ avatar }}
            </div>
            <img
                *ngIf="message.creator?.driver.photo"
                width="36px"
                height="36px"
                src="{{ environmentHost }}{{
                    message.creator?.driver.photo.url
                }}"
            />
        </div>
        <p class="nameLeft">
            {{ message.creator.name }}
        </p>
        <p class="timeLeft">
            {{ message.dateToRender }}
        </p>
    </div>
    <div class="textWrapperLeft">
        <p class="text">
            {{ message.content }}
        </p>
    </div>
</div>
