import { EventEmitter, Injectable, Input, Output } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";

import { DestructibleController } from "../../../../../../common/classes/destructible.controller";
import { ToasterNotificationService } from "../../../../../../shared/components/toaster-notification/toaster-notification.service";
import { I18nService } from "../../../../../../shared/i18n/i18n.service";

import {
    IBaseContract,
    IContractBusTypeSummary,
    TContractDetails,
} from "../../contract-modal.models";
import { ContractApiService } from "../../services/contract-api.service";
import { ContractLoaderService } from "../../services/contract-loader.service";
import { ClientsService } from "src/app/relations/clients/clients.service";
import { ReplaceComaService } from "src/app/management/replace-coma.service";

@Injectable()
export abstract class ContractDetailsBaseController<
    T extends TContractDetails,
> extends DestructibleController {
    @Input()
    public readonly details!: T;

    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    @Output()
    public readonly contractUpdate: EventEmitter<T> = new EventEmitter<T>();

    constructor(
        protected apiService: ContractApiService,
        protected i18n: I18nService,
        protected notificationService: ToasterNotificationService,
        protected loaderService: ContractLoaderService,
        protected clientsService: ClientsService,
        protected replaceComaService: ReplaceComaService,
    ) {
        super();
    }

    public deleteRate(rateId: number): void {
        this.loaderService.startLoading();

        this.apiService
            .deleteContractRate<T>(
                this.contract.contractType.type,
                this.contract.id,
                rateId,
            )
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (details: T) => {
                    this.contractUpdate.emit(details);

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }
}
