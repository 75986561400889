/* src/app/relations/clients/modals/contract-modal/components/contract-types/drenth-van-gogh-contract/drenth-table/rate-table.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
table {
  margin-bottom: 0;
}
table th {
  font-size: 14px;
  color: #828282;
  font-weight: normal;
  height: 36px;
}
table tbody tr {
  height: 57px;
  font-size: 14px;
}
table .error-field {
  background: rgba(255, 0, 0, 0.08);
}
table tr.add-row {
  background: #ffffff;
  border-bottom: 1px solid rgba(219, 223, 232, 0.5);
}
table tr.header-row th:first-child,
table tr.header-row td:first-child,
table tr.table-row th:first-child,
table tr.table-row td:first-child {
  padding-left: 13px;
}
table tr.header-row th:last-child,
table tr.header-row td:last-child,
table tr.table-row th:last-child,
table tr.table-row td:last-child {
  padding-right: 13px;
}
table tr.header-row ge-editor-field .price-label,
table tr.header-row ge-editor-field input,
table tr.table-row ge-editor-field .price-label,
table tr.table-row ge-editor-field input {
  width: 150px;
}
table .table-row:hover .remove-btn {
  opacity: 0.8;
}
.remove-btn {
  cursor: pointer;
  opacity: 0;
  transition: opacity 180ms ease;
}
.add-btn {
  border-radius: 50%;
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32946e;
  border: 1px solid rgba(219, 223, 232, 0.5);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04);
  background: none;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
}
.add-btn.disabled {
  pointer-events: none;
  cursor: auto;
  opacity: 0.35;
}
/*# sourceMappingURL=rate-table.component.css.map */
