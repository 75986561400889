<div class="filter-wrapper" (clickOutside)="onClickedOutside($event)">
    <div>
        <button
            class="drop-toggle btn"
            (click)="onBtnClick()"
            [disabled]="disabled"
        >
            <span class="drop-toggle-label">
                <ng-container *ngIf="!isValue"
                    >{{ placeholder }}&nbsp;</ng-container
                >
                <ng-container *ngIf="isValue && isSelectedItem">
                    <b>{{ i18nPrefix + getItemLabel(selectedItem) | i18n }}</b>
                </ng-container>
            </span>

            <!-- arrows -->
            <ge-svg
                class="svgArrow"
                icon="arrow-down"
                size="14"
                color="black"
                *ngIf="isCollapsed"
            ></ge-svg>
            <ge-svg
                class="svgArrow"
                icon="arrow-up"
                size="14"
                color="black"
                *ngIf="!isCollapsed"
            ></ge-svg>
            <!-- arrows -->
        </button>

        <!-- dropdown -->
        <div class="drop-show" *ngIf="!isCollapsed">
            <label class="control control-radio" *ngFor="let item of data">
                <span>{{ i18nPrefix + getItemLabel(item) | i18n }}</span>
                <input
                    type="radio"
                    [name]="radioName"
                    [value]="getItemValue(item)"
                    [checked]="isSelected(item)"
                    (change)="onSelect(item)"
                />
                <div class="control-indicator"></div>
            </label>
        </div>
        <!-- dropdown -->
    </div>
</div>
