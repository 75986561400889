<ge-modal-window>
    <div class="container">
        <form [formGroup]="balanceVacationFormGroup">
            <div class="row">
                <div class="col-4">
                    <span
                        data-translate-me
                        [translateMe]="'Drivers.balanceVacation.date'"
                    >
                        {{ "Drivers.balanceVacation.date" | i18n }}:
                    </span>
                </div>
                <div class="col-8">
                    <ge-date-range
                        [range]="dateOfChange"
                        (rangeSelection)="onDateChanged($event, true)"
                    ></ge-date-range>
                </div>
                <ge-form-error-messages
                    [formElement]="
                        balanceVacationFormGroup.controls.dateOfChange
                    "
                >
                </ge-form-error-messages>
            </div>
            <div class="row margin-top-20">
                <div class="col-2">
                    <span
                        data-translate-me
                        [translateMe]="'Drivers.balanceVacation.regular'"
                    >
                        {{ "Drivers.balanceVacation.regular" | i18n }}:
                    </span>
                </div>
                <div class="col-10">
                    <input
                        class="balance-input"
                        type="number"
                        formControlName="regularBalanceChange"
                        autocomplete="off"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </div>
            </div>
            <div class="row margin-top-20">
                <div class="col-2">
                    <span
                        data-translate-me
                        [translateMe]="'Drivers.balanceVacation.extra'"
                    >
                        {{ "Drivers.balanceVacation.extra" | i18n }}:
                    </span>
                </div>
                <div class="col-10">
                    <input
                        class="balance-input"
                        type="number"
                        formControlName="extraBalanceChange"
                        autocomplete="off"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </div>
            </div>
            <div class="row margin-top-20">
                <div class="col-2">
                    <span
                        data-translate-me
                        [translateMe]="'Drivers.balanceVacation.employer'"
                    >
                        {{ "Drivers.balanceVacation.employer" | i18n }}:
                    </span>
                </div>
                <div class="col-10">
                    <input
                        class="balance-input"
                        type="number"
                        formControlName="employerBalanceChange"
                        autocomplete="off"
                        (wheel)="EventHelper.cancelOnWheelEvent($event)"
                    />
                </div>
            </div>
        </form>
    </div>
</ge-modal-window>
