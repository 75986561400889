import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { AuthService } from "src/app/auth/authServices/auth.service";
import { I18nService } from "../../shared/i18n/i18n.service";
import { LayoutService } from "../layout.service";
import { PlanningService } from "src/app/planning/planning.service";
import { MainRouting } from "../../main-routing.enum";

@Component({
    selector: "ge-navigation",
    templateUrl: "./navigation.component.html",
    styleUrl: "./navigation.component.scss",
})
export class NavigationComponent implements OnInit, OnDestroy {
    public nextLanguage = "NL";
    public nextLanguageImg = "flagNL";
    public navigationLinks = [
        {
            icon: "dashBoardNew",
            action: ["/" + MainRouting.Dashboard],
            title: "navigation.dashboard",
        },
        {
            icon: "ordersNew",
            action: ["/" + MainRouting.Orders],
            title: "navigation.orders",
            isActive: false,
        },
        {
            icon: "planningNew",
            action: ["/" + MainRouting.Planning],
            title: "navigation.planning",
            isActive: false,
        },
        {
            icon: "tripsNew",
            action: ["/" + MainRouting.Trips],
            title: "navigation.trips",
            isActive: false,
        },
        {
            icon: "locatorNew",
            action: ["/" + MainRouting.Locator],
            title: "navigation.locator",
            isActive: false,
        },
        {
            icon: "driverHours",
            action: ["/" + MainRouting.DriverHours],
            title: "navigation.driverHours",
            isActive: false,
        },
        {
            icon: "invoicesNew",
            action: ["/" + MainRouting.Invoices],
            title: "navigation.invoices",
            isActive: false,
        },
        {
            icon: "garage",
            action: ["/" + MainRouting.Reports],
            title: "navigation.garage",
            isActive: false,
        },
        {
            icon: "cashOrders",
            action: ["/" + MainRouting.CashOrders],
            title: "navigation.cashOrders",
            isActive: false,
        },
        {
            icon: "dataNew",
            action: ["/" + MainRouting.Data],
            title: "navigation.data",
            isActive: false,
        },
        {
            icon: "tripTemplatesNew",
            action: ["/" + MainRouting.AddTripTemplates],
            title: "navigation.tripTemplates",
            isActive: false,
        },
        {
            icon: "managementNew",
            action: ["/" + MainRouting.Management],
            title: "navigation.management",
            isActive: false,
        },
    ];
    public isMenuCollapsed = false;

    private currentLanguage = this._i18nService.currentLanguageSubject;

    constructor(
        private _i18nService: I18nService,
        private authService: AuthService,
        private router: Router,
        private layoutService: LayoutService,
        private planningService: PlanningService,
    ) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.IsMenuItemActive();
            }
        });
    }

    ngOnInit() {
        this.onInitActiveTab();
        this.toggleSidebarMenu();
    }

    ngOnDestroy(): void {}

    public toggleSidebarMenu(): void {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this.layoutService.isMenuCollapsed$.next(this.isMenuCollapsed);
    }

    public changeLanguage(): void {
        const language = this.currentLanguage.value === "en" ? "nl" : "en";
        this.nextLanguage = this.currentLanguage.value === "en" ? "EN" : "NL";
        this.nextLanguageImg =
            this.currentLanguage.value === "en" ? "flagEN" : "flagNL";
        this._i18nService.setCurrentLanguage(language);
    }

    public logout(): void {
        this.authService.logout();

        this.router.navigate([MainRouting.Login]);
    }

    public isLinkBlocked(action: string): boolean {
        const planningCondition =
            this.router.url.includes(MainRouting.Planning) &&
            action.includes(`/${MainRouting.Planning}`);
        const driverHoursCondition =
            this.router.url.includes(MainRouting.DriverHours) &&
            action.includes(`/${MainRouting.DriverHours}`);
        return planningCondition || driverHoursCondition;
    }

    public isLinkActive(action: string, title?) {
        if (title == "navigation.management") {
            this.planningService.isMtdTrip.next(true);
        } else {
            this.planningService.isMtdTrip.next(false);
        }
        this.navigationLinks.map((link) => {
            link.isActive = link.title === title;
            return link;
        });
    }
    public IsMenuItemActive() {
        const url = this.router.url;
        this.navigationLinks.map((link) => {
            if (link.isActive == true) {
                link.isActive = false;
            }
        });
        this.navigationLinks.map((link) => {
            if (url.includes(link.action[0])) {
                link.isActive = true;
            }
        });
    }
    private onInitActiveTab() {
        const url = this.router.url;
        this.navigationLinks.map((link) => {
            if (url.includes(link.action[0])) {
                link.isActive = true;
            }
        });
    }
}
