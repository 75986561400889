<div>
    <h3 data-translate-me [translateMe]="'relations.contracts.periods'">
        {{ "relations.contracts.periods" | i18n }}
    </h3>
    <div class="d-flex">
        <ge-period-list
            [periods]="periods"
            [contract]="contract"
            (periodSelect)="selectedItem = $event"
            (periodsCreate)="setNewPeriods($event)"
        ></ge-period-list>
    </div>
    <div
        class="contract-period-details"
        *ngIf="
            periods.length &&
            (rates[selectedItem]?.length || availableBusTypes?.length)
        "
    >
        <h3 data-translate-me [translateMe]="'relations.contracts.ratesInfo'">
            {{ "relations.contracts.ratesInfo" | i18n }}
        </h3>
        <div class="margin-top-15">
            <ge-rate-table
                [tableRows]="rates[selectedItem]"
                [type]="contract.contractType.type"
                [customBusTypes]="availableBusTypes"
                (rowsUpdate)="updateContract($event)"
                (createRate)="createRate($event)"
                (deleteRate)="deleteRate($event)"
            ></ge-rate-table>
        </div>
    </div>

    <div class="margin-top-20">
        <ge-new-bus-type
            [contractId]="contract.id"
            (bustypeCreate)="addBusType($event)"
        ></ge-new-bus-type>
    </div>
</div>
