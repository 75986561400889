import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { NotFoundComponent } from "./common/not-found/not-found.component";
import { LayoutComponent } from "./layout/layout.component";
import { authCanActivate, authCanMatch } from "./auth/auth.guard";
import { OfferConfirmationPageComponent } from "./offer-confirmation-page/offer-confirmation-page.component";
import { AuthCallbackPageComponent as ExactAuthCallbackPageComponent } from "./management/exact/auth-callback-page/auth-callback-page.component";
import { MainRouting } from "./main-routing.enum";
import { MaintenseComponent } from "./common/maintense/maintense.component";
import {
    maintenseCanActivate,
    maintenseCanMatch,
} from "./common/guards/maintense.guard";
import { NgModule } from "@angular/core";

const routes: Routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            {
                path: "",
                redirectTo: "dashboard",
                pathMatch: "full",
            },
            {
                path: MainRouting.Data,
                loadChildren: () =>
                    import("./data/data.module").then((m) => m.DataModule),
                canMatch: [authCanMatch, maintenseCanMatch],
            },
            {
                path: MainRouting.Management,
                loadChildren: () =>
                    import("./management/management.module").then(
                        (m) => m.ManagementModule,
                    ),
            },
            {
                path: MainRouting.Orders,
                loadChildren: () =>
                    import("./orders/orders.module").then(
                        (m) => m.OrdersModule,
                    ),
            },
            {
                path: MainRouting.Planning,
                loadChildren: () =>
                    import("./planning/planning.module").then(
                        (m) => m.PlanningModule,
                    ),
            },
            {
                path: MainRouting.Invoices,
                loadChildren: () =>
                    import("./invoices/invoices.module").then(
                        (m) => m.InvoicesModule,
                    ),
            },
            {
                path: MainRouting.Relations,
                loadChildren: () =>
                    import("./relations/relations.module").then(
                        (m) => m.RelationsModule,
                    ),
            },
            {
                path: MainRouting.Reports,
                loadChildren: () =>
                    import("./reports/reports.module").then(
                        (m) => m.ReportsModule,
                    ),
            },
            {
                path: MainRouting.Trips,
                loadChildren: () =>
                    import("./trips/trips.module").then((m) => m.TripsModule),
            },
            {
                path: MainRouting.AddTripTemplates,
                loadChildren: () =>
                    import("./trip-templates/trip-templates.module").then(
                        (m) => m.TripTemplatesModule,
                    ),
            },
            {
                path: MainRouting.Dashboard,
                loadChildren: () =>
                    import("./dashboard/dashboard.module").then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: MainRouting.Locator,
                loadChildren: () =>
                    import("./locator/locator.module").then(
                        (m) => m.LocatorModule,
                    ),
            },
            {
                path: MainRouting.SunwebLocator,
                loadChildren: () =>
                    import("./sunwebLocator/sunweb-locator.module").then(
                        (m) => m.SunwebLocatorModule,
                    ),
            },
            {
                path: MainRouting.DriverHours,
                loadChildren: () =>
                    import("./driver-hours/driver-hours.module").then(
                        (m) => m.DriverHoursModule,
                    ),
            },
            {
                path: MainRouting.CashOrders,
                loadChildren: () =>
                    import(
                        "./cash-orders-container/cash-orders-container.module"
                    ).then((m) => m.CashOrdersContainerModule),
            },
            {
                path: MainRouting.Callback,
                component: ExactAuthCallbackPageComponent,
            },
        ],
        canActivate: [authCanActivate, maintenseCanActivate],
    },
    { path: MainRouting.Login, component: LoginComponent },
    {
        path: MainRouting.Payments,
        loadChildren: () =>
            import("./payments/payment.module").then((m) => m.PaymentModule),
    },
    {
        path: MainRouting.OfferConfirmation,
        component: OfferConfirmationPageComponent,
    },
    {
        path: MainRouting.OfficeScreensPlanboard,
        loadChildren: () =>
            import(
                "./office-screens-planboard/office-screens-planboard.module"
            ).then((m) => m.OfficeScreensPlanboardModule),
    },
    { path: MainRouting.Maintense, component: MaintenseComponent },
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
