/* src/app/Chat/chat-drivers-list/chat-drivers-item.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.driverAvatar,
.driverName {
  pointer-events: none;
}
.driverName {
  font-size: 16px;
  margin-left: 12px;
}
.driverCheckbox {
  margin-left: auto;
}
.driverWrapper {
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 4px;
  width: 392px;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #dbdfe8;
}
.avatarContainer {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: peru;
  margin-left: 16px;
  position: relative;
  color: peru;
}
.driverCheckbox ::ng-deep label {
  display: block;
}
.driverCheckbox ::ng-deep span {
  transform: translateY(-50%);
}
.unreadMessages {
  width: 20px;
  height: 20px;
  background-color: #7f0025;
  color: white;
  text-align: center;
  font-size: 10px;
  position: absolute;
  right: -7px;
  bottom: -7px;
  border-radius: 10px;
  padding-top: 3px;
}
.avatar {
  width: 100%;
  height: 100%;
}
.selected {
  background-color: #f3f4f7;
}
/*# sourceMappingURL=chat-drivers-item.component.css.map */
