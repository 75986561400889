<div>
    <h3 data-translate-me [translateMe]="'relations.contracts.periods'">
        {{ "relations.contracts.periods" | i18n }}
    </h3>
    <div class="d-flex">
        <ge-period-list
            [periods]="periods"
            [contract]="contract"
            (periodSelect)="selectedPeriod = periods[$event]"
            (periodsCreate)="setNewPeriods($event)"
        ></ge-period-list>
    </div>
    <div class="contract-period-details" *ngIf="selectedPeriod">
        <h3 data-translate-me [translateMe]="'relations.contracts.ratesInfo'">
            {{ "relations.contracts.ratesInfo" | i18n }}
        </h3>
        <div class="margin-top-15">
            <ge-sunweb-table
                [data]="details"
                [countries]="countries"
                [busTypes]="availableBusType"
                [period]="selectedPeriod"
                (addCountry)="addCountry($event)"
                (updateRate)="updateSunwebPrice($event)"
                (deleteRate)="deleteSunwebPrice($event)"
            ></ge-sunweb-table>
        </div>
    </div>
    <div class="margin-top-20">
        <ge-new-bus-type
            [contractId]="contract.id"
            (bustypeCreate)="addBusType($event)"
        ></ge-new-bus-type>
    </div>
</div>
