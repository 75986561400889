import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { finalize, takeUntil } from "rxjs/operators";
import {
    IBaseContract,
    IContractBusTypeSummary,
    ICustomBusType,
    IDrentVanGoghDetails,
    IDrentVanGoghRate,
    IRatePeriod,
} from "../../../contract-modal.models";
import { ContractDetailsBaseController } from "../contract-details-base.controller";

@Component({
    selector: "ge-drenth-vangogh-contract",
    templateUrl: "./drenth-van-gogh-contract.component.html",
    styleUrl: "./drenth-van-gogh-contract.component.scss",
})
export class DrenthVanGoghContractComponent
    extends ContractDetailsBaseController<IDrentVanGoghDetails[]>
    implements OnChanges
{
    @Input()
    public readonly details!: IDrentVanGoghDetails[];

    @Input()
    public readonly contract!: IBaseContract;

    @Input()
    public readonly customBusTypes!: IContractBusTypeSummary[];

    public periods: IRatePeriod[] = [];

    public selectedItem = 0;

    public rates: IDrentVanGoghRate[][] = [];

    public availableBusTypes!: IContractBusTypeSummary[];

    public ngOnChanges(changes: SimpleChanges): void {
        const { details, customBusTypes } = changes;

        if (details && this.details) {
            this.periods = [];
            this.rates = [];

            this.details.forEach(({ rates, period }: IDrentVanGoghDetails) => {
                this.periods.push(period);
                this.rates.push(
                    rates.map((item: IDrentVanGoghRate) => {
                        return Object.keys(item).reduce(
                            (res: any & object, field: string) => ({
                                ...res,
                                [field]:
                                    item[field] !== null
                                        ? item[field]
                                        : undefined,
                            }),
                            {},
                        );
                    }),
                );
            });
        }

        if (customBusTypes && this.customBusTypes) {
            this.availableBusTypes = this.customBusTypes;
        }
    }

    public setNewPeriods(periods: IRatePeriod[]): void {
        this.periods = this.periods.concat(periods);
        this.rates.push([]);
    }

    public addBusType(event: ICustomBusType): void {
        this.availableBusTypes = [
            ...this.availableBusTypes,
            event as IContractBusTypeSummary,
        ];
    }

    public createRate(createRate: IDrentVanGoghRate): void {
        const rates = this.periods.map(
            (period: IRatePeriod, index: number) => ({
                ratesPeriod: period,
                customBusTypeRates:
                    index !== this.selectedItem
                        ? this.rates[index]
                        : [...this.rates[index], createRate],
            }),
        );

        this.updateContractRequest(rates);
    }

    public updateContract(modifiedRates: IDrentVanGoghRate[]): void {
        const fullRates = this.periods.map(
            (period: IRatePeriod, index: number) => {
                return {
                    ratesPeriod: period,
                    customBusTypeRates:
                        index !== this.selectedItem
                            ? this.rates[index]
                            : modifiedRates,
                };
            },
        );

        this.updateContractRequest(fullRates);
    }

    private updateContractRequest(
        rates: {
            ratesPeriod: IRatePeriod;
            customBusTypeRates: IDrentVanGoghRate[];
        }[],
    ): void {
        this.loaderService.startLoading();

        this.apiService
            .updateContractDetails<IDrentVanGoghDetails[]>(
                this.contract.contractType.type,
                {
                    rates,
                    clientContractId: this.contract.id,
                },
            )
            .pipe(
                finalize(() => this.loaderService.stopLoading()),
                takeUntil(this.destroy$),
            )
            .subscribe(
                (data: IDrentVanGoghDetails[]) => {
                    this.contractUpdate.emit(data);

                    this.notificationService.successToaster(
                        "relations.contracts.successUpdate",
                    );
                },
                () => {
                    this.notificationService.errorToaster("errors.commonError");
                },
            );
    }
}
